import { Dispatch, FC, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "../../../../Elements/Input";
import { IHolderNew } from "../../../../types/IHolder";
import { getPersonalInfoData } from "../../Common/personalInfoData";

interface Props {
  formData: IHolderNew;
  setFormData: Dispatch<SetStateAction<IHolderNew>>;
}

export const AddMemberForm: FC<Props> = ({ formData, setFormData }) => {
  const { t } = useTranslation();

  const memberInfo = getPersonalInfoData({
    formData,
    setFormData,
    t,
    edit: true,
    isNewForm: true,
  });

  return (
    <>
      {memberInfo.map(
        (e) => !e.hidden && <Input key={e.label} component={e} edit />
      )}
    </>
  );
};
