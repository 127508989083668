import {
  ISimpleObject,
  ISimpleObjectWithCode,
} from "@inceptionbg/ui-components";
import { t } from "i18next";
import { axiosInstance } from "../App";
import { INameCode } from "../types/IBase";
import { CaseStatusFetchOption, ICase } from "../types/ICase";
import { IFuelType } from "../types/IInvoice";
import {
  ICulture,
  ILand,
  ILandReportType,
  ILandUsageBasis,
  IPlantCulture,
  LandFetchOption,
} from "../types/ILand";
import { IMemberNew } from "../types/IMember";
import { IRequestType } from "../types/IRequest";
import { maxChar } from "./StringUtils";
import { IPlantProtectionProduct } from "../types/IPlantProtectionProduct";

export const getFarmOrganizationFormOptions = (
  fullText: string,
  loadedOptions: [],
  offset: { page: number },
  filters?: { physicalPersonHolder?: boolean }
) =>
  axiosInstance
    .get("/farm/organizational-forms", {
      params: {
        fullText,
        limit: 50,
        offset: offset.page,
        ...filters,
      },
    })
    .then(({ data }) => ({
      options: data.organizationalForms.map((e: ISimpleObject) => ({
        value: e.uuid,
        label: e.name,
      })),
      hasMore: loadedOptions.length < data.pagination?.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch((error) => console.error(error));

export const getWorkActivityOptions = (
  fullText: string,
  loadedOptions: [],
  offset: { page: number }
) =>
  axiosInstance
    .get("/work-activities", {
      params: {
        fullText,
        limit: 50,
        offset: offset.page,
      },
    })
    .then(({ data }) => ({
      options: data.activities.map((e: ISimpleObject) => ({
        value: e.uuid,
        label: e.name,
      })),
      hasMore: false,
      additional: { page: offset.page + 1 },
    }))
    .catch((error) => console.error(error));

export const getEducationOptions = (
  fullText: string,
  loadedOptions: [],
  offset: { page: number }
) =>
  axiosInstance
    .get("/educations", {
      params: {
        fullText,
        limit: 50,
        offset: offset.page,
      },
    })
    .then(({ data }) => ({
      options: data.educations.map((e: ISimpleObject) => ({
        value: e.uuid,
        label: e.name,
      })),
      hasMore: false,
      additional: { page: offset.page + 1 },
    }))
    .catch((error) => console.error(error));

export const getCountriesOptions = (
  fullText: string,
  loadedOptions: [],
  offset: { page: number }
) =>
  axiosInstance
    .get("/countries", {
      params: {
        fullText,
        limit: 50,
        offset: offset.page,
      },
    })
    .then(({ data }) => ({
      options: data.countries.map((e: INameCode) => ({
        value: e.code,
        label: e.name,
      })),
      hasMore: loadedOptions.length < data.pagination?.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch((error) => console.error(error));

export const getCadastreMunicipalityOptions = (
  fullText: string,
  loadedOptions: [],
  offset: { page: number }
) =>
  axiosInstance
    .get("/cadastre-municipalities", {
      params: {
        fullText,
        limit: 50,
        offset: offset.page,
      },
    })
    .then(({ data }) => ({
      options: data.municipalities.map((e: ISimpleObjectWithCode) => ({
        value: e.uuid,
        label: e.name,
        code: e.code,
      })),
      hasMore: loadedOptions.length < data.pagination?.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch((error) => console.error(error));

export const getCadastreLandsOptions = (
  fullText: string,
  loadedOptions: [],
  offset: { page: number },
  filters: {
    farmUuid: string;
    cadastreMunicipalityUuid: string;
    memberUuid?: string;
  }
) =>
  axiosInstance
    .get("/cadastre/lands", {
      params: {
        fullText,
        limit: 50,
        offset: offset.page,
        ...filters,
      },
    })
    .then(({ data }) => ({
      options: data.map((e: { cadastreNumber: string; surface: number }) => ({
        value: e.cadastreNumber,
        label: e.cadastreNumber,
        surface: e.surface,
      })),
      hasMore: loadedOptions.length < data.pagination?.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch((error) => console.error(error));

export const getCadastreCultureOptions = (
  fullText: string,
  loadedOptions: [],
  offset: { page: number }
) =>
  axiosInstance
    .get("/cadastre-cultures", {
      params: {
        fullText,
        limit: 50,
        offset: offset.page,
      },
    })
    .then(({ data }) => ({
      options: data.cultures.map((e: ISimpleObjectWithCode) => ({
        value: e.uuid,
        label: e.name,
        code: e.code,
      })),
      hasMore: loadedOptions.length < data.pagination?.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch((error) => console.error(error));

export const getLandCultureOptions = (
  fullText: string,
  loadedOptions: [],
  offset: { page: number }
) =>
  axiosInstance
    .get("/plant-cultures", {
      params: {
        fullText,
        limit: 50,
        offset: offset.page,
      },
    })
    .then(({ data }) => ({
      options: data.cultures.map((e: IPlantCulture) => ({
        value: e.uuid,
        label: e.name,
        code: e.code,
        requireNumberOfSeedlings: e.requireNumberOfSeedlings,
      })),
      hasMore: loadedOptions.length < data.pagination?.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch((error) => console.error(error));

export const getPlaceOptions = (
  fullText: string,
  loadedOptions: [],
  offset: { page: number }
) =>
  axiosInstance
    .get("/places", {
      params: {
        fullText,
        limit: 50,
        offset: offset.page,
      },
    })
    .then(({ data }) => ({
      options: data.places.map((e: ISimpleObjectWithCode) => ({
        value: e.uuid,
        label: e.name,
        code: e.code,
      })),
      hasMore: loadedOptions.length < data.pagination?.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch((error) => console.error(error));

export const getCaseOptions = (
  fullText: string,
  loadedOptions: [],
  offset: { page: number },
  farmUuid?: string,
  statuses?: CaseStatusFetchOption[]
) =>
  axiosInstance
    .get("/cases", {
      params: {
        fullText,
        limit: 50,
        offset: offset.page,
        farmUuid,
        statuses,
      },
    })
    .then(({ data }) => ({
      options: data.cases.map((e: ICase) => ({
        value: e.caseId,
        label: e.caseNumber,
      })),
      hasMore: loadedOptions.length < data.pagination?.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch((error) => console.error(error));

export const getLeaseTypeOptions = (
  fullText: string,
  loadedOptions: [],
  offset: { page: number },
  filters?: { usageBasis?: ILandUsageBasis | "UPZ" }
) =>
  axiosInstance
    .get("/lease-types", {
      params: {
        fullText,
        limit: 50,
        offset: offset.page,
        ...filters,
      },
    })
    .then(({ data }) => ({
      options: data.leaseTypes.map((e: ISimpleObjectWithCode) => ({
        value: e.uuid,
        label: e.code + " - " + e.name,
        code: e.code,
      })),
      hasMore: loadedOptions.length < data.pagination?.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch((error) => console.error(error));

export const getMembersOptions = (
  fullText: string,
  loadedOptions: [],
  offset: { page: number },
  farmUuid: string
) =>
  axiosInstance
    .get(`/farm/${farmUuid}/members`, {
      params: {
        fullText,
        verified: true,
        // limit: 50,
        // offset: offset.page,
      },
    })
    .then(({ data }) => ({
      options: data.members.map((e: IMemberNew) => ({
        value: e.uuid,
        label: e.approved
          ? `${e.firstName} ${e.lastName}`
          : `${e.firstName} ${e.lastName} (${t("Inactive")})`,
        disabled: !e.approved,
        personalIdentityNumber: e.personalIdentityNumber,
        firstName: e.firstName,
        lastName: e.lastName,
      })),
      hasMore: false,
      additional: { page: offset.page + 1 },
    }))
    .catch((error) => console.error(error));

export const getGeneralRequestTypeOptions = (
  fullText: string,
  loadedOptions: [],
  offset: { page: number }
) =>
  axiosInstance
    .get(`general-request/types`, {
      params: {
        fullText,
        excerpt: true,
        // limit: 50,
        // offset: offset.page,
      },
    })
    .then(({ data }) => ({
      options: data.generalRequestTypes.map(
        (e: { internalCode: IRequestType; name: string; uuid: string }) => ({
          value: e.internalCode,
          label: e.name,
        })
      ),
      hasMore: false,
      additional: { page: offset.page + 1 },
    }))
    .catch((error) => console.error(error));

export const getFuelTypeOptions = (
  fullText: string,
  loadedOptions: [],
  offset: { page: number },
  filters?: {
    excludeFuelTypeUuids?: string[];
    invoiceUuids?: string[];
  }
) =>
  axiosInstance
    .get(`fuel-types`, { params: { fullText, ...filters } })
    .then(({ data }) => ({
      options: data.fuelTypes.map((e: IFuelType) => ({
        value: e.uuid,
        label: e.name,
      })),
      hasMore: false,
      additional: { page: offset.page + 1 },
    }))
    .catch((error) => console.error(error));

export const getLandsOptions = (
  fullText: string,
  loadedOptions: [],
  offset: { page: number },
  farmUuid: string,
  fetchOptions?: LandFetchOption[],
  filters?: {
    approved?: boolean;
    hasAtLeastOnePlantCulture?: boolean;
    excludeUsageBasis?: ILandReportType;
    refractionFilter?: boolean;
    excludeLandPlantCultureUuids?: string;
  }
) =>
  axiosInstance
    .get(`/farm/${farmUuid}/lands`, {
      params: { fullText, ...filters, fetchOptions },
    })
    .then(({ data }) => ({
      options: data.lands.map((e: ILand) => ({
        value: e.uuid,
        label: `${e.cadastreNumber} - ${
          e.nickname ? maxChar(e.nickname, 20) : e.cadastreMunicipality.name
        }`,
        literRecapitulation: e.literRecapitulation,
      })),
      hasMore: loadedOptions.length < data.pagination?.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch((error) => console.error(error));

export const getLandsCultureOptions = (
  fullText: string,
  loadedOptions: [],
  offset: { page: number },
  landUuid: string,
  fetchOptions?: ["MAX_LITER", "LITER_RECAPITULATION"],
  filters?: {
    refractionAllowed: boolean;
    excludeLandPlantCultureUuids?: string;
  }
) =>
  axiosInstance
    .get(`/land/${landUuid}/cultures`, {
      params: { fullText, fetchOptions, ...filters },
    })
    .then(({ data }) => ({
      options: data.cultures.map((e: ICulture) => ({
        value: e.uuid,
        label: e.plantCulture.name,
        maxLiter: e.maxLiter,
        literRecapitulation: e.literRecapitulation,
      })),
      hasMore: loadedOptions.length < data.pagination?.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch((error) => console.error(error));

export const getPlantProtectionProductOptions = (
  fullText: string,
  loadedOptions: [],
  offset: { page: number },
  filters: {
    date: string;
  }
) =>
  axiosInstance
    .get("/plant-protection-products", {
      params: {
        fullText,
        limit: 50,
        offset: offset.page,
        ...filters,
      },
    })
    .then(({ data }) => ({
      options: data.plantProtectionProducts.map(
        (e: IPlantProtectionProduct) => ({
          value: e.uuid,
          label: e.name,
          category: e.category,
          manufacturerName: e.manufacturerName,
        })
      ),
      hasMore: loadedOptions.length < data.pagination?.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch((error) => console.error(error));
