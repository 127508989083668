import { FC, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SubSection } from "../../SubSection";
import { Button, TextInput } from "@inceptionbg/ui-components";
import { Input } from "../../../Elements/Input";
import { Dialog } from "../../../Elements/Dialog";
import { FarmContext } from "../../../AppContent";
import {
  addAnimalHid,
  deleteAnimalHid,
  getHids,
} from "../../../repos/AnimalRepo";

export const AnimalHidSection: FC = () => {
  const [hid, setHid] = useState("");
  const [hidsList, setHidsList] = useState<string[]>([]);
  const [itemToDelete, setItemToDelete] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const farmUuid = useContext(FarmContext).farm?.uuid;

  const handleGetHids = useCallback(() => {
    if (farmUuid) {
      setIsLoading(true);
      getHids(farmUuid)
        .then((hids) => setHidsList(hids))
        .catch(() => {})
        .finally(() => setIsLoading(false));
    }
  }, [farmUuid]);

  useEffect(handleGetHids, [handleGetHids]);

  const onDelete = () => {
    setIsLoading(true);
    deleteAnimalHid(farmUuid!, itemToDelete)
      .then((e) => handleGetHids())
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  const onSubmit = () => {
    setIsLoading(true);
    addAnimalHid(farmUuid!, hid)
      .then((e) => {
        setHid("");
        handleGetHids();
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  return (
    <SubSection
      title={t("AnimalHidsSection")}
      expandable
      isLoading={isLoading}
      initialyOpen
    >
      {!!hidsList.length && (
        <div className="files-list">
          <p className="files-header">{t("AnimalHidsList")}:</p>
          <div className="list">
            {hidsList.map((hid) => (
              <div key={hid} className="file">
                <p>{hid}</p>
                <Button
                  label={t("RemoveHid")}
                  size="xs"
                  outlined
                  onClick={() => setItemToDelete(hid)}
                />
              </div>
            ))}
          </div>
        </div>
      )}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <Input
          component={{
            label: t("Hid"),
            input: <TextInput required value={hid} setValue={setHid} />,
            labelHelper: t("HidHelperLabel"),
          }}
          edit
        />
        <div className="flex justify-right mt-3">
          <Button
            label={t("AnimalHidsSectionButton")}
            primary
            disabled={!hid || !farmUuid}
            size="s"
          />
        </div>
      </form>
      <Dialog
        isOpen={!!itemToDelete}
        onClose={() => setItemToDelete("")}
        title={t("RemoveHid")}
        desc={`${t("RemoveHidDesc")} ${itemToDelete}?`}
        isLoading={isLoading}
        confirmButton={{ label: t("Confirm"), onClick: onDelete }}
        cancelButton={{}}
        notMobileFullScreen
      />
    </SubSection>
  );
};
