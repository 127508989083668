import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FarmContext } from "../../../../AppContent";
import { Checkbox } from "../../../../Elements/Checkbox";
import { Dialog } from "../../../../Elements/Dialog";
import { addOrEditReceipt } from "../../../../repos/InvoiceRepo";
import { IDialogTypeItem } from "../../../../types/IBase";
import { IEvidence } from "../../../../types/IFile";
import {
  IInvoice,
  IInvoiceItem,
  IInvoiceNew,
} from "../../../../types/IInvoice";
import { EvidencesComponent } from "../../../Files/EvidencesComponent";
import { AddInvoiceForm } from "./AddInvoiceForm";
import { AddInvoiceItemsForm } from "./Items/AddInvoiceItemsForm";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  invoice?: IInvoice;
  reloadInvoices: (callback?: () => void) => void;
}

type IStepType = "form" | "items" | "attachments";

export const AddInvoiceDialog: FC<Props> = ({
  isOpen,
  onClose,
  invoice,
  reloadInvoices,
}) => {
  const [step, setStep] = useState<IStepType>("form");
  const [formData, setFormData] = useState<IInvoiceNew>({});
  const [items, setItems] = useState<IInvoiceItem[]>(invoice?.items || []);
  const [evidences, setEvidences] = useState<IEvidence[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddInvoiceFormDisabled, setIsAddInvoiceFormDisabled] =
    useState(false);
  const [disclaimerChecked, setDisclaimerChecked] = useState(false);

  const { t } = useTranslation();
  const { reloadFarm } = useContext(FarmContext);
  const farmUuid = useContext(FarmContext).farm?.uuid;

  useEffect(() => {
    if (isOpen && invoice) {
      setFormData(invoice);
      setItems(invoice.items || []);
    } else {
      setFormData({});
      setItems([]);
    }
  }, [isOpen, invoice]);

  const typeData: {
    [key in IStepType]: IDialogTypeItem;
  } = {
    form: {
      title: t(invoice ? "ReplaceInvoice" : "NewInvoice"),
      desc: t("NewInvoiceDesc"),
      element: (
        <AddInvoiceForm
          formData={formData}
          setFormData={setFormData}
          setIsDisabled={setIsAddInvoiceFormDisabled}
        />
      ),
      confirmBtn: {
        label: t("AddInvoiceItems"),
        onFormSubmit: () => setStep("items"),
        keepOpen: true,
        disabled: isAddInvoiceFormDisabled,
      },
      cancelBtn: {
        onClick: () => {
          setFormData({});
          onClose();
        },
      },
    },
    items: {
      title: t("InvoiceItems"),
      desc: t("InvoiceItemsDesc"),
      element: <AddInvoiceItemsForm items={items} setItems={setItems} />,
      confirmBtn: {
        label: t("InvoiceEvidenceTitle"),
        onClick: () => setStep("attachments"),
        keepOpen: true,
        disabled: !items.length,
      },
      cancelBtn: { onClick: () => setStep("form") },
    },
    attachments: {
      title: t("InvoiceEvidenceTitle"),
      desc: t("InvoiceEvidenceDesc"),
      element: (
        <>
          <EvidencesComponent
            category="INVOICE"
            evidences={evidences}
            setEvidences={setEvidences}
          />
          <Checkbox
            className="statement"
            label={<p className="new-line">{t(`InvoiceDisclaimer`)}</p>}
            selected={disclaimerChecked}
            setSelected={() => setDisclaimerChecked((prev) => !prev)}
          />
        </>
      ),
      confirmBtn: {
        label: t("InvoiceAdd"),
        disabled: !evidences.length || !disclaimerChecked,
        onClick: () => onSubmit(),
        keepOpen: true,
      },
      cancelBtn: { onClick: () => setStep("items") },
    },
  };

  const handleClose = () => {
    setStep("form");
    setFormData({});
    setEvidences([]);
    setItems([]);
    onClose();
    setDisclaimerChecked(false);
  };

  const onSubmit = () => {
    if (farmUuid) {
      setIsLoading(true);
      addOrEditReceipt({
        ...formData,
        farm: { uuid: farmUuid },
        source: "UI",
        evidences,
        items,
      })
        .then(() => {
          reloadInvoices(() => {
            setIsLoading(false);
            reloadFarm();
            handleClose();
          });
        })
        .catch(() => setIsLoading(false));
    }
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      title={typeData[step].title}
      desc={typeData[step].desc}
      descEl={typeData[step].descEl}
      confirmButton={typeData[step].confirmBtn}
      cancelButton={typeData[step].cancelBtn}
      size="m"
      isLoading={isLoading}
    >
      {typeData[step].element}
    </Dialog>
  );
};
