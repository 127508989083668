import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { ChooseFarmDialog } from "./Components/Dialogs/ChooseFarmDialog";
import { Loader } from "./Elements/Loader";
import {
  checkFarmAuth,
  getFarmByUuid,
  getFarmList,
  getRenewalConditions,
} from "./repos/FarmRepo";
import { Router } from "./Router";
import { IFarm, IRenewalCondition } from "./types/IFarm";
import { localStorageGet, localStorageSet } from "./utils/LocalStorageHelper";
import { UserContext } from "./Context/UserContext";

export const FarmContext = createContext<{
  farm: IFarm | null;
  renewalConditions: IRenewalCondition | null;
  reloadFarm: (callback?: () => void) => void;
  changeFarm: () => void;
  handleGetFarm: (farmUuid: string, callback?: () => void) => void;
  handleGetRenewalConditions: (callback?: () => void) => void;
  isFarmInApproval: boolean;
  setIsFarmInApprovalContext: (value: boolean) => void;
}>({
  farm: null,
  renewalConditions: null,
  reloadFarm: () => {},
  changeFarm: () => {},
  handleGetFarm: () => {},
  handleGetRenewalConditions: () => {},
  isFarmInApproval: false,
  setIsFarmInApprovalContext: () => {},
});

export const AppContent: FC = () => {
  const [farm, setFarm] = useState<IFarm | null>(null);
  const [renewalConditions, setRenewalConditions] = useState<IRenewalCondition>(
    {}
  );
  const [isLoading, setIsLoading] = useState(false);
  const [chooseFarmOpen, setChooseFarmOpen] = useState(false);
  const [isFarmInApproval, setIsFarmInApproval] = useState(false);

  const { userInfo } = useContext(UserContext);

  useEffect(() => {
    setIsFarmInApproval(!!farm?.registrationApproval);
  }, [farm]);

  const setIsFarmInApprovalContext = (value: boolean) => {
    setIsFarmInApproval(value);
  };

  const handleGetFarm = useCallback(
    (farmUuid: string, callback?: () => void) => {
      setIsLoading(true);
      getFarmByUuid(farmUuid)
        .then((e) => {
          setFarm(e);
          localStorageSet("activeOrg", e.uuid);
          callback && callback();
        })
        .catch(() => {})
        .finally(() => setIsLoading(false));
    },
    []
  );

  useEffect(() => {
    const activeOrg = localStorageGet("activeOrg");
    const isConsentPage = window.location.pathname === "/consent";

    const chooseFarm = () => {
      setIsLoading(true);
      getFarmList()
        .then((e) => {
          e.length === 1
            ? handleGetFarm(e[0].uuid)
            : e.length > 1 && setChooseFarmOpen(true);
        })
        .catch(() => {})
        .finally(() => setIsLoading(false));
    };

    if (!isConsentPage && userInfo.hasAtLeastOneFarm) {
      activeOrg
        ? checkFarmAuth(activeOrg)
            .then((access) =>
              access ? handleGetFarm(activeOrg) : chooseFarm()
            )
            .catch(() => {})
        : chooseFarm();
    }
  }, [userInfo.hasAtLeastOneFarm, handleGetFarm]);

  const handleGetRenewalConditions = useCallback(
    (callback?: () => void) => {
      if (farm) {
        setIsLoading(true);
        getRenewalConditions(farm.uuid)
          .then((e) => {
            setRenewalConditions(e);
            callback && callback();
          })
          .catch(() => {})
          .finally(() => setIsLoading(false));
      }
    },
    [farm]
  );

  useEffect(handleGetRenewalConditions, [handleGetRenewalConditions]);

  return (
    <>
      <Loader isLoading={isLoading}>
        <FarmContext.Provider
          value={{
            farm,
            renewalConditions,
            reloadFarm: farm
              ? (callback?: () => void) => {
                  handleGetFarm(farm?.uuid, callback);
                  handleGetRenewalConditions();
                }
              : () => {},
            changeFarm: () => setChooseFarmOpen(true),
            handleGetFarm,
            handleGetRenewalConditions,
            isFarmInApproval,
            setIsFarmInApprovalContext,
          }}
        >
          <Router />
        </FarmContext.Provider>
      </Loader>

      <ChooseFarmDialog
        isOpen={chooseFarmOpen}
        onClose={() => setChooseFarmOpen(false)}
        handleGetFarm={handleGetFarm}
      />
    </>
  );
};
