import {
  Button,
  formatDate,
  getCurrentDateFormattedYMD,
  TextInput,
} from "@inceptionbg/ui-components";
import { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { PageWrapper } from "../Components/Wrapper/PageWrapper";
import { Input } from "../Elements/Input";
import { Loader } from "../Elements/Loader";
import { checkFarm } from "../repos/FarmRepo";
import { IFarmSearch } from "../types/IFarm";

const PublicStatusComponent: FC = () => {
  const [search, setSearch] = useState("");
  const [farm, setFarm] = useState<IFarmSearch | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(
    async (onSubmitCallback: (token: string) => void) => {
      if (!executeRecaptcha) {
        // toastError("Recaptcha is not yet available");
        return;
      }

      const token = await executeRecaptcha("REGISTRATION");
      onSubmitCallback(token);
    },
    [executeRecaptcha]
  );

  const onSubmit = () => {
    setIsLoading(true);
    checkFarm(search)
      .then((e) => {
        setFarm({
          registrationNumber: search,
          renewalDate: e.renewalDate,
          status: e.status,
        });
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  return (
    <PageWrapper isPublic>
      <div className="background-warning px-4 py-2 mobile-hidden">
        <h1>{`${t("eRPGLong")} - ${t(`PageTitleSearch`)}`}</h1>
      </div>
      <Loader isLoading={isLoading}>
        <div className="pub-search-container">
          <form
            className="pub-search"
            onSubmit={(e) => {
              e.preventDefault();
              handleReCaptchaVerify(onSubmit);
            }}
          >
            <Input
              component={{
                label: t("OrgNumberLong"),
                input: <TextInput value={search} setValue={setSearch} />,
              }}
              edit
            />
            <Button
              label={t("Back")}
              size="s"
              outlined
              onClick={() => navigate("/")}
            />
            <Button label={t("Search")} primary size="s" disabled={!search} />
          </form>
          {!!farm && (
            <div className="pub-search-result">
              <Input
                component={{
                  label: t("OrgNumberShort"),
                  value: farm.registrationNumber,
                }}
              />
              <Input
                component={{
                  label: t("Status"),
                  value: t(farm.status),
                }}
              />
              <Input
                component={{
                  label: t("RenewalDate"),
                  value: formatDate(farm.renewalDate),
                }}
              />
              <Input
                component={{
                  label: t("AskDate"),
                  value: formatDate(getCurrentDateFormattedYMD()),
                }}
              />
            </div>
          )}
        </div>
      </Loader>
    </PageWrapper>
  );
};

////// RegisterUser component wrapper //////
const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY || "";

export const PublicStatusPage: FC = () => (
  <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
    <PublicStatusComponent />
  </GoogleReCaptchaProvider>
);
