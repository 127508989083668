import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

const prefix = "far";
const iconName = "triangle-exclamation";
const width = 512;
const height = 512;
const unicode = "f071";
const svgPathData =
  "M506.3 417l-213.3-364C284.8 39 270.4 32 256 32C241.6 32 227.2 39 218.1 53l-213.2 364C-10.59 444.9 9.851 480 42.74 480h426.6C502.1 480 522.6 445 506.3 417zM52.58 432L255.1 84.8L459.4 432H52.58zM256 337.1c-17.36 0-31.44 14.08-31.44 31.44c0 17.36 14.11 31.44 31.48 31.44s31.4-14.08 31.4-31.44C287.4 351.2 273.4 337.1 256 337.1zM232 184v96C232 293.3 242.8 304 256 304s24-10.75 24-24v-96C280 170.8 269.3 160 256 160S232 170.8 232 184z";

export const faTriangleExclamation: IconDefinition = {
  prefix,
  iconName,
  icon: [width, height, [], unicode, svgPathData],
};
