import { axiosInstance } from "../App";
import { IRequestNew } from "../types/IRequest";

export const saveGeneralRequest = (data: IRequestNew) =>
  axiosInstance
    .post(`/general-request`, data, {
      headers: {
        toastType: ["STATUS_CONFIRMATION", "EXCERPT_FROM_REGISTER"].includes(
          data.requestType || ""
        )
          ? "SuccessGeneralRequest"
          : undefined,
      },
    })
    .then(({ data }) => data.identifier as string);
