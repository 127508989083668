import { Dispatch, FC, SetStateAction } from "react";
import {
  IPesticideProcurement,
  unitOfMeasures,
} from "../../types/IPesticideProcurement";
import { IInputComponentProps, Input } from "../../Elements/Input";
import { useTranslation } from "react-i18next";
import {
  DateInput,
  NumberInput,
  TextInput,
  deleteProps,
} from "@inceptionbg/ui-components";
import { Select } from "../Inputs/Select";
import {
  getFarmOrganizationFormOptions,
  getPlantProtectionProductOptions,
} from "../../utils/LoadOptions";
import { Dialog } from "../../Elements/Dialog";
import { SelectAsyncPaginate } from "../Inputs/SelectAsyncPaginate";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  data?: Partial<IPesticideProcurement>;
  setData: Dispatch<SetStateAction<Partial<IPesticideProcurement>>>;
  onSubmit: () => void;
  onDelete?: () => void;
  isDeletable?: boolean;
}
export const PesticideProcurementDialog: FC<Props> = ({
  isOpen,
  onClose,
  data,
  setData,
  onSubmit,
  onDelete,
  isDeletable,
}) => {
  const { t } = useTranslation();

  const formInfo: IInputComponentProps[] = [
    {
      label: t("PurchaseDate"),
      input: (
        <DateInput
          required
          date={data?.date}
          setDate={(e) =>
            setData({ ...data, date: e, plantProtectionProduct: undefined })
          }
        />
      ),
    },
    {
      label: t("PlantProtectionProduct"),
      input: (
        <SelectAsyncPaginate
          required
          helperText={data?.date ? "" : t("PlantProtectionProductContraint")}
          value={
            data?.plantProtectionProduct
              ? {
                  value: data.plantProtectionProduct.uuid,
                  label: data.plantProtectionProduct.name!,
                }
              : null
          }
          loadOptions={(
            search: string,
            loadedOptions: [],
            offset: { page: number }
          ) =>
            getPlantProtectionProductOptions(search, loadedOptions, offset, {
              date: data?.date!,
            })
          }
          onChange={(e) =>
            setData(
              e
                ? {
                    ...data,
                    plantProtectionProduct: {
                      uuid: e.value,
                      name: e.label,
                      manufacturerName: e.manufacturerName,
                      category: e.category,
                    },
                  }
                : deleteProps(data, [
                    "plantProtectionProduct",
                    "manufacturerName",
                    "category",
                  ])
            )
          }
          disabled={!data?.date}
        />
      ),
    },
    {
      label: t("Producer"),
      input: (
        <TextInput
          value={data?.plantProtectionProduct?.manufacturerName || ""}
          setValue={(e) =>
            setData({
              ...data,
              plantProtectionProduct: {
                ...data?.plantProtectionProduct!,
                manufacturerName: e,
              },
            })
          }
          disabled={true}
        />
      ),
    },
    {
      label: t("Category"),
      input: (
        <TextInput
          value={data?.plantProtectionProduct?.category || ""}
          setValue={(e) =>
            setData({
              ...data,
              plantProtectionProduct: {
                ...data?.plantProtectionProduct!,
                category: e,
              },
            })
          }
          disabled={true}
        />
      ),
    },
    {
      label: t("Supplier"),
      input: (
        <TextInput
          value={data?.supplierName || ""}
          setValue={(e) =>
            setData({
              ...data,
              supplierName: e,
            })
          }
        />
      ),
    },
    {
      label: t("SupplierForm"),
      input: (
        <SelectAsyncPaginate
          required
          value={
            data?.organizationalForm
              ? {
                  value: data.organizationalForm.uuid,
                  label: data.organizationalForm.name,
                }
              : null
          }
          loadOptions={(
            search: string,
            loadedOptions: [],
            offset: { page: number }
          ) =>
            getFarmOrganizationFormOptions(search, loadedOptions, offset, {
              physicalPersonHolder: false,
            })
          }
          onChange={(e) =>
            setData(
              e
                ? {
                    ...data,
                    organizationalForm: {
                      name: e.label,
                      uuid: e.value,
                    },
                  }
                : deleteProps(data, ["organizationalForm"])
            )
          }
        />
      ),
    },
    {
      label: t("Quantity"),
      input: (
        <NumberInput
          required
          decimalPlaces={2}
          value={data?.quantity || ""}
          setValue={(e) => setData({ ...data, quantity: e })}
        />
      ),
    },
    {
      label: t("UnitOfMeasure"),
      input: (
        <Select
          required
          value={data?.unit ? { value: data.unit, label: data.unit } : null}
          onChange={(e) => setData({ ...data, unit: !!e ? e.value : null })}
          options={unitOfMeasures.map((unitOfMeasure) => ({
            label: unitOfMeasure,
            value: unitOfMeasure,
          }))}
          isClearable
        />
      ),
    },
  ];

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title={t(isDeletable ? "PesticideDetails" : "AddPesticidePurchase")}
      confirmButton={{
        onFormSubmit: onSubmit,
        label: isDeletable ? t("Edit") : t("Confirm"),
      }}
      cancelButton={{ onClick: onClose }}
      deleteButton={
        isDeletable ? { onClick: onDelete, label: t("Remove") } : undefined
      }
      size="m"
    >
      <div className="flex column gap-4">
        {formInfo.map((e) => (
          <Input key={e.label} component={e} edit />
        ))}
      </div>
    </Dialog>
  );
};
