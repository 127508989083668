import jwtDecode from "jwt-decode";
import { AUTH_API_URL, axiosAuth } from "../App";
import { ITokenRaw } from "../types/IToken";
import {
  localStorageGet,
  localStorageRemove,
  localStorageSet,
} from "../utils/LocalStorageHelper";
import { createCodeChallenge, createCodeVerifier } from "../utils/login";

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || "";

const redirectUri = process.env.REACT_APP_REDIRECT_URL || "";

export const login = () => {
  const codeVerifier = createCodeVerifier();
  localStorageSet("codeVerifier", codeVerifier);

  const url = `${AUTH_API_URL}/oauth2/authorize?
response_type=code&
code_challenge=${createCodeChallenge(codeVerifier)}&
code_challenge_method=S256&
client_id=${CLIENT_ID}&
redirect_uri=${redirectUri}
&scope=id mail`;

  window.location.href = url;
};

// TO DO -> Check this url, and delete it if it is unnessesery
export const checkSession = () => {
  window.location.href = `${AUTH_API_URL}/oidc/checksession?client_id=${CLIENT_ID}`;
};

export const logout = () => {
  localStorageRemove("token");
  localStorageRemove("tokenExp");
  localStorageRemove("refreshToken");
  localStorageRemove("codeVerifier");
  localStorageRemove("activeOrg");
  // checkSession();
  window.location.href = "/";
  // window.location.href = `${AUTH_API_URL}/oidc/logout?redirect_uri=${redirectUri}`;
};

const handleGetToken = (code?: string) => {
  const refreshToken = localStorageGet("refreshToken");

  const data = new URLSearchParams();
  code
    ? data.append("code", code)
    : refreshToken && data.append("refresh_token", refreshToken);
  data.append("code_verifier", localStorageGet("codeVerifier") || "");
  data.append("client_id", CLIENT_ID);
  data.append("redirect_uri", redirectUri);
  data.append("grant_type", code ? "authorization_code" : "refresh_token");
  data.append("response_type", "id_token");

  return axiosAuth
    .post("/oauth2/token", data, {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
    .then(({ data }) => {
      const tokenDecoded = jwtDecode<ITokenRaw>(data.access_token);
      localStorageSet("token", data.access_token);
      localStorageSet("refreshToken", data.refresh_token);
      localStorageSet("tokenExp", `${tokenDecoded.exp}`);
      return data.access_token;
    });
};

export const getToken = (code: string) => handleGetToken(code);
export const refreshToken = () => handleGetToken();
