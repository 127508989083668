import { Button } from "@inceptionbg/ui-components";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog } from "../../../Elements/Dialog";
import { Input } from "../../../Elements/Input";
import { Loader } from "../../../Elements/Loader";
import { deleteInvoice } from "../../../repos/InvoiceRepo";
import { IInvoice } from "../../../types/IInvoice";
import { AddInvoiceDialog } from "./Add/AddInvoiceDialog";
import { getInvoiceData } from "./Add/invoiceData";

interface Props {
  invoice: IInvoice;
  reloadInvoices: () => void;
}

export const InvoiceForm: FC<Props> = ({ invoice, reloadInvoices }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [replaceInvoiceOpen, setReplaceInvoiceOpen] = useState(false);
  const [invoiceToDeleteUuid, setInvoiceToDeleteUuid] = useState("");

  const { t } = useTranslation();

  const data = getInvoiceData({
    formData: invoice,
    t,
    edit: false,
  });

  const isWaitingComplement =
    invoice?.approval?.approvalStatus === "WAITING_COMPLEMENT";

  const onDelete = () => {
    setIsLoading(true);
    deleteInvoice(invoice.uuid)
      .then(() => reloadInvoices())
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  return (
    <div>
      <Loader isLoading={isLoading}>
        <>
          <div className="form-container">
            {data.map((e) => (
              <Input key={e.label} component={e} />
            ))}
          </div>
          {!invoice.used && (
            <div className="button-container mt-3">
              {invoice.source === "UI" && (
                <Button
                  label={t("DeleteInvoice")}
                  outlined
                  onClick={() => setInvoiceToDeleteUuid(invoice.uuid)}
                  className="error"
                  size="s"
                />
              )}

              {isWaitingComplement && (
                <Button
                  label={t("ReplaceInvoice")}
                  onClick={() => setReplaceInvoiceOpen(true)}
                  primary
                  size="s"
                />
              )}
            </div>
          )}
        </>
      </Loader>
      <Dialog
        isOpen={!!invoiceToDeleteUuid}
        onClose={() => setInvoiceToDeleteUuid("")}
        title={t("DeleteInvoice")}
        desc={t("DeleteInvoiceDesc")}
        isLoading={isLoading}
        confirmButton={{ label: t("Confirm"), onClick: onDelete }}
        cancelButton={{}}
      />
      {isWaitingComplement && (
        <AddInvoiceDialog
          isOpen={replaceInvoiceOpen}
          onClose={() => setReplaceInvoiceOpen(false)}
          invoice={invoice}
          reloadInvoices={reloadInvoices}
        />
      )}
    </div>
  );
};
