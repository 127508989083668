import { FC, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Section } from "../../Section";
import { Button } from "@inceptionbg/ui-components";
import { RealLandDialog } from "../../Dialogs/RealLandDialog";
import { getRealLands } from "../../../repos/LandRepo";
import { IRealLand } from "../../../types/ILand";
import { RealLand } from "../Land/RealLand";
import { Dialog } from "../../../Elements/Dialog";
import { FarmContext } from "../../../AppContent";

export const RealLandSection: FC = () => {
  const { t } = useTranslation();
  const [addRealLand, setAddRealLand] = useState(false);

  const farmUuid = useContext(FarmContext).farm?.uuid;

  const [realLands, setRealLands] = useState<IRealLand[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [confirmDialogIsOpen, setConfirmDialogIsOpen] = useState(false);

  const handleGetRealLands = useCallback((callback?: () => void) => {
    setIsLoading(true);
    getRealLands({
      fetchOptions: ["CADASTRE_MUNICIPALITY"],
      ...(farmUuid ? { farmUuid } : {}),
    })
      .then((e) => {
        setRealLands(e.realLands);
        callback && callback();
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, [farmUuid]);

  useEffect(handleGetRealLands, [handleGetRealLands]);

  return (
    <Section title={t("FactualRealLandTitle")}>
      <div className="form-container">
        <div className="flex justify-right gap-2 mt-3 mb-5">
          <Button
            label={t("LandAdd")}
            primary
            onClick={() => setAddRealLand(true)}
          />
        </div>
        {!isLoading &&
          realLands.map((land) => (
            <RealLand
              key={land.uuid}
              realLand={land}
              reloadLands={(callback?: () => void) => {
                handleGetRealLands();
                callback && callback();
              }}
            />
          ))}
        <RealLandDialog
          isOpen={!!addRealLand}
          onClose={() => setAddRealLand(false)}
          handleGetRealLands={handleGetRealLands}
          setConfirmDialogIsOpen={setConfirmDialogIsOpen}
        />
      </div>

      <Dialog
        isOpen={confirmDialogIsOpen}
        onClose={() => setConfirmDialogIsOpen(false)}
        title={t("ConfirmTitleRequest")}
        desc={t("ConfirmDescRealLandRequest")}
        confirmButton={{
          onClick: () => {
            setConfirmDialogIsOpen(false);
          },
          label: t("Ok"),
        }}
        notMobileFullScreen
        className="consent-page-dialog"
      />
    </Section>
  );
};
