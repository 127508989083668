import {
  Button,
  DateInput,
  deleteProps,
  formatDate,
  TextInput,
} from "@inceptionbg/ui-components";
import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../../../Context/UserContext";
import { FarmContext } from "../../../../AppContent";
import { IInputComponentProps, Input } from "../../../../Elements/Input";
import { updateLegalRepresentative } from "../../../../repos/FarmRepo";
import { IFarmNew, ILegalRepresentativeNew } from "../../../../types/IFarm";
import { genderTypes } from "../../../../types/IMember";
import { getBirthDateFromPIN } from "../../../../utils/CalculateUtils";
import { inputPattern } from "../../../../utils/InputPatternValidation";
import { getCountriesOptions } from "../../../../utils/LoadOptions";
import { Select } from "../../../Inputs/Select";
import { SelectAsyncPaginate } from "../../../Inputs/SelectAsyncPaginate";
import { SubSection } from "../../../SubSection";
import { Form } from "../../../Wrapper/FormWrapper";
import { EvidencesUploadDialog } from "../../../Files/EvidencesUploadDialog";
import { EvidencesComponent } from "../../../Files/EvidencesComponent";
import { IEvidence } from "../../../../types/IFile";

interface Props {
  farmData?: IFarmNew;
  setFarm?: Dispatch<SetStateAction<IFarmNew>>;
}

export const LegalRepresentative: FC<Props> = ({ farmData, setFarm }) => {
  const [legalRep, setLegalRep] = useState<ILegalRepresentativeNew>({});
  const [formData, setFormData] = useState<ILegalRepresentativeNew>({});
  const [evidences, setEvidences] = useState<IEvidence[]>([]);
  const [edit, setEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [displayPersonalIdentityNumber, setDisplayPersonalIdentityNumber] =
    useState(false);
  const [attachmentsOpen, setAttachmentsOpen] = useState(false);
  const [attachments2Open, setAttachments2Open] = useState(false);

  const { t } = useTranslation();
  const { farm, reloadFarm } = useContext(FarmContext);
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (farm && !setFarm) {
      setLegalRep(farm.legalRepresentative || {});
      setFormData(farm.legalRepresentative || {});
    } else {
      setFormData((e) => ({
        ...e,
        firstName: user?.firstName || "",
        lastName: user?.lastName || "",
        personalIdentityNumber: user?.personalIdentityNumber || "",
        birthDate: getBirthDateFromPIN(user?.personalIdentityNumber) || "",
      }));
    }
  }, [user, farm, setFarm]);

  useEffect(() => {
    !!setFarm &&
      setFarm((e) => ({
        ...e,
        legalRepresentative: {
          evidences: e.legalRepresentative?.evidences,
          ...formData,
        },
      }));
  }, [formData, setFarm]);

  const legalRepInfo: IInputComponentProps[] = [
    {
      label: t("PersonalIdentityNumber"),
      value: displayPersonalIdentityNumber
        ? formData.personalIdentityNumber
        : "",
      valueElement: !displayPersonalIdentityNumber ? (
        <p
          className="link"
          onClick={() =>
            !!setDisplayPersonalIdentityNumber &&
            setDisplayPersonalIdentityNumber(true)
          }
        >
          {t("DisplayPersonalIdentityNumber")}
        </p>
      ) : undefined,
      input: (
        <TextInput
          required
          value={formData.personalIdentityNumber}
          setValue={(e) =>
            setFormData({ ...formData, personalIdentityNumber: e })
          }
          inputProps={{ pattern: inputPattern.personalIdentityNumber }}
        />
      ),
      readOnly: !!setFarm,
    },
    {
      label: t("FirstName"),
      value: formData.firstName,
      input: (
        <TextInput
          required
          value={formData.firstName}
          setValue={(e) => setFormData({ ...formData, firstName: e })}
        />
      ),
      readOnly: !!setFarm,
    },
    {
      label: t("LastName"),
      value: formData.lastName,
      input: (
        <TextInput
          required
          value={formData.lastName}
          setValue={(e) => setFormData({ ...formData, lastName: e })}
        />
      ),
      readOnly: !!setFarm,
    },
    {
      label: t("BirthDate"),
      value: formatDate(formData.birthDate),
      input: (
        <DateInput
          required
          date={formData.birthDate}
          setDate={(e) => setFormData({ ...formData, birthDate: e })}
        />
      ),
    },
    {
      label: t("BirthPlace"),
      value: formData.birthPlace,
      input: (
        <TextInput
          required
          value={formData.birthPlace}
          setValue={(e) => setFormData({ ...formData, birthPlace: e })}
        />
      ),
    },
    {
      label: t("BirthCountry"),
      value: formData.birthCountry?.name,
      input: (
        <SelectAsyncPaginate
          required
          value={
            formData.birthCountry
              ? {
                  label: formData.birthCountry.name,
                  value: formData.birthCountry.code,
                }
              : null
          }
          loadOptions={getCountriesOptions}
          onChange={(e) =>
            setFormData(
              e
                ? {
                    ...formData,
                    birthCountry: { name: e.label, code: e.value },
                  }
                : deleteProps(formData, ["birthCountry"])
            )
          }
        />
      ),
    },
    {
      label: t("Gender"),
      value: formData.gender ? t(formData.gender) : "/",
      input: (
        <Select
          required
          value={
            formData.gender
              ? { label: t(formData.gender), value: formData.gender }
              : null
          }
          options={genderTypes.map((e) => ({ label: t(e), value: e }))}
          onChange={(e) => setFormData({ ...formData, gender: e.value })}
        />
      ),
    },
    {
      label: t("Street"),
      value: formData.street,
      input: (
        <TextInput
          required
          value={formData.street}
          setValue={(e) => setFormData({ ...formData, street: e })}
        />
      ),
    },
    {
      label: t("NumberLetter"),
      value: formData.houseNumber,
      input: (
        <TextInput
          required
          value={formData.houseNumber}
          setValue={(e) => setFormData({ ...formData, houseNumber: e })}
        />
      ),
    },
    {
      label: t("Place"),
      value: formData.place,
      input: (
        <TextInput
          required
          value={formData.place}
          setValue={(e) => setFormData({ ...formData, place: e })}
        />
      ),
    },
    {
      label: t("Municipality"),
      value: formData.municipality,
      input: (
        <TextInput
          required
          value={formData.municipality}
          setValue={(e) => setFormData({ ...formData, municipality: e })}
        />
      ),
    },
    {
      label: t("PostalCode"),
      value: formData.postalCode,
      input: (
        <TextInput
          required
          value={formData.postalCode}
          setValue={(e) => setFormData({ ...formData, postalCode: e })}
          inputProps={{ pattern: inputPattern.postalCode }}
        />
      ),
    },
  ];

  const onSubmit = () => {
    if (farm?.uuid) {
      const data = evidences.length ? { ...formData, evidences } : formData;
      setIsLoading(true);
      updateLegalRepresentative(farm.uuid, data)
        .then(() => {
          reloadFarm(() => {
            setIsLoading(false);
            setEdit(false);
          });
        })
        .catch(() => setIsLoading(false));
    }
  };

  return (
    <SubSection
      title={t("LegalRepTitleOrdered")}
      expandable={!setFarm}
      alerts={
        setFarm
          ? [
              {
                text: t("MissingAttachment"),
                type: "error",
                hidden: !!farmData?.legalRepresentative?.evidences?.length,
              },
            ]
          : [
              {
                text: `${t("WaitingComplementText")}
                          ${formData.approval?.statusComment}`,
                type: "error",
                number: formData.approval?.approvalNumber,
                hidden:
                  formData.approval?.approvalStatus !== "WAITING_COMPLEMENT",
              },
              {
                text: t("LegalRepresentativeNotVerifiedInfo"),
                type: "info",
                number: formData.approval?.approvalNumber,
                hidden:
                  !!formData.approved ||
                  formData.approval?.approvalStatus === "WAITING_COMPLEMENT" ||
                  formData.approval?.approvalStatus === "REJECTED",
              },
            ]
      }
    >
      {setFarm ? (
        <div className="form-container">
          {legalRepInfo.map(
            (e) => !e.hidden && <Input key={e.label} component={e} edit />
          )}
          <div className="flex justify-right">
            <Button
              label={t("UploadAttachments")}
              primary
              onClick={() => setAttachmentsOpen(true)}
              size="s"
            />
          </div>
        </div>
      ) : (
        <Form
          data={legalRepInfo}
          edit={edit}
          setEdit={setEdit}
          submitButton={{
            editLabel: t(edit ? "Confirm" : "LegalRepEdit"),
            onSubmit: () => setAttachments2Open(true),
          }}
          otherButtons={[
            {
              label: t("UploadAttachments"),
              onClick: () => setAttachmentsOpen(true),
              primary: true,
              hidden: formData.approved || edit,
            },
          ]}
          onResetForm={() => setFormData(legalRep)}
          isLoading={isLoading}
        />
      )}
      <EvidencesUploadDialog
        isOpen={attachmentsOpen}
        onClose={() => {
          setAttachmentsOpen(false);
          if (formData?.uuid) {
            reloadFarm(() => {
              setIsLoading(false);
              setEdit(false);
            });
          }
        }}
        category="LEGAL_REPRESENTATIVE"
        sectionEvidences={farmData?.legalRepresentative?.evidences}
        setFarm={setFarm}
        approvalUuid={farm?.legalRepresentative?.approval?.uuid}
      />
      <EvidencesComponent
        isOpen={attachments2Open}
        onClose={() => setAttachments2Open(false)}
        category="LEGAL_REPRESENTATIVE"
        evidences={evidences}
        setEvidences={setEvidences}
        onSubmit={onSubmit}
      />
    </SubSection>
  );
};
