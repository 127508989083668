import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { IRefactionRequestItemNew } from "../../../../../types/IRefaction";
import { RefactionItemsForm } from "./RefactionItemForm";
import { SubSection } from "../../../../SubSection";
import { IFuelInfo } from "../../../../../types/IInvoice";
import { getInvoicesFuel } from "../../../../../repos/InvoiceRepo";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { Input } from "../../../../../Elements/Input";
import { Alert } from "../../../../../Elements/Alert/Alert";
import { Button } from "@inceptionbg/ui-components";

interface Props {
  items: IRefactionRequestItemNew[];
  setItems: Dispatch<SetStateAction<IRefactionRequestItemNew[]>>;
  invoiceUuids: string[];
  onFormVisibilityChange: (isVisible: boolean) => void;
}

export const RefactionItems: FC<Props> = ({
  items,
  setItems,
  invoiceUuids,
  onFormVisibilityChange,
}) => {
  const [fuelTotals, setFuelTotals] = useState<IFuelInfo[]>([]);
  const [totalFuelUsed, setTotalFuelUsed] = useState<{
    [fuelTypeName: string]: number;
  }>({});
  const [error, setError] = useState("");
  const [isFormVisible, setFormVisible] = useState(!items.length);

  const { t } = useTranslation();

  useEffect(() => {
    if (items.length > 0) {
      let totalsUsed: { [fuelTypeName: string]: number } = {};
      items.forEach((item) => {
        const fuelTypeUuid = item.fuelType?.uuid || "";

        // If the fuel type already exists in totals, add to its total liters, otherwise, initialize it
        totalsUsed[fuelTypeUuid]
          ? (totalsUsed[fuelTypeUuid] += item.liter || 0)
          : (totalsUsed[fuelTypeUuid] = item.liter || 0);
      });
      setTotalFuelUsed(totalsUsed);
    } else {
      setTotalFuelUsed({});
    }
  }, [items]);

  useEffect(() => {
    getInvoicesFuel(invoiceUuids)
      .then((e) => setFuelTotals(e))
      .catch(() => {});
  }, [invoiceUuids]);

  const canAdd = fuelTotals.some(
    (e) => e.liter > (totalFuelUsed[e.fuelType.uuid] || 0)
  );

  const onSubmit = (item: IRefactionRequestItemNew, callback: () => void) => {
    const fuelTypeUuid = item.fuelType!.uuid;
    const fuelUsed = totalFuelUsed[fuelTypeUuid] || 0;
    const maxFuel =
      fuelTotals.find((e) => e.fuelType.uuid === item.fuelType?.uuid)?.liter ||
      0;
    if (fuelUsed + (item.liter || 0) > maxFuel) {
      setError(t("ErrorREFRACTION_REQUEST_ITEM_LITER_SUM_GT_INVOICE_ITEM_SUM"));
    } else if ((item.liter || 0) > (item.landPlantCulture?.maxLiter || 0)) {
      setError(
        t(
          "ErrorREFRACTION_REQUEST_ITEM_LITER_SUM_GT_LAND_PLANT_CULTURE_ALLOWED"
        )
      );
    } else {
      setError("");
      setItems([...items, item]);
      callback();
      setFormVisible(false);
      onFormVisibilityChange(false);
    }
  };

  return (
    <div className="flex column gap-3">
      {fuelTotals.length > 0 && (
        <div className="flex column gap-2 mx-auto mb-4 w-600">
          <p className="bold">{t("TotalInvoiceFuels")}:</p>
          {fuelTotals.map((e) => {
            const usedTotal = totalFuelUsed[e.fuelType.uuid] || 0;
            const invoiceTotal = e.liter;
            return (
              <Input
                key={e.fuelType.uuid}
                component={{
                  label: e.fuelType?.name,
                  valueElement: (
                    <span
                      className={clsx({
                        "error-text": usedTotal > invoiceTotal,
                      })}
                    >{`${usedTotal} / ${invoiceTotal} l`}</span>
                  ),
                  alert: {
                    active: usedTotal > invoiceTotal,
                    type: "error",
                  },
                }}
              />
            );
          })}
        </div>
      )}
      {items.map((item, itemIndex) => (
        <SubSection
          key={
            item.uuid ||
            `${itemIndex} ${item.landObj?.label} ${item.fuelType?.name} ${item.landPlantCulture?.uuid}`
          }
          title={`${item.landObj?.label || ""} - ${item.fuelType?.name}: ${
            item.liter
          } l`}
          expandable
          className="pt-0"
        >
          <RefactionItemsForm item={item} />
          <div className="flex justify-right mt-3">
            <Button
              label={t("DeleteItem")}
              primary
              className="error"
              size="s"
              onClick={() =>
                setItems((e) => {
                  let newItemse = [...e];
                  newItemse.splice(itemIndex, 1);
                  return newItemse;
                })
              }
            />
          </div>
        </SubSection>
      ))}
      <div>
        <div className="page-sub-section">
          <div className="sub-section-title" />
        </div>
        {error && <Alert text={error} type="error" className="mb-3" />}
        {isFormVisible ? (
          canAdd && (
            <RefactionItemsForm
              edit
              onSubmit={onSubmit}
              newForm
              invoiceUuids={invoiceUuids}
              setIsFormVisible={() => {
                setFormVisible(false);
                onFormVisibilityChange(false);
              }}
              items={items}
            />
          )
        ) : (
          <div className="flex justify-right">
            <Button
              label={t("LandAddNew")}
              primary
              size="s"
              onClick={() => {
                setFormVisible(true);
                onFormVisibilityChange(true);
              }}
              disabled={!canAdd}
            />
          </div>
        )}
      </div>
    </div>
  );
};
