import { axiosInstance } from "../App";
import { IAuthorizedPersonNew } from "../types/IAuthorizedPerson";

export const getAuthorizedPersons = (farmUuid: string) =>
  axiosInstance
    .get(`authorized-persons`, { params: { farmUuid } })
    .then(({ data }) => ({ authorizedPersons: data.authorizedPersons as IAuthorizedPersonNew[] }));

export const addAuthorizedPerson = (farmUuid: string, data: IAuthorizedPersonNew) =>
  axiosInstance.post(`/authorized-person`, { ...data, farm: { uuid: farmUuid } });

export const deleteAuthorizedPerson = (authorizedPersonUuid: string) =>
  axiosInstance.delete(`/authorized-person/${authorizedPersonUuid}`);
