import { FC } from "react";
import { IRealLand } from "../../../types/ILand";
import { convertSurfaceToObj } from "../../../utils/CalculateUtils";
import { SubSection } from "../../SubSection";
import { RealLandForm } from "../Common/RealLandForm";
import { useTranslation } from "react-i18next";

interface Props {
  realLand: IRealLand;
  reloadLands: (callback?: () => void) => void;
}

export const RealLand: FC<Props> = ({ realLand, reloadLands }) => {
  const { t } = useTranslation();
  const surfaceObj = convertSurfaceToObj(realLand.surface);

  return (
    <SubSection
      titleMulti={[
        `${t('CadastreMunicipalityShort')} ${realLand.cadastreMunicipality.name}`,
        `${t('CadastreNumberShort')} ${realLand.cadastreNumber}`,
        `${surfaceObj?.ha || 0}ha ${surfaceObj?.a || 0}a ${
          surfaceObj?.m || 0
        }m²`,
      ]}
      expandable
    >
      <RealLandForm
        realLand={realLand}
        reloadLands={reloadLands}
      />
    </SubSection>
  );
};
