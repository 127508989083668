import { Button } from "@inceptionbg/ui-components";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog } from "../../Elements/Dialog";
import { ModeContext } from "../../Pages/RPGPage";
import { deleteEvidence } from "../../repos/FileRepo";
import { IEvidence } from "../../types/IFile";
import { API_URL } from "../../App";

interface Props {
  evidences: IEvidence[];
  reloadEvidences?: () => void;
  onDeleteFile?: (index: number, callback: () => void) => void;
}
export const EvidencesList: FC<Props> = ({
  evidences,
  reloadEvidences,
  onDeleteFile,
}) => {
  const [evidenceToDelete, setEvidenceToDelete] = useState<{
    evidence: IEvidence;
    index: number;
  } | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const isRenew = useContext(ModeContext) === "RENEW";

  const onDelete = () => {
    if (onDeleteFile) {
      onDeleteFile(evidenceToDelete!.index, () => setEvidenceToDelete(null));
    } else {
      setIsLoading(true);
      deleteEvidence(evidenceToDelete!.evidence.uuid!)
        .then(() => {
          reloadEvidences && reloadEvidences();
          setEvidenceToDelete(null);
        })
        .catch(() => {})
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <div className="files-list">
      <p className="files-header">{t("Document")}:</p>
      <div className="list">
        {evidences.map((evidence, index) => (
          <div key={evidence.storedFile.uuid} className="file">
            {evidence.storedFile.downloadUrl ? (
              <a
                className="file-name link"
                href={API_URL + evidence.storedFile.downloadUrl}
                target="_blank"
                rel="noreferrer"
              >
                {evidence.storedFile.originalFileName}
              </a>
            ) : (
              <p>{evidence.storedFile.originalFileName}</p>
            )}
            {!isRenew && (
              <Button
                label={t("RemoveDocument")}
                size="xs"
                outlined
                disabled={evidence.concluded}
                onClick={() => {
                  setEvidenceToDelete({ evidence, index });
                }}
              />
            )}
          </div>
        ))}
      </div>
      <Dialog
        isOpen={!!evidenceToDelete}
        onClose={() => setEvidenceToDelete(null)}
        title={t("RemoveDocument")}
        desc={`${t("RemoveDocumentDesc")} ${
          evidenceToDelete?.evidence.storedFile.originalFileName
        }?`}
        isLoading={isLoading}
        confirmButton={{ label: t("Confirm"), onClick: onDelete }}
        cancelButton={{}}
        notMobileFullScreen
      />
    </div>
  );
};
