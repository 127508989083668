import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextInput } from "@inceptionbg/ui-components";
import { IInputComponentProps } from "../../../Elements/Input";
import { Form } from "../../Wrapper/FormWrapper";
import { SubSection } from "../../SubSection";
import { saveGeneralRequest } from "../../../repos/GeneralRepo";
import { IRequestNew, IRequestType } from "../../../types/IRequest";
import { FarmContext } from "../../../AppContent";

interface Props {
  title: string;
  requestType: IRequestType;
}

export const CertificateBasic: FC<Props> = ({ title, requestType }) => {
  const [certificate, setCertificate] = useState<IRequestNew>({});
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const farmUuid = useContext(FarmContext).farm?.uuid;

  const certificateInfo: IInputComponentProps[] = [
    {
      label: t("CertificatePurpose"),
      value: certificate.purpose,
      input: (
        <TextInput
          required
          placeholder={t("FreeTextPlaceholder")}
          value={certificate.purpose}
          setValue={(e) => setCertificate({ ...certificate, purpose: e })}
        />
      ),
    },
  ];

  const onSubmit = () => {
    setIsLoading(true);
    saveGeneralRequest({
      ...certificate,
      requestType,
      deliveryType: "INBOX",
      farm: { uuid: farmUuid },
    })
      .then(() => setCertificate({}))
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  return (
    <SubSection title={title}>
      <Form
        data={certificateInfo}
        edit={true}
        setEdit={() => {}}
        submitButton={{
          editLabel: t("SubmitRequest"),
          primary: true,
          onSubmit,
        }}
        onResetForm={() => setCertificate({})}
        isLoading={isLoading}
      />
    </SubSection>
  );
};
