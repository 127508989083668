import { axiosInstance } from "../App";
import {
  IRefactionRequest,
  IRefactionStatus,
  RefactionRequestFetchOption,
} from "../types/IRefaction";

export const getRefactionRequests = (
  farmUuid: string,
  fetchOptions?: RefactionRequestFetchOption[]
) =>
  axiosInstance
    .get(`/farm/${farmUuid}/refraction-requests`, {
      params: { fetchOptions },
    })
    .then(({ data }) => ({
      refractionRequests: data.refractionRequests as IRefactionRequest[],
    }));

export const getRefractionRequestByUuid = (requestUuid: string) =>
  axiosInstance.get(`/refraction-request/${requestUuid}`).then(({ data }) => ({
    refractionRequest: data as IRefactionRequest,
  }));

export const addEditRefactionRequests = (data: IRefactionRequest) =>
  axiosInstance
    .post("/refraction-request", data, {
      headers: { noToast: true },
    })
    .then(({ data }) => ({
      identifier: data.identifier as string,
    }));

export const updateRefractionRequest = (
  requestUuid: string,
  status: IRefactionStatus
) =>
  axiosInstance
    .patch(`/refraction-request`, { uuid: requestUuid, status })
    .then(({ data }) => ({
      caseNumber: data.caseNumber as string,
    }));

export const deleteRefactionRequest = (requestUuid: string) =>
  axiosInstance.delete(`/refraction-request/${requestUuid}`);
