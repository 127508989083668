import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Section } from "../../Section";
import { InfoBox } from "../../../Elements/Alert/InfoBox";
import { CertificateBasic } from "./CertificateBasic";
import { StatementBasic } from "./StatementBasic";

export const StatementSection: FC = () => {
  const { t } = useTranslation();

  return (
    <Section title={t("StatementsAndCertificatesTitle")}>
      <InfoBox
        title={t("RequestStatementTitle")}
        desc={t("RequestStatementDesc")}
        className="ml-4 mr-4 mb-4 mt-0"
        light
      />
      <div className="form-container">
        <StatementBasic />
        <CertificateBasic
          title={t("CurrentStatusCertificateTitle")}
          requestType="STATUS_CONFIRMATION"
        />
        {/* <CertificateBasic
          title={t("DurationOdTheActiveStatusCertificateTitle")}
          requestType={"ACTIVE_STATUS_LEAST_CONFIRMATION"}
        />*/}
      </div>
    </Section>
  );
};
