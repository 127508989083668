import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DateInput,
  TextInput,
  deleteEmptyProps,
  deleteProps,
  formatDate,
} from "@inceptionbg/ui-components";
import { IInputComponentProps } from "../../../Elements/Input";
import { Form } from "../../Wrapper/FormWrapper";
import { SubSection } from "../../SubSection";
import { Select } from "../../Inputs/Select";
import { DeliveryTypes, IRequestNew } from "../../../types/IRequest";
import { saveGeneralRequest } from "../../../repos/GeneralRepo";
import { FarmContext } from "../../../AppContent";
import { getGeneralRequestTypeOptions } from "../../../utils/LoadOptions";
import { SelectAsyncPaginate } from "../../Inputs/SelectAsyncPaginate";
import { API_URL } from "../../../App";

export const StatementBasic: FC = () => {
  const [statement, setStatement] = useState<IRequestNew>({});
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const farm = useContext(FarmContext).farm!;

  const statementInfo: IInputComponentProps[] = [
    {
      label: t("StatementType"),
      value: statement.deliveryType,
      input: (
        <SelectAsyncPaginate
          required
          placeholder={t("ChoosePlaceholder")}
          value={statement.requestTypeObj || null}
          loadOptions={getGeneralRequestTypeOptions}
          onChange={(e) =>
            setStatement({
              ...statement,
              requestTypeObj: e,
              requestType: e.value,
            })
          }
        />
      ),
    },
    {
      label: t("AtDate"),
      value: formatDate(statement.atDay),
      hidden: statement.requestType !== "EXCERPT_PLANT_CULTURE",
      input: setStatement && (
        <DateInput
          date={statement.atDay}
          setDate={(e) => setStatement({ ...statement, atDay: e })}
          helperText={t("AtDateHelper")}
        />
      ),
    },
    {
      label: t("DeliveryType"),
      value: statement.deliveryType,
      input: (
        <Select
          required
          placeholder={t("ChoosePlaceholder")}
          value={
            statement.deliveryType
              ? {
                  label: t(`DeliveryType${statement.deliveryType}`),
                  value: statement.deliveryType,
                }
              : null
          }
          options={DeliveryTypes.map((e) => ({
            label: t(`DeliveryType${e}`),
            value: e,
          }))}
          onChange={(e) =>
            setStatement({ ...statement, deliveryType: e.value })
          }
        />
      ),
    },
    {
      label: t("StatementPurpose"),
      value: statement.purpose,
      input: (
        <TextInput
          required
          placeholder={t("FreeTextPlaceholder")}
          value={statement.purpose}
          setValue={(e) => setStatement({ ...statement, purpose: e })}
        />
      ),
    },
  ];

  const onSubmit = () => {
    setIsLoading(true);
    const data = deleteEmptyProps(
      deleteProps({ ...statement, farm: { uuid: farm.uuid } }, [
        "requestTypeObj",
      ])
    );
    saveGeneralRequest(data)
      .then((identifier) => {
        if (statement.deliveryType === "DOWNLOAD") {
          const getFileName = (requestType?: string) => {
            switch (requestType) {
              case "EXCERPT_FROM_REGISTER":
                return `farm_status_confirmation_${farm.registrationNumber}.pdf`;
              case "EXCERPT_ANIMAL":
                return `animal_${farm.registrationNumber}.pdf`;
              case "EXCERPT_PLANT_CULTURE":
                return `plant_cultures_${farm.registrationNumber}.pdf`;

              default:
                return "confirmation.pdf";
            }
          };

          window.open(
            API_URL +
              `/general-request/${identifier}/${getFileName(
                statement.requestType
              )}`,
            "_blank"
          );
        }
        setStatement({});
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  return (
    <SubSection title={t("StatementSectionTitle")}>
      <Form
        data={statementInfo}
        edit
        setEdit={() => {}}
        submitButton={{
          editLabel: t("SubmitRequest"),
          primary: true,
          onSubmit,
        }}
        onResetForm={() => setStatement({})}
        isLoading={isLoading}
      />
    </SubSection>
  );
};
