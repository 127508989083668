import {
  Button,
  formatDate,
  formatDateAndTime,
} from "@inceptionbg/ui-components";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "../../../Elements/Input";
import { IRefactionRequest } from "../../../types/IRefaction";
import { SubSection } from "../../SubSection";
import { SummaryRefactionDialog } from "./Add/SummaryRefactionDialog";

interface Props {
  refactionReq: IRefactionRequest;
  reloadData: (caseNumber: string) => void;
  initialyOpen?: boolean;
  onEdit?: () => void;
}

export const RefactionReq: FC<Props> = ({
  refactionReq,
  reloadData,
  initialyOpen = false,
  onEdit,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation();

  return (
    <SubSection
      title={`${
        refactionReq?.caseNumber ? `${refactionReq?.caseNumber} - ` : ""
      } ${formatDate(refactionReq.createdAt)} - ${t(
        `RefactionStatus${refactionReq.status}`
      )}
      `}
      expandable
      initialyOpen={initialyOpen}
    >
      <Input
        component={{
          label: t("CreatedAtTime"),
          value: formatDateAndTime(refactionReq.createdAt),
        }}
      />
      <SubSection
        title={t("Invoices")}
        secondary
        expandable={!!refactionReq.invoices?.length}
      >
        {refactionReq.invoices?.map((invoice) => (
          <SubSection
            key={invoice.uuid}
            title={`${formatDate(invoice?.date)} - ${invoice?.number}`}
            expandable={!!invoice.items?.length}
            tertiary
          >
            {invoice.items?.map((item) => (
              <Input
                key={item.uuid}
                component={{
                  label: item.fuelType?.name || "",
                  value: `${item.liter || "0"} l`,
                }}
              />
            ))}
          </SubSection>
        ))}
      </SubSection>
      <SubSection title={t("RequestItems")} expandable secondary>
        {refactionReq.items?.map((item) => (
          <SubSection
            key={item.uuid}
            title={`${
              item.landPlantCulture?.land?.nickname ||
              item.landPlantCulture?.land?.cadastreNumber ||
              ""
            } - ${item.landPlantCulture?.plantCulture?.name || ""}`}
            expandable
            tertiary
          >
            <Input
              component={{
                label: t("Municipality"),
                value: item.landPlantCulture?.land?.cadastreMunicipality.name,
              }}
            />
            <Input
              component={{
                label: t("Land"),
                value:
                  item.landPlantCulture?.land?.nickname ||
                  item.landPlantCulture?.land?.cadastreNumber,
              }}
            />
            <Input
              component={{
                label: t("LandCulture"),
                value: item.landPlantCulture?.plantCulture?.name,
              }}
            />
            <Input
              component={{
                label: item.fuelType?.name || "",
                value: `${item.liter || "0"} l`,
              }}
            />
          </SubSection>
        ))}
      </SubSection>
      {refactionReq.status === "DRAFT" && (
        <div className="button-container">
          {onEdit && (
            <Button
              label={t("Edit")}
              outlined
              size="s"
              onClick={() => onEdit()}
            />
          )}
          <Button
            label={t("RefactionRequestSubmit")}
            primary
            size="s"
            disabled={!refactionReq?.uuid}
            onClick={() => setIsOpen(true)}
          />
        </div>
      )}
      <SummaryRefactionDialog
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        refactionUuid={refactionReq.uuid}
        reloadData={reloadData}
      />
    </SubSection>
  );
};
