import { FC, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FarmContext } from "../../../AppContent";
import { Dialog } from "../../../Elements/Dialog";
import { createEvidenceFromFile, getEvidences } from "../../../repos/FileRepo";
import { IEvidence, IEvidenceFilters } from "../../../types/IFile";
import { FilesDropzone } from "../../Files/Dropzone/FilesDropzone";
import { EvidencesList } from "../../Files/EvidencesList";
import { deleteEmptyProps } from "@inceptionbg/ui-components";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  landUuid: string;
  approvalUuid?: string;
  reloadLands?: (callback?: () => void) => void;
}

export const EditLandEvidenceDialog: FC<Props> = ({
  isOpen,
  onClose,
  landUuid,
  approvalUuid,
  reloadLands,
}) => {
  const [evidences, setEvidences] = useState<IEvidence[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState<IEvidenceFilters>({});

  const farmUuid = useContext(FarmContext).farm?.uuid;
  const { t } = useTranslation();

  useEffect(() => {
    setFilters({ land: { uuid: landUuid } });
  }, [landUuid]);

  const handleGetEvidences = useCallback(() => {
    if (farmUuid) {
      setIsLoading(true);
      getEvidences(farmUuid, "LAND", { landUuid, approvalUuid })
        .then((e) => setEvidences(e.evidences))
        .catch(() => {})
        .finally(() => setIsLoading(false));
    }
  }, [farmUuid, landUuid, approvalUuid]);

  useEffect(handleGetEvidences, [handleGetEvidences]);

  const onSubmit = (data: File[]) => {
    if (farmUuid) {
      setIsLoading(true);
      data.forEach((file, i) => {
        createEvidenceFromFile(
          file,
          farmUuid,
          "LAND",
          deleteEmptyProps({
            land: { uuid: landUuid },
            approval: approvalUuid ? { uuid: approvalUuid } : undefined,
          })
        )
          .catch(() => {})
          .finally(() => {
            if (i === data.length - 1) {
              setIsLoading(false);
              handleGetEvidences();
            }
          });
      });
    }
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title={t("LandEvidenceTitle")}
      desc={t("LandEvidenceDesc")}
      confirmButton={{
        label: t("Confirm"),
        onClick: reloadLands,
      }}
      cancelButton={{}}
      isLoading={isLoading}
      size="m"
    >
      {!!evidences.length && (
        <EvidencesList
          evidences={evidences}
          reloadEvidences={handleGetEvidences}
        />
      )}
      <FilesDropzone
        onSubmit={onSubmit}
        category="LAND"
        reloadEvidences={handleGetEvidences}
        filters={filters}
      />
    </Dialog>
  );
};
