import { FC, useContext, useEffect, useState } from "react";
import { ILand } from "../../../types/ILand";
import { AddLandDialog } from "./Add/AddLandDialog";
import { Dialog } from "../../../Elements/Dialog";
import { useTranslation } from "react-i18next";
import { Select } from "../../Inputs/Select";
import { ISelectData } from "@inceptionbg/ui-components";
import { SelectAsyncPaginate } from "../../Inputs/SelectAsyncPaginate";
import { getMembersOptions } from "../../../utils/LoadOptions";
import { FarmContext } from "../../../AppContent";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  land: ILand;
  reloadLands: (callback?: () => void) => void;
}

export const ReplaceLandDialog: FC<Props> = ({
  isOpen,
  onClose,
  land,
  reloadLands,
}) => {
  const [isEditLandOpen, setIsEditLandOpen] = useState(false);
  const [typeObj, setTypeObj] = useState<{
    value: "holderLand" | "memberLand";
    label: string;
  } | null>(null);
  const [memberObj, setMemberObj] = useState<ISelectData | null>(null);

  const { t } = useTranslation();
  const farmUuid = useContext(FarmContext).farm?.uuid;

  useEffect(() => {
    if (isOpen) {
      if (land.member) {
        setTypeObj({ value: "memberLand", label: t("MemberLand") });
        setMemberObj({
          value: land.member.uuid,
          label: `${land.member.firstName} ${land.member.lastName}`,
        });
      } else {
        setTypeObj({ value: "holderLand", label: t("HolderLand") });
      }
    }
  }, [land, t, isOpen]);

  const handleClose = () => {
    setIsEditLandOpen(false);
    setTypeObj(null);
    onClose();
  };

  return (
    <>
      <Dialog
        isOpen={isOpen}
        onClose={handleClose}
        title={t("ReplaceLandTitle")}
        desc={t("ReplaceLandDesc", {
          name: `${land.cadastreNumber} - ${
            land.nickname || land.cadastreMunicipality.name
          }`,
        })}
        confirmButton={{
          onClick: () => setIsEditLandOpen(true),
          disabled: !typeObj || (typeObj.value === "memberLand" && !memberObj),
          keepOpen: true,
        }}
      >
        <Select
          value={typeObj}
          options={[
            { value: "holderLand", label: t("HolderLand") },
            { value: "memberLand", label: t("MemberLand") },
          ]}
          onChange={setTypeObj}
        />
        {typeObj?.value === "memberLand" && (
          <div>
            <p className="desc">{t("SelectMember")}</p>
            <SelectAsyncPaginate
              required
              value={memberObj || null}
              loadOptions={(
                search: string,
                loadedOptions: [],
                offset: { page: number }
              ) => getMembersOptions(search, loadedOptions, offset, farmUuid!)}
              onChange={(e) => setMemberObj(e || null)}
            />
          </div>
        )}
      </Dialog>
      <AddLandDialog
        isOpen={isEditLandOpen}
        onClose={() => setIsEditLandOpen(false)}
        reloadLands={(callback?: () => void) => {
          reloadLands(callback);
        }}
        land={land}
        memberLand={
          typeObj?.value === "memberLand" && memberObj
            ? {
                memberUuid: memberObj.value,
                memberName: memberObj.label,
              }
            : undefined
        }
      />
    </>
  );
};
