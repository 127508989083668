import { deleteProps } from "@inceptionbg/ui-components";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IInputComponentProps } from "../../../Elements/Input";
import {
  addOrEditLandCultures,
  deleteLandCulture,
} from "../../../repos/LandRepo";
import { ICultureNew, productionType } from "../../../types/ILand";
import {
  calculateSurfaceFromObj,
  convertSurfaceToObj,
} from "../../../utils/CalculateUtils";
import { getLandCultureOptions } from "../../../utils/LoadOptions";
import { Select } from "../../Inputs/Select";
import { SelectAsyncPaginate } from "../../Inputs/SelectAsyncPaginate";
import { SurfaceInput } from "../../Inputs/Surface/SurfaceInput";
import { SurfaceValue } from "../../Inputs/Surface/SurfaceValue";
import { Form } from "../../Wrapper/FormWrapper";
import { Dialog } from "../../../Elements/Dialog";
import { NumberInput } from "../../Inputs/NumberInput";

interface Props {
  landUuid: string;
  culture?: ICultureNew;
  newForm?: {
    onCancel: () => void;
  };
  reloadLandCultures?: (callback?: () => void) => void;
  reloadLandsSum?: () => void;
}

export const LandCultureForm: FC<Props> = ({
  landUuid,
  culture = {},
  newForm,
  reloadLandCultures,
  reloadLandsSum,
}) => {
  const [formData, setFormData] = useState<ICultureNew>({});
  const [edit, setEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [landCultureToDeleteUuid, setLandCultureToDeleteUuid] = useState("");

  const { t } = useTranslation();

  const surfaceObj = convertSurfaceToObj(culture.surface);
  const hailProtectionSurfaceObj = convertSurfaceToObj(
    culture.hailProtectionSurface
  );

  useEffect(() => {
    if (!!Object.keys(culture).length) {
      setFormData({ ...culture, surfaceObj, hailProtectionSurfaceObj });
    }
    // eslint-disable-next-line
  }, [culture]);

  const cultureInfo: IInputComponentProps[] = [
    {
      label: t("Culture"),
      value: culture.plantCulture?.name,
      input: (
        <SelectAsyncPaginate
          required
          value={
            formData.plantCulture
              ? {
                  label: formData.plantCulture.name,
                  value: formData.plantCulture.uuid,
                }
              : null
          }
          loadOptions={getLandCultureOptions}
          onChange={(e) =>
            setFormData(
              e
                ? {
                    ...formData,
                    plantCulture: {
                      name: e.label,
                      uuid: e.value,
                      requireNumberOfSeedlings: e.requireNumberOfSeedlings,
                    },
                  }
                : deleteProps(formData, ["plantCulture"])
            )
          }
          error={!newForm && !formData.plantCulture}
        />
      ),
      alert: {
        text: t("RequiredFieldError"),
        type: "error",
        active: !newForm && !formData.plantCulture,
      },
    },
    {
      label: t("CultureSurface"),
      valueElement: <SurfaceValue valueObj={surfaceObj} />,
      input: (
        <SurfaceInput
          formData={formData}
          setFormData={setFormData}
          value="surfaceObj"
          required
          error={
            !newForm &&
            (!formData.surfaceObj ||
              (!!formData.surfaceObj &&
                !calculateSurfaceFromObj(formData.surfaceObj)))
          }
        />
      ),
      alert: {
        text: t("RequiredFieldError"),
        type: "error",
        active:
          !newForm &&
          (!formData.surfaceObj ||
            (!!formData.surfaceObj &&
              !calculateSurfaceFromObj(formData.surfaceObj))),
      },
    },
    {
      label: t("NumberOfSeedlings"),
      value: `${culture.numberOfSeedlings || "/"}`,
      input: (
        <NumberInput
          value={formData.numberOfSeedlings}
          setValue={(e) => setFormData({ ...formData, numberOfSeedlings: e })}
          required={formData.plantCulture?.requireNumberOfSeedlings}
        />
      ),
      alert: {
        text: t("RequiredFieldError"),
        type: "error",
        active:
          !newForm &&
          !!formData.plantCulture?.requireNumberOfSeedlings &&
          !formData.plantCulture,
      },
    },
    {
      label: t("ProductionType"),
      value: culture.productionType ? t(culture.productionType) : "/",
      input: (
        <Select
          required
          value={
            formData.productionType
              ? {
                  label: t(formData.productionType),
                  value: formData.productionType,
                }
              : null
          }
          options={productionType.map((e) => ({ label: t(e), value: e }))}
          onChange={(e) =>
            setFormData({ ...formData, productionType: e.value })
          }
          error={!newForm && !formData.productionType}
        />
      ),
      alert: {
        text: t("RequiredFieldError"),
        type: "error",
        active: !newForm && !formData.productionType,
      },
    },
    {
      label: t("Irrigation"),
      value: t(culture.irrigation ? "Yes" : "No"),
      input: (
        <Select
          value={{
            label: t(formData.irrigation ? "Yes" : "No"),
            value: formData.irrigation,
          }}
          options={[true, false].map((e) => ({
            label: t(e ? "Yes" : "No"),
            value: e,
          }))}
          onChange={(e) => setFormData({ ...formData, irrigation: e.value })}
        />
      ),
    },
    {
      label: t("HailProtectionSurface"),
      valueElement: <SurfaceValue valueObj={hailProtectionSurfaceObj} />,
      input: (
        <SurfaceInput
          formData={formData}
          setFormData={setFormData}
          value="hailProtectionSurfaceObj"
        />
      ),
    },
  ];

  const onSubmit = () => {
    setIsLoading(true);
    const data: ICultureNew = deleteProps(
      {
        ...formData,
        surface: formData.surfaceObj
          ? calculateSurfaceFromObj(formData.surfaceObj)
          : formData.surface,
        hailProtectionSurface: formData.hailProtectionSurfaceObj
          ? calculateSurfaceFromObj(formData.hailProtectionSurfaceObj)
          : formData.hailProtectionSurface,
      },
      ["surfaceObj", "hailProtectionSurfaceObj"]
    );
    addOrEditLandCultures(landUuid, data)
      .then(() => {
        !!reloadLandsSum && reloadLandsSum();
        if (!!reloadLandCultures) {
          reloadLandCultures(() => {
            setIsLoading(false);
            setEdit(false);
            newForm?.onCancel();
          });
        } else {
          setIsLoading(false);
          setEdit(false);
        }
      })
      .catch(() => setIsLoading(false));
  };

  const onDelete = () => {
    if (landCultureToDeleteUuid) {
      setIsLoading(true);
      deleteLandCulture(landCultureToDeleteUuid)
        .then(() => {
          !!reloadLandsSum && reloadLandsSum();
          if (!!reloadLandCultures) {
            reloadLandCultures(() => {
              setIsLoading(false);
              setEdit(false);
              newForm && newForm.onCancel();
            });
          } else {
            setIsLoading(false);
            setEdit(false);
          }
        })
        .catch(() => setIsLoading(false));
    }
  };

  return (
    <>
      <Form
        data={cultureInfo}
        edit={!!newForm || edit}
        setEdit={setEdit}
        submitButton={{
          editLabel: t(!!newForm || edit ? "Confirm" : "LandCultureEdit"),
          onSubmit,
        }}
        otherButtons={[
          {
            label: t("DeleteLandCulture"),
            onClick: () => setLandCultureToDeleteUuid(formData.uuid || ""),
            hidden: !edit,
            outlined: true,
            className: "error",
          },
        ]}
        buttonSize="xs"
        onCancel={!!newForm ? newForm.onCancel : undefined}
        onResetForm={() =>
          setFormData({ ...culture, surfaceObj, hailProtectionSurfaceObj })
        }
        isLoading={isLoading}
      />
      <Dialog
        isOpen={!!landCultureToDeleteUuid}
        onClose={() => setLandCultureToDeleteUuid("")}
        title={t("DeleteLandCulture")}
        desc={t("DeleteLandCultureDesc")}
        isLoading={isLoading}
        confirmButton={{ label: t("Confirm"), onClick: onDelete }}
        cancelButton={{ onClick: () => setLandCultureToDeleteUuid("") }}
        error
      />
    </>
  );
};
