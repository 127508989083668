import { Dispatch, FC, SetStateAction, useState } from "react";
import { Loader } from "../../../../../Elements/Loader";
import { uploadFile } from "../../../../../repos/FileRepo";
import { IEvidence } from "../../../../../types/IFile";
import { FilesDropzone } from "../../../../Files/Dropzone/FilesDropzone";
import { EvidencesList } from "../../../../Files/EvidencesList";

interface Props {
  statements: IEvidence[];
  setStatements: Dispatch<SetStateAction<IEvidence[]>>;
}
export const StatementEvidenceBulk: FC<Props> = ({
  statements,
  setStatements,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const onUpload = (data: File[]) => {
    setIsLoading(true);
    data.forEach((file, i) => {
      uploadFile(file)
        .then((e) => {
          setStatements((data) => [...data, { storedFile: e }]);
        })
        .catch(() => setIsLoading(false))
        .finally(() => {
          if (i === data.length - 1) {
            setIsLoading(false);
          }
        });
    });
  };

  const onDeleteFile = (index: number, callback: () => void) => {
    setStatements((e: any) => {
      let newList = [...e];
      newList.splice(index, 1);
      return newList;
    });
    callback();
  };

  return (
    <Loader isLoading={isLoading}>
      <div className="flex column gap-5">
        {!!statements.length && (
          <EvidencesList evidences={statements} onDeleteFile={onDeleteFile} />
        )}
        <FilesDropzone onSubmit={onUpload} setEvidences={setStatements} />
      </div>
    </Loader>
  );
};
