import { TextInput } from "@inceptionbg/ui-components";
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { FarmContext } from "../../../AppContent";
import { IInputComponentProps } from "../../../Elements/Input";
import { inputPattern } from "../../../utils/InputPatternValidation";
import { Form } from "../../Wrapper/FormWrapper";
import { IAuthorizedPersonNew } from "../../../types/IAuthorizedPerson";
import { addAuthorizedPerson, deleteAuthorizedPerson } from "../../../repos/AuthorizedPersonsRepo";
import { Dialog } from "../../../Elements/Dialog";

interface Props {
  authorizedPerson?: IAuthorizedPersonNew;
  newForm?: {
    onCancel?: () => void;
  };
  reloadAuthorizedPersons?: (callback?: () => void) => void;
}

export const AuthorizedPersonForm: FC<Props> = ({
                                                  authorizedPerson,
                                                  newForm,
                                                  reloadAuthorizedPersons,
                                                }) => {
  const [formData, setFormData] = useState<IAuthorizedPersonNew>({});
  const [isLoading, setIsLoading] = useState(false);
  const [authorizedPersonToDeleteUuid, setAuthorizedPersonToDeleteUuid] = useState("");
  const [displayPersonalIdentityNumber, setDisplayPersonalIdentityNumber] = useState(false);

  const { t } = useTranslation();
  const { farm } = useContext(FarmContext);

  const resetFormData = useCallback(() => {
    authorizedPerson && setFormData(authorizedPerson);
  }, [authorizedPerson]);

  useEffect(resetFormData, [resetFormData]);

  const authorizedPersonInfo: IInputComponentProps[] = [
    {
      label: t("FirstName"),
      value: formData.firstName,
      input: (
        <TextInput
          required
          value={formData.firstName}
          setValue={(e) => setFormData({ ...formData, firstName: e })}
        />
      ),
    },
    {
      label: t("LastName"),
      value: formData.lastName,
      input: (
        <TextInput
          required
          value={formData.lastName}
          setValue={(e) => setFormData({ ...formData, lastName: e })}
        />
      ),
    },
    {
      label: t("PersonalIdentityNumber"),
      value: displayPersonalIdentityNumber ? formData.personalIdentityNumber : "",
      valueElement: !displayPersonalIdentityNumber ?
        <p className="link"
           onClick={() => !!setDisplayPersonalIdentityNumber && setDisplayPersonalIdentityNumber(true)}>
          {t("DisplayPersonalIdentityNumber")}
        </p> : undefined,
      input: (
        <TextInput
          required
          value={formData.personalIdentityNumber}
          setValue={(e) =>
            setFormData({ ...formData, personalIdentityNumber: e })
          }
          inputProps={{ pattern: inputPattern.personalIdentityNumber }}
        />
      ),
    },
    {
      label: t("EmailAddress"),
      value: formData.email,
      input: (
        <TextInput
          required
          value={formData.email}
          setValue={(e) =>
            setFormData({ ...formData, email: e })
          }
        />
      ),
    },
    {
      label: t("PhoneNumber"),
      value: formData.phone,
      input: (
        <div className="flex align-center mb-3">
          <p className="mr-2">+381</p>
          <TextInput
            required
            placeholder="64123456"
            value={formData.phone}
            setValue={(e) => setFormData({ ...formData, phone: e })}
            inputProps={{ pattern: inputPattern.phone }}
          />
        </div>
      ),
    },
  ];

  const onDelete = () => {
    if (authorizedPersonToDeleteUuid) {
      setIsLoading(true);
      deleteAuthorizedPerson(authorizedPersonToDeleteUuid)
        .then(() => {
          if (!!reloadAuthorizedPersons) {
            reloadAuthorizedPersons(() => {
              setIsLoading(false);
              newForm?.onCancel && newForm.onCancel();
            });
          } else {
            setIsLoading(false);
          }
        })
        .catch(() => setIsLoading(false));
    }
  };

  const onSubmit = () => {
    if (farm?.uuid) {
      setIsLoading(true);
      addAuthorizedPerson(farm.uuid, { ...formData, phone: `+381${formData.phone}` })
        .then(() => {
          if (!!reloadAuthorizedPersons) {
            reloadAuthorizedPersons(() => {
              setIsLoading(false);
              newForm?.onCancel && newForm.onCancel();
            });
          } else {
            setIsLoading(false);
          }
        })
        .catch(() => setIsLoading(false));
    }
  };

  return (
    <>
      <Form
        data={authorizedPersonInfo}
        edit={!!newForm}
        setEdit={() => {
          setAuthorizedPersonToDeleteUuid(formData.uuid!);
        }}
        submitButton={!newForm ? {
          editLabel: t("RemoveAuthorizedPerson"),
          onSubmit: () => {
          },
        } : {
          editLabel: t("Save"),
          onSubmit,
        }}
        onCancel={!!newForm ? newForm.onCancel : undefined}
        onResetForm={resetFormData}
        isLoading={isLoading}
      />
      <Dialog
        isOpen={!!authorizedPersonToDeleteUuid}
        onClose={() => setAuthorizedPersonToDeleteUuid("")}
        title={t("RemoveAuthorizedPerson")}
        desc={t("RemoveAuthorizedPersonDesc")}
        isLoading={isLoading}
        confirmButton={{ label: t("Confirm"), onClick: onDelete }}
        cancelButton={{ onClick: () => setAuthorizedPersonToDeleteUuid("") }}
      />
    </>
  );
};
