import { ENV } from "../App";
import { IFarm } from "../types/IFarm";
import { IUserInfo } from "../types/IUser";

export const pageNoAccess = (farm: IFarm | null, userInfo: IUserInfo) => ({
  edit:
    !userInfo.hasAtLeastOneFarm ||
    (!!farm &&
      ["PENDING", "PENDING_APPROVAL"].includes(farm.status) &&
      farm.registrationStatus !== "WAITING_COMPLEMENT" &&
      !!farm.sendToHermes),
  renew: !farm?.uuid || !userInfo.hasAtLeastOneFarm,
  new:
    !userInfo.canRegisterNewFarm ||
    (!!farm &&
      ["PENDING", "PENDING_APPROVAL"].includes(farm.status) &&
      farm.registrationStatus !== "WAITING_COMPLEMENT" &&
      !farm.sendToHermes),
  statements:
    !farm?.uuid || !["PASSIVE", "ACTIVE"].includes(farm?.status || ""),
  statusChange: !farm?.uuid || ENV === "PROD",
  delete: !farm?.uuid,
  changeHolderPP: !farm?.uuid || farm?.holder.type !== "physicalPerson",
  setTempHolder: !userInfo.canActAsHolder,
});
