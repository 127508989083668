import {
  ITableColumn,
  ITableDataItem,
  Table,
} from "@inceptionbg/ui-components";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ILand, ILandPart } from "../../../../../types/ILand";
import { getLandParts } from "../../../../../repos/LandRepo";

interface Props {
  land: ILand;
}

export const PartLandTable: FC<Props> = ({ land }) => {
  const [landParts, setLandParts] = useState<ILandPart[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const handleGetLandPart = useCallback(() => {
    if (land) {
      setIsLoading(true);
      getLandParts(land.uuid)
        .then((data) => setLandParts(data))
        .catch(() => {})
        .finally(() => setIsLoading(false));
    }
  }, [land]);

  useEffect(handleGetLandPart, [handleGetLandPart]);

  const columns: ITableColumn[] = [
    { id: "partNumber", label: t("PartNumber") },
    { id: "partSurface", label: t("PartSurface") },
    { id: "cadastreCulture", label: t("CadastreCulture") },
  ];

  const data: ITableDataItem[] = landParts.map((landPart) => ({
    uuid: landPart.partNumber!,
    cells: {
      partNumber: {
        value: landPart.partNumber,
      },
      partSurface: {
        value: landPart.partSurface,
      },
      cadastreCulture: {
        value: landPart.cadastreCulture?.name,
      },
    },
  }));

  return (
    <Table
      headerWrap
      isLoading={isLoading}
      data={data}
      columns={columns}
      hideFooter
      customPagination={{ defaultLimit: 1000 }}
      className="table-5col"
    />
  );
};
