import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { FarmContext } from "../../../../AppContent";
import { Dialog } from "../../../../Elements/Dialog";
import { addMember } from "../../../../repos/MembersRepo";
import { IDialogTypeItem } from "../../../../types/IBase";
import { IEvidence } from "../../../../types/IFile";
import { IMemberNew } from "../../../../types/IMember";
import { EvidencesComponent } from "../../../Files/EvidencesComponent";
import { AddStatementTypeList } from "../Statement/Components/AddStatementTypeList";
import { AddMemberForm } from "./AddMemberForm";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  member?: IMemberNew;
  reloadMembers?: (callback?: () => void) => void;
}

type IStepType = "form" | "type" | "eid" | "attachments";

export const AddEditMemberDialog: FC<Props> = ({
  isOpen,
  onClose,
  member,
  reloadMembers,
}) => {
  const [step, setStep] = useState<IStepType>("form");
  const [formData, setFormData] = useState<IMemberNew>(member || {});
  const [evidences, setEvidences] = useState<IEvidence[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const { reloadFarm } = useContext(FarmContext);
  const farm = useContext(FarmContext).farm;
  const farmUuid = farm?.uuid;

  const category = "MEMBER_STATEMENT";

  const typeData: {
    [key in IStepType]: IDialogTypeItem;
  } = {
    form: {
      title: t(member ? "MemberEdit" : "NewMember"),
      desc: t(member ? "MemberEditDesc" : "NewMemberDesc"),
      element: <AddMemberForm formData={formData} setFormData={setFormData} />,
      confirmBtn: {
        label: t("AddMemberStatement"),
        onFormSubmit: () => setStep("type"),
        keepOpen: true,
      },
      cancelBtn: {
        onClick: () => {
          setFormData({});
          onClose();
        },
      },
    },
    type: {
      title: t(`AddStatementTitle${category}`),
      desc: t(`AddStatementDesc${category}`),
      element: (
        <AddStatementTypeList setStep={(e) => setStep(e as IStepType)} />
      ),
      cancelBtn: { onClick: () => setStep("form") },
    },
    eid: {
      title: t("StatementEIDTitle"),
      // descEl: <>{t(`StatementEIDDesc${signStatus || ""}`)}</>,
      element: (
        <></>
        // <StatementEID
        //   memberPIN={memberPIN}
        //   evidenceUuid={evidenceUuid}
        //   signStatus={signStatus}
        //   setSignStatus={setSignStatus}
        // />
      ),
      // confirmBtn:
      // !evidenceUuid
      //   ? {
      //       label: t("SendEIDStatement"),
      //       onFormSubmit: () => {
      //         setIsLoading(true);
      //         createEvidenceFromEID(farmUuid, memberPIN, category)
      //           .then((e) => setEvidenceUuid(e))
      //           .catch(() => {})
      //           .finally(() => setIsLoading(false));
      //       },
      //       keepOpen: true,
      //     }
      //   : signStatus === "SIGNED"
      //   ? { okButton: true }
      //   : undefined,
      // cancelBtn:
      //   signStatus !== "SIGNED"
      //     ? { onClick: () => setStep("TYPE") }
      //     : undefined,
    },
    attachments: {
      title: t("StatementEvidenceTitle"),
      descEl: (
        <>
          <p>{t("StatementEvidenceDesc")}</p>
          <ul>
            <li>{t("PaperStatementDesc")}</li>
            <a
              href="https://erpg.eagrar.gov.rs/upload/prilog-2-izjava-clan.pdf"
              target="_blank"
              rel="noopener noreferrer"
              download
              className="link"
            >
              {t("PrintStatementForm")}
            </a>
            <li>{t("PhotoStatementDesc")}</li>
          </ul>
        </>
      ),
      element: (
        <EvidencesComponent
          category={category}
          evidences={evidences}
          setEvidences={setEvidences}
        />
      ),
      confirmBtn: {
        label: t("MemberAdd"),
        disabled: !evidences.length,
        onClick: () => onSubmit(),
        keepOpen: true,
      },
      cancelBtn: { onClick: () => setStep("type") },
    },
  };

  const handleClose = () => {
    setStep("form");
    setFormData({});
    setEvidences([]);
    onClose();
  };

  const onSubmit = () => {
    if (farmUuid) {
      setIsLoading(true);
      let data: IMemberNew = { ...formData, evidences };

      if (farm?.registrationApproval?.approvalStatus === "WAITING_COMPLEMENT")
        data.approval = { uuid: farm?.registrationApproval?.uuid ?? "" };

      addMember(farmUuid, data)
        .then((e) => {
          reloadMembers &&
            reloadMembers(() => {
              setIsLoading(false);
              reloadFarm();
              handleClose();
            });
        })
        .catch(() => setIsLoading(false));
    }
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      title={typeData[step].title}
      desc={typeData[step].desc}
      descEl={typeData[step].descEl}
      confirmButton={typeData[step].confirmBtn}
      cancelButton={typeData[step].cancelBtn}
      size="m"
      isLoading={isLoading}
    >
      {typeData[step].element}
    </Dialog>
  );
};
