import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  deleteProps,
  LargeTextInput,
  TextInput,
} from "@inceptionbg/ui-components";
import { IRequestNew } from "../../../../types/IRequest";
import { IInputComponentProps, Input } from "../../../../Elements/Input";
import { SubSection } from "../../../SubSection";
import { Loader } from "../../../../Elements/Loader";
import { SelectAsyncPaginate } from "../../../Inputs/SelectAsyncPaginate";
import { getCaseOptions } from "../../../../utils/LoadOptions";
import { FarmContext } from "../../../../AppContent";
import { CaseStatusFetchOption } from "../../../../types/ICase";
import { IPageMode } from "../../../../types/IBase";
import { Select } from "../../../Inputs/Select";

interface Props {
  title?: string;
  mode: IPageMode;
  data: IRequestNew;
  setData: (data: IRequestNew) => void;
  isLoading?: boolean;
}

export const BasicRequest: FC<Props> = ({
  title,
  mode,
  data,
  setData,
  isLoading,
}) => {
  const { t } = useTranslation();
  const { farm } = useContext(FarmContext);

  const basicData: IInputComponentProps[] = [
    {
      label: t("Title"),
      value: title || data.title,
      input: (
        <TextInput
          required
          placeholder={t("FreeTextPlaceholder")}
          value={title || data.title}
          setValue={(e) => setData({ ...data, title: e })}
        />
      ),
      hidden: mode === "CASE_UPDATE" || mode === "COMPLAINT",
      edit: !!title,
    },
    {
      label: t("RequestDescription"),
      input: (
        <LargeTextInput
          required
          placeholder={t("FreeTextPlaceholder")}
          label={""}
          rows={7}
          value={data.purpose}
          setValue={(e) => setData({ ...data, purpose: e })}
        />
      ),
      hidden: mode === "CASE_UPDATE" || mode === "COMPLAINT",
    },
    {
      label: t("CaseNumber"),
      input: (
        <SelectAsyncPaginate
          required
          placeholder={t("ChoosePlaceholder")}
          value={
            data.case
              ? {
                  label: data.case.caseNumber,
                  value: data.case.caseId,
                }
              : null
          }
          loadOptions={(
            search: string,
            loadedOptions: [],
            offset: { page: number }
          ) =>
            getCaseOptions(
              search,
              loadedOptions,
              offset,
              farm?.registrationNumber ? farm.uuid : undefined,
              [
                "SUPER_VISION",
                "APPEAL_FIRST_DEGREE",
                "APPEAL_SECOND_DEGREE",
                "SENT_FOR_REPLENISHMENT",
                "ACCEPTED",
                "RECEIVED",
                "IN_PROGRESS",
                ...((mode === "COMPLAINT"
                  ? ["SUSPENSION", "TURN_DOWN", "REJECTED", "GIVING_UP"]
                  : ["PAID", "SENT_FOR_PAYMENT"]) as CaseStatusFetchOption[]),
              ]
            )
          }
          onChange={(e) =>
            setData(
              e
                ? {
                    ...data,
                    case: { caseNumber: e.label, caseId: e.value },
                  }
                : deleteProps(data, ["case"])
            )
          }
        />
      ),
      hidden: !(mode === "CASE_UPDATE" || mode === "COMPLAINT"),
    },
    {
      label: t("ComplaintType"),
      input: (
        <Select
          required
          placeholder={t("ChoosePlaceholder")}
          value={
            data.requestType
              ? {
                  label: t(`RequestType${data.requestType}`),
                  value: data.requestType,
                }
              : null
          }
          options={["COMPLAINT", "COMPLAINT_WAIVER"].map((e) => ({
            label: t(`RequestType${e}`),
            value: e,
          }))}
          onChange={(e) => setData({ ...data, requestType: e.value })}
        />
      ),
      hidden: mode !== "COMPLAINT",
    },
    {
      label: t("Note"),
      input: (
        <TextInput
          required={mode === "COMPLAINT"}
          placeholder={t("FreeTextPlaceholder")}
          value={data.note}
          setValue={(e) => setData({ ...data, note: e })}
        />
      ),
      hidden: !(
        mode === "GENERAL_REQUEST" ||
        mode === "CASE_UPDATE" ||
        mode === "COMPLAINT"
      ),
    },
  ];

  return (
    <SubSection className="pt-0">
      <Loader isLoading={!!isLoading}>
        <div className="form-container">
          {basicData.map(
            (e) =>
              !e.hidden && <Input key={e.label} component={e} edit={!e.edit} />
          )}
        </div>
      </Loader>
    </SubSection>
  );
};
