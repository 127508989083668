import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog } from "../../Elements/Dialog";
import { List } from "../../Elements/List";
import { getFarmList } from "../../repos/FarmRepo";
import { IFarmBasic } from "../../types/IFarm";
import { useNavigate } from "react-router-dom";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  handleGetFarm: (farmUuid: string, callback?: () => void) => void;
}

export const ChooseFarmDialog: FC<Props> = ({
  isOpen,
  onClose,
  handleGetFarm,
}) => {
  const [farms, setFarms] = useState<IFarmBasic[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      getFarmList()
        .then(setFarms)
        .catch(() => {})
        .finally(() => setIsLoading(false));
    }
  }, [isOpen]);

  const onChange = (uuid: string) => {
    setIsLoading(true);
    navigate("/");
    handleGetFarm(uuid, onClose);
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title={t("ChooseFarm")}
      size="l"
      isLoading={isLoading}
      confirmButton={{
        label: t("ContinueWithoutFarm"),
        okButton: true,
        outlined: true,
      }}
    >
      <List
        listItems={farms.map((e) => ({
          id: e.uuid,
          title:
            e.registrationNumber &&
            !["PENDING", "PENDING_APPROVAL"].includes(e.status)
              ? `${t("OrgNumberShort")}  ${e.registrationNumber} - ${e.name}`
              : `${e.name} - ${t("FarmRegistrationInProgress")}`,
        }))}
        onClick={onChange}
      />
    </Dialog>
  );
};
