import { Button } from "@inceptionbg/ui-components";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FarmContext } from "../AppContent";
import { Section } from "../Components/Section";
import { AddInvoiceDialog } from "../Components/Sections/Invoice/Add/AddInvoiceDialog";
import { Invoice } from "../Components/Sections/Invoice/Invoice";
import { Alert } from "../Elements/Alert/Alert";
import { InfoBox } from "../Elements/Alert/InfoBox";
import { Dialog } from "../Elements/Dialog";
import { Loader } from "../Elements/Loader";
import { farmHasEDelivery } from "../repos/FarmRepo";
import { getInvoices } from "../repos/InvoiceRepo";
import { IInvoice } from "../types/IInvoice";

export const InvoicesPage: FC = () => {
  const [initialInfoOpen, setInitialInfoOpen] = useState(true);
  const [invoices, setInvoices] = useState<IInvoice[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [addInvoice, setAddInvoice] = useState(false);
  const [hasDelivery, setHasDelivery] = useState(true);

  const { t } = useTranslation();
  const farmUuid = useContext(FarmContext).farm?.uuid;

  const handleGetData = useCallback(
    (callback?: () => void) => {
      if (farmUuid) {
        setIsLoading(true);
        Promise.all([
          getInvoices(farmUuid, ["ITEMS", "APPROVAL", "GAS_STATION", "USED"]),
          farmHasEDelivery(farmUuid),
        ])
          .then(([data, hasDelivery]) => {
            setHasDelivery(!!hasDelivery);
            setInvoices(data.invoices);
            callback && callback();
          })
          .catch(() => {})
          .finally(() => setIsLoading(false));
      }
    },
    [farmUuid]
  );

  useEffect(handleGetData, [handleGetData]);

  return (
    <>
      <InfoBox
        title={t("Note")}
        descEl={
          <p>
            <Trans
              i18nKey="InvoicesPageInfoBox"
              components={{
                link1: <Link to="/refaction" className="link bold" />,
              }}
            />
          </p>
        }
        className="mb-4"
      />
      <Section id="invoices" title={t("FuelInvoices")}>
        {!hasDelivery && (
          <Alert
            text={t("ErrorDATABOX_NOT_FOUND")}
            type="info"
            className="mb-4"
          />
        )}
        {isLoading ? (
          <Loader isLoading>
            <div className="pt-5 full-width" />
          </Loader>
        ) : (
          <>
            <div className="form-container">
              {farmUuid &&
                !isLoading &&
                invoices.map((e) => (
                  <Invoice
                    key={e.uuid}
                    invoice={e}
                    reloadInvoices={handleGetData}
                  />
                ))}
            </div>
            <div className="flex justify-right mt-3">
              <Button
                label={t("InvoiceAdd")}
                primary
                disabled={!hasDelivery}
                onClick={() => setAddInvoice(true)}
              />
            </div>
            <AddInvoiceDialog
              isOpen={addInvoice}
              onClose={() => setAddInvoice(false)}
              reloadInvoices={handleGetData}
            />
          </>
        )}
        <Dialog
          desc={t("InvoicesPageInfo")}
          isOpen={initialInfoOpen}
          onClose={() => setInitialInfoOpen(false)}
          size="m"
        />
      </Section>
    </>
  );
};
