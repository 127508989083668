import { TextInput } from "@inceptionbg/ui-components";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { FarmContext } from "../../AppContent";
import { Dialog } from "../../Elements/Dialog";
import {
  resendVerificationEmail,
  resendVerificationSMS,
  verifyEmail,
  verifyPhoneNumber,
} from "../../repos/ContactRepo";
import { IContactType } from "../../types/IContactInfo";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  type: IContactType;
}

export const ContactConfirmationDialog: FC<Props> = ({
  isOpen,
  onClose,
  type,
}) => {
  const [code, setCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const farm = useContext(FarmContext).farm!;
  const reloadFarm = useContext(FarmContext).reloadFarm;

  const onFormSubmit = () => {
    if (type === "Email") {
      setIsLoading(true);
      verifyEmail(farm.uuid, {
        email: farm.contactInfo.email,
        emailVerificationCode: code,
      })
        .then(() => reloadFarm())
        .catch(() => {})
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(true);
      verifyPhoneNumber(farm.uuid, {
        phoneNumber: farm.contactInfo.phoneNumber,
        phoneNumberVerificationCode: code,
      })
        .then(() => reloadFarm())
        .catch(() => {})
        .finally(() => setIsLoading(false));
    }
  };
  const onResend = () => {
    if (type === "Email") {
      setIsLoading(true);
      resendVerificationEmail(farm.uuid)
        .catch(() => {})
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(true);
      resendVerificationSMS(farm.uuid)
        .catch(() => {})
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title={t(`${type}ConfirmTitle`)}
      desc={t(`${type}ConfirmDesc`)}
      confirmButton={{ onFormSubmit }}
      cancelButton={{}}
      isLoading={isLoading}
    >
      <TextInput required value={code} setValue={setCode} />
      <p className="link text-center" onClick={onResend}>
        {t("ResendActivationCode")}
      </p>
    </Dialog>
  );
};
