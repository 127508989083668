import { axiosInstance } from "../App";
import { IAnimal, IAnimalCountTotals, IAnimalNew } from "../types/IAnimal";

export const getAnimals = (farmUuid: string) =>
  axiosInstance
    .get(`/farm/${farmUuid}/animals`, {
      params: { limit: 1000 },
    })
    .then(({ data }) => ({
      animals: data.animals as IAnimal[],
      totals: {
        animalCountTotal: data.animalCountTotal,
        animalCountOrganicTotal: data.animalCountOrganicTotal,
        animalCountConventionalConTotal: data.animalCountConventionalConTotal,
      } as IAnimalCountTotals,
    }));

export const editAnimal = (animalUuid: string, data: IAnimalNew) =>
  axiosInstance.patch(`/animal/${animalUuid}`, data);

export const addCustomAnimal = (farmUuid: string, data: IAnimalNew) =>
  axiosInstance.post(`/farm/${farmUuid}/animal`, {
    group: "CUSTOM",
    ...data,
  });

export const deleteAnimal = (animalUuid: string) =>
  axiosInstance.delete(`/animal/${animalUuid}`);

////// HID //////
export const getHids = (farmUuid: string) =>
  axiosInstance
    .get(`/farm/${farmUuid}/animal/hids`)
    .then(({ data }) => (data.temporaryHIDS || []) as string[]);

export const addAnimalHid = (farmUuid: string, hid: string) =>
  axiosInstance.post(`/farm/${farmUuid}/animal/hid`, { hid });

export const deleteAnimalHid = (farmUuid: string, hid: string) =>
  axiosInstance.delete(`/farm/${farmUuid}/animal/hid/${hid}`);
