import { FC, ReactElement } from 'react';

interface Props {
  isLoading: boolean;
  children: ReactElement;
}
export const Loader: FC<Props> = ({ isLoading, children }) => (
  <div className="loader-container">
    <div className={isLoading ? 'loading-content' : undefined}>{children}</div>
    {isLoading && (
      <div className="loader">
        <span className="spinner" />
      </div>
    )}
  </div>
);
