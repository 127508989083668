import { ISimpleObject } from "@inceptionbg/ui-components";
import { IApproval, INameCode } from "./IBase";
import { IGender } from "./IMember";

export const holderTypes = ["physicalPerson", "legalEntity"] as const;
export type IHolderType = (typeof holderTypes)[number];

export const legalEntitySize = ["MICRO", "SMALL", "MEDIUM", "LARGE"] as const;
export type ILegalEntitySize = (typeof legalEntitySize)[number];

export const LEForms = ["LLC", "ENTREPRENEUR", "OTHER"] as const;
export type ILegalEntityForm = (typeof LEForms)[number];

export interface IHolderNew {
  uuid?: string;
  type?: IHolderType;
  //   Physical Person
  education?: ISimpleObject;
  personalIdentityNumber?: string;
  firstName?: string;
  lastName?: string;
  birthDate?: string;
  birthPlace?: string;
  birthCountry?: INameCode;
  gender?: IGender;
  workActivity?: ISimpleObject;
  //   Legal Entity
  legalEntityForm?: ILegalEntityForm;
  registrationNumber?: string;
  taxId?: string;
  jbkjs?: string;
  name?: string;
  numberOfEmployees?: string | number;
  size?: ILegalEntitySize;
  approved?: boolean;
  dedicatedRegisterNumber?: string;
  temporary?: boolean;
  approval?: Partial<IApproval>;
}
