import { FC } from "react";
import { useTranslation } from "react-i18next";
import { SubSection } from "../../SubSection";
import { IAuthorizedPersonNew } from "../../../types/IAuthorizedPerson";
import { AuthorizedPersonForm } from "./AuthorizedPersonForm";

interface Props {
  authorizedPerson?: IAuthorizedPersonNew;
  newForm?: {
    onCancel: () => void;
  };
  reloadAuthorizedPersons?: (callback?: () => void) => void;
}

export const AuthorizedPerson: FC<Props> = ({ authorizedPerson = {}, newForm, reloadAuthorizedPersons }) => {
  const { t } = useTranslation();

  return (
    <SubSection
      title={!!newForm ? t("NewAuthorizedPerson") : `${authorizedPerson.firstName} ${authorizedPerson.lastName}`}
      expandable={!newForm}
      initialyOpen={!!newForm}
    >
      <AuthorizedPersonForm authorizedPerson={authorizedPerson} newForm={newForm}
                            reloadAuthorizedPersons={reloadAuthorizedPersons} />
    </SubSection>
  );
};
