import { FontAwesomeIcon } from "@inceptionbg/ui-components";
import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { faBars } from "../../assets/icons/regular/faBars";
import { Dialog } from "../../Elements/Dialog";
import { INavItem } from "../../types/IBase";

interface Props {
  navItems: INavItem[];
}
export const MobileNav: FC<Props> = ({ navItems }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div
        onClick={() => setIsOpen(true)}
        className="mobile-only clickable ml-3"
      >
        <FontAwesomeIcon icon={faBars} className="icon-3 color-primary" />
      </div>
      <Dialog isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <div className="flex column mt-4">
          {navItems.map((e) => (
            <Link
              key={e.label}
              className="nav-item"
              to={e.url}
              target={e.target}
              onClick={() => setIsOpen(false)}
            >
              {e.label}
            </Link>
          ))}
        </div>
      </Dialog>
    </>
  );
};
