import { ISimpleObject } from "@inceptionbg/ui-components";
import { IApproval, INameCode } from "./IBase";
import { IEvidence } from "./IFile";

export const genderTypes = ["M", "F"] as const;
export type IGender = (typeof genderTypes)[number];

export interface IMemberNew {
  uuid?: string;
  personalIdentityNumber?: string;
  firstName?: string;
  lastName?: string;
  birthDate?: string;
  birthPlace?: string;
  birthCountry?: INameCode;
  gender?: IGender;
  workActivity?: ISimpleObject;
  approved?: boolean;
  evidences?: IEvidence[];
  approval?: Partial<IApproval>;
}

export type IStatementType = "attachments" | "eid";
