import { axiosInstance } from "../App";
import {
  IContactInfoNew,
  IEmailVerification,
  IPhoneVerification,
} from "../types/IContactInfo";

export const updateContactInfo = (farmUuid: string, data: IContactInfoNew) =>
  axiosInstance.put(`/farm/${farmUuid}/contact-info`, data);

export const verifyEmail = (farmUuid: string, data: IEmailVerification) =>
  axiosInstance.put(`/farm/${farmUuid}/email-verify`, data);

export const verifyPhoneNumber = (
  farmUuid: string,
  data: IPhoneVerification
) =>
  axiosInstance.put(
    `/farm/${farmUuid}/phone-number-verify`,
    data
  );

export const resendVerificationEmail = (farmUuid: string) =>
  axiosInstance.put(
    `/farm/${farmUuid}/email-verification-code-resend`
  );

export const resendVerificationSMS = (farmUuid: string) =>
  axiosInstance.put(
    `/farm/${farmUuid}/phone-number-verification-code-resend`
  );
