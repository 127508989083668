import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog } from "../../../../Elements/Dialog";
import { SubSection } from "../../../SubSection";
import { Input } from "../../../../Elements/Input";
import { IRefactionRequest } from "../../../../types/IRefaction";
import {
  getRefractionRequestByUuid,
  updateRefractionRequest,
} from "../../../../repos/RefactionRepo";
import { Alert } from "../../../../Elements/Alert/Alert";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  refactionUuid?: string;
  reloadData: (caseNumber: string) => void;
}

export const SummaryRefactionDialog: FC<Props> = ({
  isOpen,
  onClose,
  refactionUuid,
  reloadData,
}) => {
  const [formData, setFormData] = useState<IRefactionRequest>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setIsLoading(true);
    getRefractionRequestByUuid(refactionUuid!)
      .then((data) => {
        setFormData(data.refractionRequest);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, [refactionUuid]);

  const onSubmit = () => {
    if (refactionUuid) {
      setIsLoading(true);
      updateRefractionRequest(refactionUuid, "SUBMITTED")
        .then((res) => reloadData(res.caseNumber))
        .catch(() => {})
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <>
      <Dialog
        isOpen={isOpen}
        onClose={onClose}
        title={t("UsedFuelPreview")}
        confirmButton={{
          onClick: () => setIsConfirmDialogOpen(true),
          keepOpen: true,
          label: t("Confirm"),
        }}
        cancelButton={{
          onClick: () => onClose(),
        }}
        size="m"
        isLoading={isLoading}
      >
        <SubSection isLoading={isLoading} initialyOpen>
          <div className="form-container">
            <Input
              component={{
                label: t("InvoiceLiterSum"),
                value: `${formData.invoiceLiterSum || "0"} l`,
              }}
            />
            <Input
              component={{
                label: t("LiterSum"),
                value: `${formData.literSum || "0"} l`,
              }}
            />
            <Alert text={t("SummaryPreview")} type={"info"} />
          </div>
        </SubSection>
      </Dialog>
      <Dialog
        isOpen={isConfirmDialogOpen}
        onClose={() => setIsConfirmDialogOpen(false)}
        title={t("SubmitRefactionRequest")}
        desc={t("SubmitRefactionRequestDesc")}
        isLoading={isLoading}
        confirmButton={{ label: t("Yes"), onClick: onSubmit }}
        cancelButton={{
          label: t("No"),
          onClick: () => setIsConfirmDialogOpen(false),
        }}
      />
    </>
  );
};
