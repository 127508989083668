import { DateInput, deleteProps, TextInput } from "@inceptionbg/ui-components";
import { Dispatch, FC, SetStateAction, useContext } from "react";
import { useTranslation } from "react-i18next";
import { FarmContext } from "../../../../../AppContent";
import { Alert } from "../../../../../Elements/Alert/Alert";
import { IInputComponentProps, Input } from "../../../../../Elements/Input";
import { getLandSurfaceInfo } from "../../../../../repos/LandRepo";
import { ILandNew, ILandUsageBasis } from "../../../../../types/ILand";
import { convertSurfaceToObj } from "../../../../../utils/CalculateUtils";
import { inputPattern } from "../../../../../utils/InputPatternValidation";
import {
  getCadastreLandsOptions,
  getCadastreMunicipalityOptions,
  getLeaseTypeOptions,
} from "../../../../../utils/LoadOptions";
import { SelectAsyncPaginate } from "../../../../Inputs/SelectAsyncPaginate";
import { SurfaceInput } from "../../../../Inputs/Surface/SurfaceInput";
import { SurfaceValue } from "../../../../Inputs/Surface/SurfaceValue";

interface Props {
  type: ILandUsageBasis;
  formData: ILandNew;
  setFormData: Dispatch<SetStateAction<ILandNew>>;
  disableSurfaceChange: boolean;
  setDisableSurfaceChange: Dispatch<SetStateAction<boolean>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  memberUuid?: string;
}

export const AddLandForm: FC<Props> = ({
  type,
  formData,
  setFormData,
  disableSurfaceChange,
  setDisableSurfaceChange,
  setIsLoading,
  memberUuid,
}) => {
  const { t } = useTranslation();
  const { farm } = useContext(FarmContext);

  const handleGetLandInfo =
    type === "OWNERSHIP_OUT_CADASTRE" && formData.cadastreNumber
      ? () => {
          setIsLoading(true);
          getLandSurfaceInfo(farm!.uuid, {
            cadastreNumber: formData.cadastreNumber,
            cadastreMunicipalityUuid: formData.cadastreMunicipality?.uuid,
            memberUuid,
          })
            .then((e) => {
              if (e.totalSurface) {
                setDisableSurfaceChange(true);
                setFormData({
                  ...formData,
                  surface: e.totalSurface,
                  usedSurface: e.ownedSurface,
                  usedSurfaceObj: convertSurfaceToObj(e.ownedSurface),
                });
              }
            })
            .catch(() => {})
            .finally(() => setIsLoading(false));
        }
      : undefined;

  const landInfo: IInputComponentProps[] = [
    {
      label: t("LandName"),
      input: (
        <TextInput
          value={formData.nickname}
          setValue={(e) => setFormData({ ...formData, nickname: e })}
          inputProps={{ pattern: inputPattern.maxChar255 }}
        />
      ),
    },
    {
      label: t(
        type === "LEASE"
          ? "LessorPersonalIdentityNumber"
          : type === "LEASE_LEGAL_ENTITY"
          ? "LessorRegistrationNumber"
          : "LessorIdentificationNumber"
      ),
      input: (
        <TextInput
          required
          value={formData.lessorIdentification}
          setValue={(e) =>
            setFormData({ ...formData, lessorIdentification: e })
          }
          inputProps={{
            pattern:
              type === "LEASE"
                ? inputPattern.personalIdentityNumber
                : type === "LEASE_LEGAL_ENTITY"
                ? inputPattern.registrationNumber
                : inputPattern.registrationOrPersonalIdentityNumber,
          }}
        />
      ),
      hidden: !["LEASE", "LEASE_LEGAL_ENTITY", "LEASE_CONSOLIDATION"].includes(
        type
      ),
    },
    {
      label: t("CadastreMunicipality"),
      input: (
        <SelectAsyncPaginate
          required
          value={
            formData.cadastreMunicipality
              ? {
                  label: formData.cadastreMunicipality.name,
                  value: formData.cadastreMunicipality.uuid,
                }
              : null
          }
          loadOptions={getCadastreMunicipalityOptions}
          onChange={(e) => {
            setFormData(
              e
                ? deleteProps(
                    {
                      ...formData,
                      cadastreMunicipality: { name: e.label, uuid: e.value },
                    },
                    ["cadastreNumber", "cadastreNumberObj"]
                  )
                : deleteProps(formData, [
                    "cadastreMunicipality",
                    "cadastreNumber",
                    "cadastreNumberObj",
                  ])
            );
            type === "OWNERSHIP_OUT_CADASTRE" && setDisableSurfaceChange(false);
          }}
        />
      ),
    },
    {
      label: t("CadastreNumber"),
      input:
        type === "CADASTRE_OWNERSHIP" ? (
          <SelectAsyncPaginate
            required
            disabled={!formData.cadastreMunicipality}
            value={
              formData.cadastreNumber
                ? {
                    label: formData.cadastreNumber,
                    value: formData.cadastreNumber,
                  }
                : null
            }
            loadOptions={(
              search: string,
              loadedOptions: [],
              offset: { page: number }
            ) =>
              getCadastreLandsOptions(search, loadedOptions, offset, {
                cadastreMunicipalityUuid: formData.cadastreMunicipality!.uuid,
                farmUuid: farm!.uuid,
                memberUuid,
              })
            }
            onChange={(e) =>
              setFormData(
                e
                  ? {
                      ...formData,
                      cadastreNumber: e.value,
                      cadastreNumberObj: { name: e.label, uuid: e.value },
                      surface: e.surface,
                    }
                  : deleteProps(formData, [
                      "cadastreNumber",
                      "cadastreNumberObj",
                    ])
              )
            }
            refresh={[formData.cadastreMunicipality]}
          />
        ) : (
          <TextInput
            required
            disabled={
              type === "OWNERSHIP_OUT_CADASTRE" &&
              !formData.cadastreMunicipality
            }
            value={formData.cadastreNumber}
            setValue={(e) => {
              setFormData({ ...formData, cadastreNumber: e });
              type === "OWNERSHIP_OUT_CADASTRE" &&
                setDisableSurfaceChange(false);
            }}
            inputProps={{
              pattern: inputPattern.cadastreNumber,
              onBlur: handleGetLandInfo,
            }}
          />
        ),
    },
    /*{
      label: t("CadastreCulture"),
      readOnly: type === "CADASTRE_OWNERSHIP",
      value: formData.cadastreCulture?.name,
      input: (
        <SelectAsyncPaginate
          required
          value={
            formData.cadastreCulture
              ? {
                  label: formData.cadastreCulture.name,
                  value: formData.cadastreCulture.uuid,
                }
              : null
          }
          loadOptions={CadastreCultureOptions}
          onChange={(e) =>
            setFormData(
              e
                ? {
                    ...formData,
                    cadastreCulture: { name: e.label, uuid: e.value },
                  }
                : deleteProps(formData, ["cadastreCulture"])
            )
          }
        />
      ),
    },*/
    {
      label: t("Surface"),
      readOnly: type === "CADASTRE_OWNERSHIP" || disableSurfaceChange,
      valueElement: (
        <SurfaceValue valueObj={convertSurfaceToObj(formData.surface)} />
      ),
      input: (
        <SurfaceInput
          formData={formData}
          setFormData={setFormData}
          value="surfaceObj"
          required
        />
      ),
    },
    {
      label: t("UsedSurface"),
      input: (
        <SurfaceInput
          formData={formData}
          setFormData={setFormData}
          value="usedSurfaceObj"
          required
          
        />
      ),
    },
    {
      label: t("LeaseEndDate"),
      input: (
        <DateInput
          required
          date={formData.leaseEndDate}
          setDate={(e) => setFormData({ ...formData, leaseEndDate: e })}
        />
      ),
      hidden: !["LEASE", "LEASE_LEGAL_ENTITY", "LEASE_CONSOLIDATION"].includes(
        type
      ),
    },
    {
      label: t("LeaseType"),
      value: formData.leaseType?.name,
      input: (
        <SelectAsyncPaginate
          required
          placeholder={t("ChoosePlaceholder")}
          value={
            formData.leaseType
              ? {
                  label: formData.leaseType.name,
                  value: formData.leaseType.uuid,
                }
              : null
          }
          loadOptions={(
            search: string,
            loadedOptions: [],
            offset: { page: number }
          ) =>
            getLeaseTypeOptions(search, loadedOptions, offset, {
              usageBasis: type === "LEASE_LEGAL_ENTITY" ? type : undefined,
            })
          }
          onChange={(e) =>
            setFormData(
              e
                ? {
                    ...formData,
                    leaseType: { name: e.label, uuid: e.value },
                  }
                : deleteProps(formData, ["leaseType"])
            )
          }
        />
      ),
      hidden: !["LEASE", "LEASE_LEGAL_ENTITY", "LEASE_CONSOLIDATION"].includes(
        type
      ),
    },
  ];

  return (
    <div className="flex column gap-4">
      {landInfo.map(
        (e) => !e.hidden && <Input key={e.label} component={e} edit />
      )}
      {type === "CADASTRE_OWNERSHIP" && (
        <Alert text={t("CadastreLandIsMissingDesc")} type={"info"} />
      )}
    </div>
  );
};
