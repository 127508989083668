import { Dispatch, FC, SetStateAction, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { FarmContext } from "../../../../AppContent";
import { Dialog } from "../../../../Elements/Dialog";
import { createEvidenceFromEID } from "../../../../repos/FileRepo";
import { IDialogTypeItem } from "../../../../types/IBase";
import {
  IEvidence,
  IEvidenceCategory,
  IEvidenceSignStatus,
} from "../../../../types/IFile";
import { IStatementType } from "../../../../types/IMember";
import { AddStatementTypeList } from "./Components/AddStatementTypeList";
import { StatementEID } from "./Components/StatementEID";
import { StatementEvidence } from "./Components/StatementEvidence";
import { StatementEvidenceBulk } from "./Components/StatementEvidenceBulk";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  memberUuid: string;
  memberPIN: string;
  category: IEvidenceCategory;
  bulk?: {
    statements: IEvidence[];
    setStatements: Dispatch<SetStateAction<IEvidence[]>>;
    onSubmit: (setIsLoading: (isLoading: boolean) => void) => void;
  };
  approvalUuid?: string;
}

type IStepType = "type" | IStatementType;

export const AddStatementDialog: FC<Props> = ({
  isOpen,
  onClose,
  memberUuid,
  memberPIN,
  category,
  bulk,
  approvalUuid,
}) => {
  const [step, setStep] = useState<IStepType>("type");
  const [evidenceUuid, setEvidenceUuid] = useState("");
  const [signStatus, setSignStatus] = useState<IEvidenceSignStatus | "">("");
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const farmUuid = useContext(FarmContext).farm!.uuid!;

  const typeData: {
    [key in IStepType]: IDialogTypeItem;
  } = {
    type: {
      title: t(`AddStatementTitle${category}`),
      desc: t(`AddStatementDesc${category}`),
      element: (
        <AddStatementTypeList setStep={(e) => setStep(e as IStepType)} />
      ),
    },
    eid: {
      title: t("StatementEIDTitle"),
      descEl: <>{t(`StatementEIDDesc${signStatus || ""}`)}</>,
      element: (
        <StatementEID
          memberPIN={memberPIN}
          evidenceUuid={evidenceUuid}
          signStatus={signStatus}
          setSignStatus={setSignStatus}
        />
      ),
      confirmBtn: !evidenceUuid
        ? {
            label: t("SendEIDStatement"),
            onFormSubmit: () => {
              setIsLoading(true);
              createEvidenceFromEID(farmUuid, memberPIN, category)
                .then((e) => setEvidenceUuid(e))
                .catch(() => {})
                .finally(() => setIsLoading(false));
            },
            keepOpen: true,
          }
        : signStatus === "SIGNED"
        ? { okButton: true }
        : undefined,
      cancelBtn:
        signStatus !== "SIGNED"
          ? { onClick: () => setStep("type") }
          : undefined,
    },
    attachments: {
      title: t("StatementEvidenceTitle"),
      descEl: (
        <>
          <p>{t("StatementEvidenceDesc")}</p>
          <ul>
            <li>
              {t(
                category === "MEMBER_STATEMENT"
                  ? "PaperStatementDesc"
                  : "PaperStatementLandDesc"
              )}
            </li>
            {category === "MEMBER_STATEMENT" && (
              <a
                href="https://erpg.eagrar.gov.rs/upload/prilog-2-izjava-clan.pdf"
                target="_blank"
                rel="noopener noreferrer"
                download
                className="link"
              >
                {t("PrintStatementForm")}
              </a>
            )}
            {category === "MEMBER_LAND_STATEMENT" && (
              <a
                href="https://erpg.eagrar.gov.rs/upload/prilog-5-izjava-clan-zemljista.pdf"
                target="_blank"
                rel="noopener noreferrer"
                download
                className="link"
              >
                {t("PrintStatementForm")}
              </a>
            )}
            <li>{t("PhotoStatementDesc")}</li>
          </ul>
        </>
      ),
      element: bulk ? (
        <StatementEvidenceBulk
          statements={bulk.statements}
          setStatements={bulk.setStatements}
        />
      ) : (
        <StatementEvidence
          memberUuid={memberUuid}
          category={category}
          approvalUuid={approvalUuid}
        />
      ),
      confirmBtn: bulk
        ? {
            onClick: () => bulk.onSubmit(setIsLoading),
            disabled: !bulk.statements.length,
          }
        : { okButton: true },
      cancelBtn: { onClick: () => setStep("type") },
    },
  };

  const handleClose = () => {
    setStep("type");
    onClose();
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      title={typeData[step].title}
      desc={typeData[step].desc}
      descEl={typeData[step].descEl}
      confirmButton={typeData[step].confirmBtn}
      cancelButton={typeData[step].cancelBtn}
      size="m"
      isLoading={isLoading}
    >
      {typeData[step].element}
    </Dialog>
  );
};
