import { ISelectData } from "@inceptionbg/ui-components";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert } from "../../../../Elements/Alert/Alert";
import { Input } from "../../../../Elements/Input";
import { IInvoiceNew } from "../../../../types/IInvoice";
import { Select } from "../../../Inputs/Select";
import { getInvoiceData } from "./invoiceData";

interface Props {
  formData: IInvoiceNew;
  setFormData: Dispatch<SetStateAction<IInvoiceNew>>;
  setIsDisabled: Dispatch<SetStateAction<boolean>>;
}

export const AddInvoiceForm: FC<Props> = ({
  formData,
  setFormData,
  setIsDisabled,
}) => {
  const [type, setType] = useState<ISelectData | null>(null);

  const { t } = useTranslation();
  const isFiscalInvoice = type?.value === "FiscalInvoice";
  const formDisable = !type || isFiscalInvoice;
  const data = getInvoiceData({
    formData,
    setFormData,
    t,
    edit: true,
    disabled: formDisable,
  });

  return (
    <>
      {isFiscalInvoice && <Alert text={t("NoFiscalInvoiceInfo")} type="info" />}
      <Input
        component={{
          label: t("InoviceType"),
          input: (
            <Select
              required
              value={type}
              placeholder={t("ChoosePlaceholder")}
              options={[
                "WholesaleInvoice",
                "ImportInvice",
                "FiscalInvoice",
              ].map((e) => ({
                label: t(e),
                value: e,
              }))}
              onChange={(e) => {
                setType(e);
                setIsDisabled(e.value === "FiscalInvoice");
              }}
            />
          ),
        }}
        edit
      />
      {data.map((e) => (
        <Input key={e.label} component={e} edit />
      ))}
    </>
  );
};
