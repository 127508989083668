import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useEffect,
} from "react";
import { IUser, IUserInfo } from "../types/IUser";
import { getUserInfo } from "../repos/FarmRepo";

interface IUserContext {
  user: IUser | null;
  userInfo: IUserInfo;
  updateUserInfo: (callback?: () => void) => void;
}

export const UserContext = createContext<IUserContext>({
  user: null,
  userInfo: {},
  updateUserInfo: () => {},
});

interface IProps {
  user: IUser | null;
  userInfo: IUserInfo;
  setUserInfo: Dispatch<SetStateAction<IUserInfo>>;
  children: ReactNode;
}

export const UserProvider = ({
  user,
  userInfo,
  setUserInfo,
  children,
}: IProps) => {
  useEffect(() => {
    if (user) {
      getUserInfo()
        .then(setUserInfo)
        .catch(() => {});
    }
  }, [user, setUserInfo]);

  const updateUserInfo = (callback?: () => void) => {
    getUserInfo()
      .then((info) => {
        setUserInfo(info);
        callback && callback();
      })
      .catch(() => {});
  };

  return (
    <UserContext.Provider value={{ user, userInfo, updateUserInfo }}>
      {children}
    </UserContext.Provider>
  );
};
