import clsx from "clsx";
import { FC, ReactNode } from "react";

interface Props {
  focus?: boolean;
  className?: string;
  error?: boolean;
  children: ReactNode;
  helperText?: string;
}
export const InputWrapper: FC<Props> = ({
  focus,
  className,
  error,
  children,
  helperText,
}) => (
  <div className={clsx("iui-input-container", className, { error })}>
    <fieldset className={clsx({ focus })}>{children}</fieldset>
    {helperText && <div className="helper-text">{helperText}</div>}
  </div>
);
