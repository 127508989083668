
export const dataURLtoFile = (
  dataUrl: string,
  filename: string,
  fileExt: string
) => {
  const arr = dataUrl.split(",");
  const mime = arr[0].match(/:(.*?);/)![1];
  const bstr = window.atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], `${filename}.${fileExt}`, { type: mime });
};

export const helpFileUrl =
  "https://erpg.eagrar.gov.rs/upload/uputstvo-za-azuriranje.pdf";

export const helpVideoUrl = "https://youtu.be/zv39zORPfK0";
