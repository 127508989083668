import {
  Button,
  ITableColumn,
  ITableDataItem,
  Table,
  formatDate,
} from "@inceptionbg/ui-components";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  deletePesticideProcurement,
  getProcurementPesticides,
  saveProcurementPesticide,
} from "../repos/PesticideProcurementsRepo";
import { PesticideProcurementDialog } from "../Components/Dialogs/PesticideProcurementDialog";
import { IPesticideProcurement } from "../types/IPesticideProcurement";
import { FarmContext } from "../AppContent";
import { Dialog } from "../Elements/Dialog";

export const PesticideProcurementsPage: FC = () => {
  const [data, setData] = useState<ITableDataItem[]>([]);
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [pesticideProcurement, setPesticideProcurement] = useState<
    Partial<IPesticideProcurement>
  >({});
  const [isOpenForm, setIsOpenForm] = useState(false);

  const [isDeletable, setIsDeletable] = useState(false);
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);

  const { t } = useTranslation();

  const farm = useContext(FarmContext).farm;

  const columns: ITableColumn[] = [
    { id: "name", label: t("Name") },
    { id: "quantity", label: t("Quantity") },
    { id: "unit", label: t("UnitOfMeasure") },
    { id: "date", label: t("PurchaseDate") },
    { id: "actions", label: t("Actions"), align: "center" },
  ];

  const handleGetPesticideProcurements = useCallback(() => {
    if (farm?.uuid) {
      setIsLoading(true);
      getProcurementPesticides(farm?.uuid!, { limit, offset }, [
        "FARM",
        "ORGANIZATION_FORM",
        "PLANT_PROTECTION_PRODUCT",
      ])
        .then(({ pesticideProcurements }) => {
          setData(
            pesticideProcurements.map((pesticiceProcurement) => ({
              uuid: pesticiceProcurement.uuid,
              cells: {
                name: {
                  value: pesticiceProcurement.plantProtectionProduct?.name,
                },
                quantity: {
                  value: pesticiceProcurement.quantity,
                },
                unit: { value: pesticiceProcurement.unit },
                date: {
                  value: formatDate(pesticiceProcurement.date),
                },
                actions: {
                  value: (
                    <Button
                      label={t("Details")}
                      onClick={(event) => {
                        event.stopPropagation();
                        setPesticideProcurement(pesticiceProcurement);
                        setIsOpenForm(true);
                        setIsDeletable(true);
                      }}
                      outlined
                      size={"s"}
                    />
                  ),
                },
              },
            })) as ITableDataItem[]
          );
          setTotalRows(totalRows);
        })
        .catch(() => {})
        .finally(() => setIsLoading(false));
    }
  }, [totalRows, limit, offset, t, farm]);

  useEffect(handleGetPesticideProcurements, [handleGetPesticideProcurements]);

  const onSubmit = () => {
    setIsLoading(true);
    saveProcurementPesticide(farm?.uuid!, pesticideProcurement)
      .then(() => {
        handleGetPesticideProcurements();
        setPesticideProcurement({});
        setIsOpenForm(false);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  const onDelete = () => {
    setDeleteIsOpen(true);
  };

  const handleDelete = () => {
    setIsLoading(true);
    deletePesticideProcurement(pesticideProcurement?.uuid!)
      .then(() => {
        handleGetPesticideProcurements();
        setPesticideProcurement({});
        setIsOpenForm(false);
        setIsDeletable(false);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <div className="flex justify-right">
        <Button
          label={t("AddPesticidePurchase")}
          size="s"
          primary
          onClick={() => {
            setIsOpenForm(true);
          }}
        />
      </div>
      <Table
        columns={columns}
        data={data}
        isLoading={isLoading}
        serverSidePagination={{
          limit,
          offset,
          setLimit,
          setOffset,
          totalRows,
        }}
        footerAction={{
          onClick: handleGetPesticideProcurements,
        }}
      />
      <PesticideProcurementDialog
        isOpen={isOpenForm}
        onClose={() => {
          setIsOpenForm(false);
          setPesticideProcurement({});
          setIsDeletable(false);
        }}
        data={pesticideProcurement}
        setData={setPesticideProcurement}
        onSubmit={onSubmit}
        onDelete={onDelete}
        isDeletable={isDeletable}
      />
      <Dialog
        isOpen={!!deleteIsOpen}
        onClose={() => setDeleteIsOpen(false)}
        desc={t("DeletePesticideDesc")}
        confirmButton={{
          label: t("Delete"),
          onClick: () => handleDelete(),
        }}
        cancelButton={{ onClick: () => setDeleteIsOpen(false) }}
      />
    </>
  );
};
