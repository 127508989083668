import { Button } from "@inceptionbg/ui-components";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  errorCode: string;
  resetError: () => void;
}
export const AppError: FC<Props> = ({ errorCode, resetError }) => {
  const { t } = useTranslation();
  return (
    <div className="app-error">
      <h1 className="new-line text-center">
        {t("AppError", { app: t(errorCode) })}
      </h1>
      <Button label={t("TryAgain")} size="l" primary onClick={resetError} />
    </div>
  );
};
