import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FarmContext } from "../../../../../AppContent";
import { Alert } from "../../../../../Elements/Alert/Alert";
import { Input } from "../../../../../Elements/Input";
import { IRefactionRequestItemNew } from "../../../../../types/IRefaction";
import { Form } from "../../../../Wrapper/FormWrapper";
import { getRefactionItemData } from "./refactionItemData";

interface Props {
  item?: IRefactionRequestItemNew;
  items?: IRefactionRequestItemNew[];
  edit?: boolean;
  newForm?: boolean;
  invoiceUuids?: string[];
  setIsFormVisible?: (visible: boolean) => void;
  onSubmit?: (item: IRefactionRequestItemNew, callback: () => void) => void;
}

export const RefactionItemsForm: FC<Props> = ({
  item,
  items,
  edit = false,
  newForm,
  invoiceUuids,
  setIsFormVisible,
  onSubmit,
}) => {
  const [formData, setFormData] = useState<IRefactionRequestItemNew>(
    item || {}
  );
  const [cultureLiterRecapitulation, setCultureLiterRecapitulation] =
    useState<number>(0);
  const [landLiterLeft, setLandLiterLeft] = useState<{ [key: string]: number }>(
    {}
  );

  const { t } = useTranslation();
  const farmUuid = useContext(FarmContext).farm?.uuid!;

  useEffect(() => {
    if (formData.landObj?.value) {
      const landId = formData.landObj.value;
      const totalUsedLiters =
        items
          ?.filter((item) => item.landObj?.value === landId)
          .reduce((acc, item) => acc + (item.liter || 0), 0) || 0;

      setLandLiterLeft((prev) => ({
        ...prev,
        [landId]:
          formData.landObj?.literRecapitulation.leftLiter - totalUsedLiters,
      }));
    }
  }, [formData.liter, formData.landObj, items]);

  const data = getRefactionItemData({
    formData,
    setFormData,
    t,
    edit,
    farmUuid,
    invoiceUuids,
    items,
    cultureLiterRecapitulation,
    setCultureLiterRecapitulation,
    landLiterLeft,
    setLandLiterLeft,
  });

  return newForm ? (
    <>
      {!!formData.landPlantCulture?.literRecapitulation && (
        <Alert
          text={t("RemainLiters", {
            value: Math.min(
              landLiterLeft[formData.landObj?.value] || 0,
              cultureLiterRecapitulation
            ),
          })}
          type="info"
          className="mb-2"
        />
      )}
      <Form
        data={data}
        edit={!!newForm || edit}
        setEdit={() => {}}
        submitButton={{
          editLabel: t(!!newForm ? "SaveLand" : "LandEdit"),
          disabled: formData?.liter! <= 0,
          onSubmit: () => {
            if (formData.landObj?.value && formData.liter) {
              setLandLiterLeft((prev) => ({
                ...prev,
                [formData.landObj?.value]:
                  (prev[formData.landObj?.value] || 0) - formData.liter!,
              }));
            }
            onSubmit && onSubmit(formData, () => setFormData(item || {}));
          },
        }}
        onResetForm={() => {
          setFormData(item || {});
          setIsFormVisible && setIsFormVisible(false);
        }}
        isLoading={false}
      />
    </>
  ) : (
    <div className="w-600 mx-auto">
      {data.map((e) => (
        <Input key={e.label} component={e} />
      ))}
    </div>
  );
};
