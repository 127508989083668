import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Dialog } from "../../Elements/Dialog";
import { List } from "../../Elements/List";
import { helpFileUrl, helpVideoUrl } from "../../utils/FileUtils";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const HelpDialog: FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title={t("HelpTitle")}
      desc={t("HelpDesc")}
      confirmButton={{ label: t("Ok"), okButton: true }}
      size="m"
      enableBackgroundClose
    >
      <div>
        <List
          listItems={["AQ1", "AQ2", "AQ3", "AQ4", "AQ5", "AQ6", "AQ7"].map((item) => ({
            id: item,
            desc: <Trans i18nKey={`Help${item}`} />,
          }))}
        />
        <List
          listItems={["TEXT", "VIDEO"].map((item) => ({
            id: item,
            title: t(`Help${item}`),
          }))}
          onClick={(e) => {
            window.open(e === "TEXT" ? helpFileUrl : helpVideoUrl, "_blank");
            onClose();
          }}
        />
      </div>
    </Dialog>
  );
};
