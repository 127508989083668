import { FC, ReactNode, useContext } from "react";
import { Button } from "@inceptionbg/ui-components";
import { IInputComponentProps, Input } from "../../Elements/Input";
import { useTranslation } from "react-i18next";
import { Loader } from "../../Elements/Loader";
import { IButtonSize } from "../../types/IBase";
import { ModeContext } from "../../Pages/RPGPage";

export interface IForm {
  data: IInputComponentProps[];
  edit: boolean;
  setEdit: (e: boolean) => void;
  submitButton: {
    editLabel: string;
    icon?: any;
    disabled?: boolean;
    onSubmit: () => void;
    primary?: boolean;
    hidden?: boolean;
  };
  onResetForm: () => void;
  onCancel?: () => void;
  otherButtons?: {
    label: string;
    className?: string;
    icon?: any;
    disabled?: boolean;
    outlined?: boolean;
    primary?: boolean;
    onClick: () => void;
    hidden?: boolean;
  }[];
  buttonSize?: IButtonSize;
  isLoading: boolean;
  className?: string;
  children?: ReactNode;
}

export const Form: FC<IForm> = ({
  data,
  edit,
  setEdit,
  submitButton,
  onResetForm,
  onCancel,
  otherButtons,
  buttonSize,
  isLoading,
  className,
  children,
}) => {
  const { t } = useTranslation();
  const mode = useContext(ModeContext);

  return (
    <Loader isLoading={isLoading}>
      <form
        className={className}
        onSubmit={(e) => {
          e.preventDefault();
          !submitButton.disabled && submitButton.onSubmit();
        }}
      >
        <div className="form-container">
          {data.map(
            (e) =>
              !e.hidden && <Input key={e.label} component={e} edit={edit} />
          )}
          {children}
          <div className="button-container">
            {otherButtons?.map(
              (e) =>
                !e.hidden && (
                  <Button
                    className={e.className}
                    key={e.label}
                    label={e.label}
                    icon={e.icon}
                    disabled={isLoading || e.disabled}
                    outlined={e.outlined}
                    primary={e.primary}
                    onClick={e.onClick}
                    size={buttonSize || "s"}
                  />
                )
            )}
            {mode !== "RENEW" && (
              <>
                {edit && (
                  <Button
                    label={t("Cancel")}
                    onClick={
                      onCancel
                        ? onCancel
                        : () => {
                            onResetForm();
                            setEdit(false);
                          }
                    }
                    outlined
                    size={buttonSize || "s"}
                  />
                )}
                {/* Must be separated because of the form submit */}
                {!submitButton.hidden && edit && (
                  <Button
                    label={submitButton.editLabel || t("SaveChanges")}
                    primary
                    size={buttonSize || "s"}
                  />
                )}
                {!submitButton.hidden && !edit && (
                  <Button
                    label={submitButton.editLabel}
                    onClick={() => setEdit(!edit)}
                    size={buttonSize || "s"}
                    outlined
                  />
                )}
              </>
            )}
          </div>
        </div>
      </form>
    </Loader>
  );
};
