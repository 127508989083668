import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

const prefix = "fas";
const iconName = "minus";
const width = 448;
const height = 512;
const unicode = "f068";
const svgPathData =
  'M400 288h-352c-17.69 0-32-14.32-32-32.01s14.31-31.99 32-31.99h352c17.69 0 32 14.3 32 31.99S417.7 288 400 288z';

export const faMinus: IconDefinition = {
  prefix,
  iconName,
  icon: [width, height, [], unicode, svgPathData],
};
