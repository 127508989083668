import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

const prefix = "far";
const iconName = "timer";
const width = 512;
const height = 512;
const unicode = "e29e";
const svgPathData =
  "M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 202.7 16.29 153.2 44.15 112.2C51.6 101.3 66.53 98.43 77.49 105.9C88.45 113.3 91.3 128.3 83.85 139.2C61.22 172.5 47.1 212.7 47.1 256C47.1 370.9 141.1 464 255.1 464C370.9 464 464 370.9 464 256C464 149.2 383.6 61.27 280 49.37V104C280 117.3 269.3 128 255.1 128C242.7 128 231.1 117.3 231.1 104V24C231.1 10.75 242.7 0 255.1 0C397.4 0 512 114.6 512 256H512zM272.1 239C282.3 248.4 282.3 263.6 272.1 272.1C263.6 282.3 248.4 282.3 239 272.1L159 192.1C149.7 183.6 149.7 168.4 159 159C168.4 149.7 183.6 149.7 192.1 159L272.1 239z";

export const faTimer: IconDefinition = {
  prefix,
  iconName,
  icon: [width, height, [], unicode, svgPathData],
};
