import { Button } from "@inceptionbg/ui-components";
import clsx from "clsx";
import { FC, ReactElement, useContext } from "react";
import { AppContext } from "../Context/AppContext";
import { IAlertType } from "../types/IBase";
import { AlertIcon } from "./Alert/Alert";

export interface IInputComponentProps {
  label: string;
  labelHelper?: string;
  value?: string;
  valueElement?: ReactElement;
  input?: ReactElement;
  alert?: {
    text?: string;
    type: IAlertType;
    active: boolean;
    action?: {
      label: string;
      onClick: () => void;
    };
  };
  readOnly?: boolean;
  hidden?: boolean;
  edit?: boolean;
}

interface Props {
  component: IInputComponentProps;
  edit?: boolean;
}

export const Input: FC<Props> = ({
  component: {
    label,
    alert,
    input,
    labelHelper,
    value,
    valueElement,
    readOnly,
  },
  edit,
}) => {
  const {
    screen: { isMobile },
  } = useContext(AppContext);
  return (
    <div className="input-container">
      <div className={clsx("input-item", { edit: edit && input })}>
        <div className="label-container">
          <p className="label">{label}</p>
          {!isMobile && <p className="helper-label">{labelHelper}</p>}
        </div>
        {!readOnly && edit && input
          ? input
          : valueElement || (
              <div className="flex align-center">
                <p className="full-width">{value || "/"}</p>
                {alert?.active && isMobile && <AlertIcon type={alert?.type} />}
              </div>
            )}
        {alert?.active && !isMobile && (
          <AlertIcon type={alert?.type} className="input-alert" />
        )}
      </div>
      {edit && alert?.active && (
        <div className="input-helper">
          {
            <p
              className={clsx("helper-text", {
                "color-error": alert.type === "error",
              })}
            >
              {alert.text}
            </p>
          }
          {alert.action && (
            <Button
              label={alert.action.label}
              primary
              onClick={alert.action.onClick}
              size="xs"
              className={alert?.type}
            />
          )}
        </div>
      )}
    </div>
  );
};
