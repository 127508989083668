import { FC, ReactNode, useContext, useState } from "react";
import { AppContext } from "../../Context/AppContext";
import { Footer } from "../Footer";
import { Header } from "../Header/Header";
import { useTranslation } from "react-i18next";
import {
  Button,
  ConditionalWrapper,
  IconButton,
} from "@inceptionbg/ui-components";
import { Sidebar } from "../Sidebar";
import { ISidebarType } from "../../types/IBase";
import { AppError } from "../../Elements/Alert/AppError";
import { NoAccessInfo } from "../NoAccessInfo";
import { faCircleQuestion } from "../../assets/icons/light/faCircleQuestion";
import { HelpDialog } from "../Dialogs/HelpDialog";
import { UserContext } from "../../Context/UserContext";
import { Loader } from "../../Elements/Loader";

interface Props {
  title?: string;
  sidebar?: ISidebarType;
  helpButton?: {
    isOpen?: boolean;
  };
  noAccess?: boolean;
  isPublic?: boolean;
  children: ReactNode;
}
export const PageWrapper: FC<Props> = ({
  title,
  sidebar,
  helpButton,
  noAccess,
  isPublic,
  children,
}) => {
  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const {
    appUnavailable,
    screen: { contentHeight, isMobile, isDesktop },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { userInfo } = useContext(UserContext);

  // useEffect(() => {
  //   setIsHelpOpen(!!helpButton?.isOpen);
  // }, [helpButton]);

  return isPublic || userInfo.isLoaded ? (
    <>
      <Header />
      {title && (
        <div className="background-primary color-white px-4 py-2 mobile-hidden">
          <h1>{`${t("eRPGLong")} - ${t(title).toUpperCase()}`}</h1>
        </div>
      )}

      <ConditionalWrapper
        condition={!!sidebar}
        wrapper={(children) => (
          <div className="flex p-3">
            {isDesktop && <Sidebar type={sidebar!} />}
            {children}
          </div>
        )}
      >
        <div className="full-width" style={{ minHeight: contentHeight }}>
          {noAccess ? (
            <NoAccessInfo />
          ) : appUnavailable ? (
            <AppError
              errorCode={appUnavailable.errorCode}
              resetError={appUnavailable.resetError}
            />
          ) : (
            children
          )}
        </div>
      </ConditionalWrapper>

      {helpButton && (
        <>
          {isMobile ? (
            <IconButton
              icon={faCircleQuestion}
              onClick={() => setIsHelpOpen(true)}
              className="help-button secondary"
            />
          ) : (
            <Button
              label={t("Help")}
              icon={faCircleQuestion}
              onClick={() => setIsHelpOpen(true)}
              className="help-button secondary"
              size="l"
            />
          )}
          <HelpDialog
            isOpen={isHelpOpen}
            onClose={() => setIsHelpOpen(false)}
          />
        </>
      )}
      <Footer />
    </>
  ) : (
    <Loader isLoading>
      <div className="full-screen" />
    </Loader>
  );
};
