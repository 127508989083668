import { Button } from "@inceptionbg/ui-components";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const NoAccessInfo: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="no-access">
      <h2>{t("NoAccessInfo")}</h2>
      <Link to="/">
        <Button primary label={t("ReturnToDashboard")} size="l" />
      </Link>
    </div>
  );
};
