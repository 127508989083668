import { Button } from "@inceptionbg/ui-components";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FarmContext } from "../../../AppContent";
import { ModeContext } from "../../../Pages/RPGPage";
import { Section } from "../../Section";
import { IAuthorizedPersonNew } from "../../../types/IAuthorizedPerson";
import { getAuthorizedPersons } from "../../../repos/AuthorizedPersonsRepo";
import { AuthorizedPerson } from "./AuthorizedPerson";

export const AuthorizedPersonsSection: FC = () => {
  const [authorizedPersons, setAuthorizedPersons] = useState<IAuthorizedPersonNew[]>([]);
  const [addAuthorizedPerson, setAddAuthorizedPerson] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { farm } = useContext(FarmContext);
  const isRenew = useContext(ModeContext) === "RENEW";
  const { t } = useTranslation();

  const handleGetAuthorizedPersons = useCallback(
    (callback?: () => void) => {
      if (farm) {
        setIsLoading(true);
        getAuthorizedPersons(farm.uuid)
          .then((data) => {
            setAuthorizedPersons(data.authorizedPersons);
            callback && callback();
          })
          .catch(() => {
          })
          .finally(() => setIsLoading(false));
      }
    },
    [farm],
  );

  useEffect(handleGetAuthorizedPersons, [handleGetAuthorizedPersons]);

  return (
    <Section id="authorizedPersons" title={t("AuthorizedPersonsTitleOrdered")}>
      <div className="form-container">
        {farm &&
          !isLoading &&
          authorizedPersons.map((person) => (
            <AuthorizedPerson
              key={person.personalIdentityNumber}
              authorizedPerson={person}
              reloadAuthorizedPersons={handleGetAuthorizedPersons}
            />
          ))}
        {addAuthorizedPerson && (
          <AuthorizedPerson
            newForm={{ onCancel: () => setAddAuthorizedPerson(false) }}
            reloadAuthorizedPersons={handleGetAuthorizedPersons}
          />
        )}
      </div>
      {!isRenew && (
        <div className="flex justify-right mt-3">
          <Button
            label={t("AuthorizedPersonAdd")}
            primary
            onClick={() => setAddAuthorizedPerson(true)}
          />
        </div>
      )}
    </Section>
  );
};
