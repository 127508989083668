import { Button } from "@inceptionbg/ui-components";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "../../../../../Elements/Input";
import { IInvoiceItem } from "../../../../../types/IInvoice";
import { getInvoiceItemData } from "./invoiceItemData";

interface Props {
  item?: IInvoiceItem;
  edit?: boolean;
  onSubmit?: (item: IInvoiceItem) => void;
  excludeFuelTypeUuids?: string[];
}

export const InvoiceItem: FC<Props> = ({
  item,
  edit = false,
  onSubmit,
  excludeFuelTypeUuids,
}) => {
  const [formData, setFormData] = useState<IInvoiceItem>(item || {});

  const { t } = useTranslation();
  const data = getInvoiceItemData({
    formData,
    setFormData,
    t,
    edit,
    excludeFuelTypeUuids,
  });

  return (
    <>
      {data.map((e) => (
        <Input key={e.label} component={e} edit={edit} />
      ))}
      {onSubmit && (
        <div className="flex justify-right mt-3">
          <Button
            label={t("InvoiceItemAdd")}
            primary
            onClick={() => {
              onSubmit(formData);
              setFormData({});
            }}
            disabled={!formData.fuelType || !formData.liter}
          />
        </div>
      )}
    </>
  );
};
