import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { FarmContext } from "../../../AppContent";
import { Section } from "../../Section";
import { BankAccount } from "./SubSections/BankAccount";
import { ContactInfo } from "./SubSections/ContactInfo";
import { HeadOffice } from "./SubSections/HeadOffice";
import { PersonalInfoForm } from "../Common/PersonalInfoForm";
import { SubSection } from "../../SubSection";
import { LegalEntityInfo } from "./SubSections/LegalEntityInfo";
import { LegalRepresentative } from "./SubSections/LegalRepresentative";
import { IFarmNew } from "../../../types/IFarm";
import { Input } from "../../../Elements/Input";
import { SelectAsyncPaginate } from "../../Inputs/SelectAsyncPaginate";
import { getFarmOrganizationFormOptions } from "../../../utils/LoadOptions";
import { Button, deleteProps } from "@inceptionbg/ui-components";
import { createFarm } from "../../../repos/FarmRepo";
import { Loader } from "../../../Elements/Loader";
import { useNavigate } from "react-router-dom";
import { holderTypes, IHolderType } from "../../../types/IHolder";
import { Dialog } from "../../../Elements/Dialog";
import { List } from "../../../Elements/List";
import { localStorageSet } from "../../../utils/LocalStorageHelper";
import { UserContext } from "../../../Context/UserContext";
import { Checkbox } from "../../../Elements/Checkbox";

export const BasicInfoSectionNew: FC = () => {
  const [holderType, setHolderType] = useState<IHolderType | "">("");
  const [formData, setFormData] = useState<IFarmNew>({
    agriculturalProduction: true,
  });
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { handleGetFarm } = useContext(FarmContext);
  const { userInfo, updateUserInfo } = useContext(UserContext);

  const onSubmit = () => {
    setIsLoading(true);
    createFarm({
      ...formData,
      holder: { ...formData.holder, type: holderType as IHolderType },
      contactInfo: {
        ...formData.contactInfo,
        phoneNumber: `+381${formData.contactInfo?.phoneNumber}`,
        deliveryType: formData.contactInfo?.deliveryType || "INBOX",
      },
    })
      .then((farmUuid) => {
        localStorageSet("activeOrg", farmUuid);
        updateUserInfo(() =>
          handleGetFarm(farmUuid, () => {
            setIsLoading(false);
            navigate("/edit");
          })
        );
      })
      .catch(() => setIsLoading(false));
  };

  const formDisabled =
    !formData.headOffice?.evidences?.length ||
    (formData.holder?.type === "legalEntity" &&
      !formData.legalRepresentative?.evidences?.length) ||
    !formData.bankAccount?.evidences?.length;

  return (
    <>
      {holderType && (
        <Section id="basic" title={t("BasicInfoTitleOrdered")}>
          <Loader isLoading={isLoading}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              <div className="form-container">
                <Input
                  component={{
                    label: t("OrganizationalForm"),
                    input: (
                      <SelectAsyncPaginate
                        required
                        value={
                          formData.organizationForm
                            ? {
                                label: formData.organizationForm.name!,
                                value: formData.organizationForm.uuid!,
                              }
                            : null
                        }
                        loadOptions={(
                          search: string,
                          loadedOptions: [],
                          offset: { page: number }
                        ) =>
                          getFarmOrganizationFormOptions(
                            search,
                            loadedOptions,
                            offset,
                            {
                              physicalPersonHolder:
                                holderType === "physicalPerson",
                            }
                          )
                        }
                        onChange={(e) =>
                          setFormData(
                            e
                              ? {
                                  ...formData,
                                  organizationForm: {
                                    name: e.label,
                                    uuid: e.value,
                                  },
                                }
                              : deleteProps(formData, ["organizationForm"])
                          )
                        }
                      />
                    ),
                  }}
                  edit
                />
                {holderType === "legalEntity" && (
                  <>
                    <Input
                      component={{
                        label: t("AgriculturalProduction"),
                        input: (
                          <Checkbox
                            selected={!!formData.agriculturalProduction}
                            setSelected={(e) =>
                              setFormData((data) => ({
                                ...data,
                                agriculturalProduction: e,
                              }))
                            }
                          />
                        ),
                      }}
                      edit
                    />
                    <Input
                      component={{
                        label: t("AgriculturalProductsProcessing"),
                        input: (
                          <Checkbox
                            selected={!!formData.agriculturalProductsProcessing}
                            setSelected={(e) =>
                              setFormData((data) => ({
                                ...data,
                                agriculturalProductsProcessing: e,
                              }))
                            }
                          />
                        ),
                      }}
                      edit
                    />
                    <Input
                      component={{
                        label: t("NonAgriculturalProduction"),
                        input: (
                          <Checkbox
                            selected={!!formData.nonAgriculturalProduction}
                            setSelected={(e) =>
                              setFormData((data) => ({
                                ...data,
                                nonAgriculturalProduction: e,
                              }))
                            }
                          />
                        ),
                      }}
                      edit
                    />
                  </>
                )}
                {holderType === "physicalPerson" && (
                  <SubSection title={t("ArgHolderInfoTitleOrdered")}>
                    <PersonalInfoForm isHolder setFarm={setFormData} />
                  </SubSection>
                )}
                {holderType === "legalEntity" && (
                  <LegalEntityInfo setFarm={setFormData} />
                )}
                <HeadOffice farmData={formData} setFarm={setFormData} />
                <ContactInfo setFarm={setFormData} />
                <BankAccount farmData={formData} setFarm={setFormData} />
                {holderType === "legalEntity" && (
                  <LegalRepresentative
                    farmData={formData}
                    setFarm={setFormData}
                  />
                )}
                <div className="button-container">
                  <Button
                    label={t("Cancel")}
                    onClick={() => navigate("/")}
                    outlined
                    size="l"
                  />
                  <Button
                    label={t("StartFarmCreate")}
                    primary
                    size="l"
                    disabled={formDisabled}
                  />
                </div>
              </div>
            </form>
          </Loader>
        </Section>
      )}
      <Dialog
        isOpen={!holderType}
        onClose={() => navigate(-1)}
        title={t("SelectHolderType")}
        desc={t("")}
        size="m"
      >
        <List
          listItems={holderTypes.map((item) => ({
            id: item,
            title: t(`HolderType${item}`),
            disabled:
              item === "physicalPerson" &&
              (userInfo.isUserHolder || userInfo.isUserMember),
          }))}
          onClick={(e) => setHolderType(e as IHolderType)}
        />
      </Dialog>
    </>
  );
};
