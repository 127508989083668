import { FC, useContext, useState } from "react";
import { IAnimalNew } from "../../../types/IAnimal";
import { FarmContext } from "../../../AppContent";
import { addCustomAnimal } from "../../../repos/AnimalRepo";
import { Dialog } from "../../../Elements/Dialog";
import { useTranslation } from "react-i18next";
import { IInputComponentProps, Input } from "../../../Elements/Input";
import { TextInput } from "@inceptionbg/ui-components";
import { productionType } from "../../../types/ILand";
import { Select } from "../../Inputs/Select";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  reloadAnimals: (callback?: () => void) => void;
}
export const AnimalAddDialog: FC<Props> = ({
  isOpen,
  onClose,
  reloadAnimals,
}) => {
  const [formData, setFormData] = useState<IAnimalNew>({});
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const farmUuid = useContext(FarmContext).farm?.uuid;

  const animalInfo: IInputComponentProps[] = [
    {
      label: t("AnimalName"),
      value: formData.name,
      input: (
        <TextInput
          value={formData.name}
          setValue={(e) => setFormData({ ...formData, name: e })}
          required
          autoFocus
        />
      ),
    },
    {
      label: t("AnimalProductionType"),
      value: formData.productionType ? t(formData.productionType) : "/",
      input: (
        <Select
          required
          value={
            formData.productionType
              ? {
                  label: t(formData.productionType),
                  value: formData.productionType,
                }
              : null
          }
          options={productionType.map((e) => ({
            label: t(`Animal${e}`),
            value: e,
          }))}
          onChange={(e) =>
            setFormData({ ...formData, productionType: e.value })
          }
        />
      ),
    },
    {
      label: t("AnimalDescriptionAndCount"),
      value: formData.description,
      input: (
        <TextInput
          value={formData.description}
          setValue={(e) => setFormData({ ...formData, description: e })}
          required
        />
      ),
    },
  ];

  const handleClose = () => {
    setFormData({});
    onClose();
  };

  const onSubmit = () => {
    if (farmUuid) {
      setIsLoading(true);
      addCustomAnimal(farmUuid, formData)
        .then((e) => {
          reloadAnimals(() => {
            setIsLoading(false);
            handleClose();
          });
        })
        .catch(() => setIsLoading(false));
    }
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      title={t("AddCustomAnimal")}
      confirmButton={{
        label: t("Confirm"),
        onFormSubmit: onSubmit,
      }}
      cancelButton={{
        onClick: () => {
          setFormData({});
          onClose();
        },
      }}
      size="m"
      isLoading={isLoading}
    >
      {animalInfo.map(
        (e) => !e.hidden && <Input key={e.label} component={e} edit />
      )}
    </Dialog>
  );
};
