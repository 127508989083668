import { IFile } from "./IFile";
import { ISelectData } from "@inceptionbg/ui-components";
import { ICase } from "./ICase";
import { IFarmNew } from "./IFarm";

export interface IRequestNew {
  title?: string;
  requestType?: IRequestType;
  requestTypeObj?: ISelectData;
  purpose?: string;
  note?: string;
  deliveryType?: IDeliveryType;
  storedFiles?: IFile[];
  case?: ICase;
  caseId?: string;
  farm?: IFarmNew;
  atDay?: string;
}

export const DeliveryTypes = ["INBOX", "DOWNLOAD"] as const;
export type IDeliveryType = (typeof DeliveryTypes)[number];

export type IRequestType =
  | "EXCERPT_FROM_REGISTER"
  | "EXCERPT_PLANT_CULTURE"
  | "EXCERPT_ANIMAL"
  | "STATUS_CONFIRMATION"
  | "ACTIVE_STATUS_LEAST_CONFIRMATION"
  | "STATUS_CHANGE_REQUEST"
  | "DELETE_REQUEST"
  | "ADDENDUM"
  | "GENERAL_REQUEST"
  | "COMPLAINT"
  | "COMPLAINT_WAIVER";
