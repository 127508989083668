import { axiosInstance } from "../App";
import {
  IEvidence,
  IEvidenceCategory,
  IEvidenceFilters,
  IFile,
  IMobileUploadSession,
  IUploadStatus,
} from "../types/IFile";

export const uploadFile = (file: File) => {
  const formData = new FormData();
  formData.append("file", file);

  return axiosInstance
    .post("/stored-file", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        noToast: true,
      },
    })
    .then(
      ({ data }) =>
        ({
          uuid: data.identifier,
          originalFileName: data.originalFileName,
        } as IFile)
    );
};

// EVIDENCE

export const createEvidenceFromEID = (
  farmUuid: string,
  signatoryPersonalIdentityNumber: string,
  category: IEvidenceCategory
) =>
  axiosInstance
    .post(`/farm/${farmUuid}/evidence`, {
      category,
      signatoryPersonalIdentityNumber,
    })
    .then(({ data }) => data.identifier as string);

export const createEvidenceFromStoredFile = (
  fileUuid: string,
  farmUuid: string,
  category: IEvidenceCategory,
  filters?: IEvidenceFilters
) =>
  axiosInstance
    .post(`/farm/${farmUuid}/evidence`, {
      category,
      storedFile: { uuid: fileUuid },
      ...filters,
    })
    .then(({ data }) => data.identifier as string);

export const createEvidenceFromFile = (
  file: File,
  farmUuid: string,
  category: IEvidenceCategory,
  filters?: IEvidenceFilters
) =>
  uploadFile(file).then((data) =>
    createEvidenceFromStoredFile(data.uuid!, farmUuid, category, filters).catch(
      () => {}
    )
  );

export const getEvidences = (
  farmUuid: string,
  category: IEvidenceCategory,
  filters?: {
    landUuid?: string;
    memberUuid?: string;
    approvalUuid?: string;
  }
) =>
  axiosInstance
    .get(`/farm/${farmUuid}/evidences`, {
      params: {
        limit: 100,
        fetchOptions: ["STORED_FILE"],
        category,
        ...filters,
      },
    })
    .then(
      ({ data }) =>
        data as {
          evidences: IEvidence[];
          totalRows: number;
        }
    );

export const getEvidenceByUuid = (evidenceUuid: string) =>
  axiosInstance
    .get(`/evidence/${evidenceUuid}`)
    .then(({ data }) => data as IEvidence);

export const deleteEvidence = (evidenceUuid: string) =>
  axiosInstance.delete(`/evidence/${evidenceUuid}`);

// MOBILE

export const createPublicUploadKey = () =>
  axiosInstance
    .post("/public-upload", undefined, {
      headers: {
        noToast: true,
      },
    })
    .then(
      ({ data }) =>
        data as {
          uuid: string;
          createdAt: string;
          expiresAt: string;
        }
    );

export const uploadFilePub = (uploadKey: string, file: File) => {
  const formData = new FormData();
  formData.append("file", file);

  return axiosInstance
    .post(`/public-upload/p/${uploadKey}/stored-file`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        noToast: true,
        public: true,
      },
    })
    .then(
      ({ data }) =>
        ({
          uuid: data.identifier,
          originalFileName: data.originalFileName,
        } as IFile)
    );
};

export const getStoredFilesPub = (uploadKey: string) =>
  axiosInstance
    .get(`/public-upload/${uploadKey}/stored-files`)
    .then(({ data }) => data.storedFiles as IFile[]);

export const getMobileUploadSession = (uploadKey: string) =>
  axiosInstance
    .get(`/public-upload/${uploadKey}`)
    .then(({ data }) => data as IMobileUploadSession);

export const setMobileUploadStatus = (
  uploadKey: string,
  status: IUploadStatus
) =>
  axiosInstance
    .patch(
      `/public-upload/p/${uploadKey}`,
      { status },
      { headers: { public: true } }
    )
    .then(({ data }) => data as IMobileUploadSession);
