import { FC } from "react";
import { useTranslation } from "react-i18next";
import { SubSection } from "../../../SubSection";
import { EvidencesList } from "../../../Files/EvidencesList";
import { FilesDropzone } from "../../../Files/Dropzone/FilesDropzone";
import { IFile } from "../../../../types/IFile";
import { uploadFile } from "../../../../repos/FileRepo";
import { InfoBox } from "../../../../Elements/Alert/InfoBox";

interface Props {
  storedFiles: IFile[];
  setStoredFiles: (storedFiles: any) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  infoBoxItems?: {
    title: string;
    file?: {
      label: string;
      url: string;
    };
  }[];
}

export const FilesUpload: FC<Props> = ({
  storedFiles,
  setStoredFiles,
  isLoading,
  setIsLoading,
  infoBoxItems,
}) => {
  const { t } = useTranslation();

  const handleUpload = (data: File[]) => {
    setIsLoading(true);
    data.forEach((file, i) => {
      uploadFile(file)
        .then((e) => setStoredFiles((storedFiles: any) => [...storedFiles, e]))
        .catch(() => setIsLoading(false))
        .finally(() => {
          if (i === data.length - 1) {
            setIsLoading(false);
          }
        });
    });
  };

  const onDeleteFile = (index: number, callback: () => void) => {
    setStoredFiles((e: any) => {
      let newList = [...e];
      newList.splice(index, 1);
      return newList;
    });
    callback();
  };

  return (
    <SubSection title={t("UploadedFiles")} isLoading={isLoading}>
      {!!infoBoxItems && (
        <InfoBox
          title={t("DocumentsNeeded")}
          desc={t("DocumentsNeededDesc")}
          descEl={
            <ul className="m-0 flex column gap-3">
              {infoBoxItems.map((e) => (
                <li key={e.title}>
                  <strong>{e.title}</strong>
                  <br />
                  {e.file && (
                    <a
                      href={e.file.url}
                      download
                      target="_blank"
                      rel="noreferrer"
                      className="link"
                    >
                      {e.file.label}
                    </a>
                  )}
                </li>
              ))}
            </ul>
          }
          light
          size="s"
          className="mb-4"
        />
      )}
      {!!storedFiles.length && (
        <EvidencesList
          evidences={storedFiles.map((storedFile) => ({
            storedFile: storedFile,
          }))}
          onDeleteFile={onDeleteFile}
        />
      )}
      <FilesDropzone setFiles={setStoredFiles} onSubmit={handleUpload} />
    </SubSection>
  );
};
