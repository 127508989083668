import { Button, ISelectData } from "@inceptionbg/ui-components";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { FarmContext } from "../../../../AppContent";
import { Dialog } from "../../../../Elements/Dialog";
import { addLands } from "../../../../repos/LandRepo";
import { IEvidence } from "../../../../types/IFile";
import { ILandNew } from "../../../../types/ILand";
import { IMemberNew } from "../../../../types/IMember";
import { getMembersOptions } from "../../../../utils/LoadOptions";
import { SelectAsyncPaginate } from "../../../Inputs/SelectAsyncPaginate";
import { AddStatementDialog } from "../../Members/Statement/AddStatementDialog";
import { AddLandDialog } from "./AddLandDialog";
import { LandsTable } from "./Components/LandsTable";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  reloadLands: (callback?: () => void) => void;
}

export const AddMemberLandDialog: FC<Props> = ({
  isOpen,
  onClose,
  reloadLands,
}) => {
  const [memberObj, setMemberObj] = useState<ISelectData | null>(null);
  const [member, setMember] = useState<IMemberNew | null>(null);
  const [lands, setLands] = useState<ILandNew[]>([]);
  const [statements, setStatements] = useState<IEvidence[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState<"land" | "statement" | "">("");

  const { t } = useTranslation();
  const farmUuid = useContext(FarmContext).farm?.uuid;

  const handleClose = () => {
    setLands([]);
    setStatements([]);
    setDialogOpen("");
    setMember(null);
    setMemberObj(null);
    onClose();
  };

  const onSubmit = (setIsParrentLoading: (isLoading: boolean) => void) => {
    if (farmUuid) {
      setIsLoading(true);
      setIsParrentLoading(true);
      addLands(farmUuid, { lands, memberLandStatements: statements })
        .then(() => reloadLands(handleClose))
        .catch(() => {})
        .finally(() => {
          setIsLoading(false);
          setIsParrentLoading(false);
        });
    }
  };

  return (
    <>
      <Dialog
        isOpen={isOpen}
        onClose={handleClose}
        title={t(
          member
            ? t("AddMemberLandTitleName", {
                name: `${member.firstName} ${member.lastName}`,
              })
            : "AddMemberLandTitle"
        )}
        desc={!member ? t("AddMemberLandDesc") : undefined}
        descEl={member ? <p>{t(`AddMembersLandDesc`)}</p> : undefined}
        isLoading={isLoading}
        confirmButton={{
          label: t(member ? "LandsAddAll" : "Next"),
          onClick: () => {
            if (member) {
              setDialogOpen("statement");
            } else if (memberObj) {
              setDialogOpen("land");
              setTimeout(() => {
                setMember({
                  uuid: memberObj.value,
                  firstName: memberObj.firstName,
                  lastName: memberObj.lastName,
                  personalIdentityNumber: memberObj.personalIdentityNumber,
                });
              }, 200);
            }
          },
          keepOpen: true,
          disabled: member ? !lands.length : !memberObj,
        }}
        cancelButton={member ? undefined : {}}
        size="m"
      >
        {!member ? (
          <div>
            <SelectAsyncPaginate
              required
              value={memberObj || null}
              loadOptions={(
                search: string,
                loadedOptions: [],
                offset: { page: number }
              ) => getMembersOptions(search, loadedOptions, offset, farmUuid!)}
              onChange={(e) => setMemberObj(e || null)}
            />
          </div>
        ) : (
          <>
            <LandsTable lands={lands} setLands={setLands} />
            <div className="flex justify-right">
              <Button
                label={t("AddNewLand")}
                size="s"
                primary
                onClick={() => setDialogOpen("land")}
              />
            </div>
          </>
        )}
      </Dialog>
      {memberObj && (
        <AddLandDialog
          isOpen={isOpen && dialogOpen === "land"}
          onClose={() => setDialogOpen("")}
          reloadLands={reloadLands}
          memberLand={{
            memberUuid: memberObj.value,
            memberName: memberObj.label,
            setLands,
          }}
        />
      )}
      {member && (
        <AddStatementDialog
          isOpen={isOpen && dialogOpen === "statement"}
          onClose={() => setDialogOpen("")}
          memberUuid={member.uuid!}
          memberPIN={member.personalIdentityNumber!}
          category="MEMBER_LAND_STATEMENT"
          bulk={{ statements, setStatements, onSubmit }}
        />
      )}
    </>
  );
};
