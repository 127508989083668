import {
  Button,
  DateInput,
  deleteProps,
  formatDate,
  TextInput,
} from "@inceptionbg/ui-components";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IInputComponentProps } from "../../../Elements/Input";
import { Form } from "../../Wrapper/FormWrapper";
import { ILand, ILandNew } from "../../../types/ILand";
import {
  calculateSurfaceFromObj,
  convertSurfaceToObj,
} from "../../../utils/CalculateUtils";
import { deleteLand, editLand } from "../../../repos/LandRepo";
import { SurfaceValue } from "../../Inputs/Surface/SurfaceValue";
import { SurfaceInput } from "../../Inputs/Surface/SurfaceInput";
import { EditLandEvidenceDialog } from "../Land/EditLandEvidenceDialog";
import { Dialog } from "../../../Elements/Dialog";
import { ModeContext } from "../../../Pages/RPGPage";
import { inputPattern } from "../../../utils/InputPatternValidation";
import { FarmContext } from "../../../AppContent";
import { ReplaceLandDialog } from "../Land/ReplaceLandDialog";
import { PartLandEvidenceDialog } from "../Land/PartLandEvidenceDialog";
import { setIsUserProcessing } from "../../../repos/FarmRepo";

interface Props {
  land: ILand;
  newForm?: {
    onCancel: () => void;
  };
  reloadLands: (callback?: () => void) => void;
  reloadLandsSum?: () => void;
  membersLand?: boolean;
  approvalUuid?: string;
}

export const LandForm: FC<Props> = ({
  land,
  newForm,
  reloadLands,
  reloadLandsSum,
  membersLand,
  approvalUuid,
}) => {
  const [formData, setFormData] = useState<ILandNew>({});
  const [edit, setEdit] = useState(false);
  const [evidenceOpen, setEvidenceOpen] = useState(false);
  const [isReplaceLandOpen, setIsReplaceLandOpen] = useState(false);
  const [landToDeleteUuid, setLandToDeleteUuid] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenLandPartDialog, setIsOpenLandPartDialog] = useState(false);
  const [isInApproval, setIsInApproval] = useState(
    !land.approved && !!land.approval
  );

  const { t } = useTranslation();
  const { farm, isFarmInApproval } = useContext(FarmContext);
  const isRenew = useContext(ModeContext) === "RENEW";

  const surfaceObj = convertSurfaceToObj(land.surface);
  const usedSurfaceObj = convertSurfaceToObj(land.usedSurface);

  const disableFormEdit =
    isFarmInApproval ||
    land.approval?.adminProcessing === true ||
    land.approval?.approvalStatus === "DECISION_SEND";

  const isWaitingComplement =
    land.approval?.approvalStatus === "WAITING_COMPLEMENT" ||
    farm?.registrationApproval?.approvalStatus === "WAITING_COMPLEMENT";

  // eslint-disable-next-line
  useEffect(() => setFormData({ ...land, surfaceObj, usedSurfaceObj }), [land]);

  const landInfo: IInputComponentProps[] = [
    {
      label: t("LandName"),
      value: land.nickname,
      input: (
        <TextInput
          value={formData.nickname}
          setValue={(e) => setFormData({ ...formData, nickname: e })}
          inputProps={{ pattern: inputPattern.maxChar255 }}
        />
      ),
    },
    {
      label: t("CadastreMunicipality"),
      value: land.cadastreMunicipality.name,
      readOnly: true,
    },
    {
      label: t("CadastreNumber"),
      value: land.cadastreNumber,
      readOnly: true,
    },
    {
      label: t("PartLand"),
      valueElement: (
        <Button
          label={t("ShowLandPartEvidence")}
          outlined
          size="s"
          onClick={() => setIsOpenLandPartDialog(true)}
        />
      ),
      hidden: formData.usageBasis === "CONSOLIDATION",
    },
    {
      label: t("Surface"),
      valueElement: <SurfaceValue valueObj={surfaceObj} />,
      readOnly: true,
    },
    {
      label: t("UsageBasis"),
      value: land.usageBasis
        ? t(land.usageBasis) +
          (land.member
            ? ` (${land.member.firstName} ${land.member.lastName})`
            : "")
        : "/",
      readOnly: true,
      // hidden: formData.usageBasis === "LEASE",
    },
    {
      label: t("UsageBasisUPZ"),
      value: land.upzBase?.name || "/",
      readOnly: true,
      hidden: formData.usageBasis !== "UPZ",
    },
    {
      label: t("LeaseEndDate"),
      value: formatDate(land.leaseEndDate),
      input: (
        <DateInput
          required
          date={formData.leaseEndDate}
          setDate={(e) => setFormData({ ...formData, leaseEndDate: e })}
          error={!formData.leaseEndDate}
        />
      ),
      hidden:
        formData.usageBasis !== "LEASE" &&
        formData.usageBasis !== "LEASE_CONSOLIDATION",
      alert: {
        text: t("RequiredFieldError"),
        type: "error",
        active: !formData.leaseEndDate,
      },
    },
    {
      label: t("UsedSurface"),
      valueElement: <SurfaceValue valueObj={usedSurfaceObj} />,
      readOnly: !!formData.usedSurface,
      input: (
        <SurfaceInput
          formData={formData}
          setFormData={setFormData}
          value="usedSurfaceObj"
          required
          error={
            !!formData.usedSurfaceObj &&
            !calculateSurfaceFromObj(formData.usedSurfaceObj)
          }
        />
      ),
      alert: {
        text: t("RequiredFieldError"),
        type: "error",
        active:
          !!formData.usedSurfaceObj &&
          !calculateSurfaceFromObj(formData.usedSurfaceObj),
      },
    },
    {
      label: t("LeaseType"),
      value: formData.leaseType
        ? `${formData.leaseType.code} - ${formData.leaseType.name}`
        : "/",
      hidden:
        formData.usageBasis !== "LEASE" &&
        formData.usageBasis !== "LEASE_CONSOLIDATION",
    },
    {
      label: t("LessorIdentificationNumber"),
      value: formData.lessorIdentification,
      input: (
        <TextInput
          required
          value={formData.lessorIdentification}
          setValue={(e) =>
            setFormData({ ...formData, lessorIdentification: e })
          }
          inputProps={{
            pattern: inputPattern.registrationOrPersonalIdentityNumber,
          }}
          error={!formData.lessorIdentification}
        />
      ),
      hidden:
        formData.usageBasis !== "LEASE" &&
        formData.usageBasis !== "LEASE_CONSOLIDATION",
      alert: {
        text: t("RequiredFieldError"),
        type: "error",
        active: !formData.lessorIdentification,
      },
    },
  ];

  const onSubmit = () => {
    setIsLoading(true);
    const data: ILandNew = deleteProps(
      {
        ...formData,
        surface: formData.surfaceObj
          ? calculateSurfaceFromObj(formData.surfaceObj)
          : formData.surface,
        usedSurface: formData.usedSurfaceObj
          ? calculateSurfaceFromObj(formData.usedSurfaceObj)
          : formData.usedSurface,
      },
      ["surfaceObj", "usedSurfaceObj"]
    );

    if (!!data.uuid) {
      editLand(data.uuid, {
        usedSurface: data.usedSurface,
        nickname: data.nickname,
        lessorIdentification: data.lessorIdentification,
        leaseEndDate: data.leaseEndDate,
      })
        .then(() => {
          if (!!reloadLands) {
            reloadLands(() => {
              setIsLoading(false);
              setEdit(false);
              newForm && newForm.onCancel();
            });
          } else {
            setIsLoading(false);
            setEdit(false);
          }
        })
        .then(() => {
          if (!!land?.approval?.uuid)
            setIsUserProcessing(land.approval.uuid, false)
              .then(() => setIsInApproval(true))
              .catch(() => {});
        })
        .catch(() => setIsLoading(false));
    }
  };

  const onDelete = () => {
    if (landToDeleteUuid) {
      setIsLoading(true);
      deleteLand(landToDeleteUuid)
        .then(() => {
          if (!!reloadLands) {
            reloadLands(() => {
              setIsLoading(false);
              setEdit(false);
              newForm && newForm.onCancel();
            });
          } else {
            setIsLoading(false);
            setEdit(false);
          }
          !!reloadLandsSum && reloadLandsSum();
        })
        .catch(() => setIsLoading(false));
    }
  };

  return (
    <>
      <Form
        data={landInfo}
        edit={!!newForm || edit}
        setEdit={setEdit}
        submitButton={{
          editLabel: t(!!newForm || edit ? "Confirm" : "LandEdit"),
          onSubmit: () => onSubmit(),
          hidden: isWaitingComplement || isInApproval || disableFormEdit,
        }}
        otherButtons={[
          {
            label: t("DeleteLand"),
            onClick: () => setLandToDeleteUuid(formData.uuid || ""),
            hidden:
              (!edit &&
                (farm?.status === "PASSIVE" ||
                  farm?.registrationApproval?.approvalStatus !==
                    "WAITING_COMPLEMENT")) ||
              isInApproval ||
              disableFormEdit,
            outlined: true,
            className: "error",
          },
          {
            label: t("Attachments"),
            primary: true,
            onClick: () => setEvidenceOpen(true),
            hidden:
              land.usageBasis === "CADASTRE_OWNERSHIP" ||
              edit ||
              isRenew ||
              isWaitingComplement ||
              isInApproval ||
              disableFormEdit,
          },
          {
            label: t("ReplaceLand"),
            primary: true,
            onClick: () => setIsReplaceLandOpen(true),
            hidden: !isWaitingComplement || isInApproval || disableFormEdit,
          },
          {
            label: t("StartChanges"),
            onClick: () =>
              setIsUserProcessing(land?.approval?.uuid!, true)
                .then(() => setIsInApproval(false))
                .catch(() => {}),
            primary: true,
            hidden: !land?.approval?.uuid || !isInApproval || disableFormEdit,
          },
        ]}
        buttonSize={membersLand ? "xs" : "s"}
        onCancel={!!newForm ? newForm.onCancel : undefined}
        onResetForm={() => setFormData({ ...land, surfaceObj, usedSurfaceObj })}
        isLoading={isLoading}
      />
      {isWaitingComplement && (
        <ReplaceLandDialog
          isOpen={isReplaceLandOpen}
          onClose={() => setIsReplaceLandOpen(false)}
          land={land}
          reloadLands={reloadLands}
        />
      )}
      {!isRenew && (
        <>
          <EditLandEvidenceDialog
            isOpen={evidenceOpen}
            onClose={() => setEvidenceOpen(false)}
            landUuid={land.uuid}
            approvalUuid={approvalUuid}
            reloadLands={reloadLands}
          />
          <Dialog
            isOpen={!!landToDeleteUuid}
            onClose={() => setLandToDeleteUuid("")}
            title={t("DeleteLand")}
            desc={t("DeleteLandDesc")}
            isLoading={isLoading}
            confirmButton={{ label: t("Confirm"), onClick: onDelete }}
            cancelButton={{ onClick: () => setLandToDeleteUuid("") }}
            error
          />
        </>
      )}
      {isOpenLandPartDialog && (
        <PartLandEvidenceDialog
          land={land}
          isOpen={isOpenLandPartDialog}
          onClose={() => setIsOpenLandPartDialog(false)}
        />
      )}
    </>
  );
};
