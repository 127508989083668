import { FC, useCallback, useContext, useEffect, useState } from "react";
import { FarmContext } from "../../../../../AppContent";
import { Loader } from "../../../../../Elements/Loader";
import {
  createEvidenceFromFile,
  getEvidences,
} from "../../../../../repos/FileRepo";
import {
  IEvidence,
  IEvidenceCategory,
  IEvidenceFilters,
} from "../../../../../types/IFile";
import { FilesDropzone } from "../../../../Files/Dropzone/FilesDropzone";
import { EvidencesList } from "../../../../Files/EvidencesList";
import { deleteEmptyProps } from "@inceptionbg/ui-components";

interface Props {
  memberUuid: string;
  category: IEvidenceCategory;
  approvalUuid?: string;
}
export const StatementEvidence: FC<Props> = ({
  memberUuid,
  category,
  approvalUuid,
}) => {
  const [evidences, setEvidences] = useState<IEvidence[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const farmUuid = useContext(FarmContext).farm?.uuid;
  const filters: IEvidenceFilters = deleteEmptyProps({
    member: { uuid: memberUuid },
    approval: approvalUuid ? { uuid: approvalUuid } : undefined,
  });

  const handleGetEvidences = useCallback(() => {
    if (farmUuid) {
      setIsLoading(true);
      getEvidences(farmUuid, category, { memberUuid, approvalUuid })
        .then((e) => setEvidences(e.evidences))
        .catch(() => {})
        .finally(() => setIsLoading(false));
    }
  }, [farmUuid, category, memberUuid, approvalUuid]);

  useEffect(handleGetEvidences, [handleGetEvidences]);

  const onSubmit = (data: File[]) => {
    if (farmUuid) {
      setIsLoading(true);
      data.forEach((file, i) => {
        createEvidenceFromFile(file, farmUuid, category, filters)
          .catch(() => {})
          .finally(() => {
            if (i === data.length - 1) {
              setIsLoading(false);
              handleGetEvidences();
            }
          });
      });
    }
  };

  return (
    <Loader isLoading={isLoading}>
      <div className="flex column gap-5">
        {!!evidences.length && (
          <EvidencesList
            evidences={evidences}
            reloadEvidences={handleGetEvidences}
          />
        )}
        <FilesDropzone
          onSubmit={onSubmit}
          category={category}
          reloadEvidences={handleGetEvidences}
          filters={filters}
        />
      </div>
    </Loader>
  );
};
