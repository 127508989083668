import { Button, formatDate, moment } from "@inceptionbg/ui-components";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { FarmContext } from "../../../AppContent";
import { InfoBox } from "../../../Elements/Alert/InfoBox";
import { Loader } from "../../../Elements/Loader";
import { renewFarm } from "../../../repos/FarmRepo";
import { Section } from "../../Section";
import { ModeContext } from "../../../Pages/RPGPage";
import { RenewalConditionsTable } from "./RenewalConditionsTable";

export const RenewSection: FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const { farm, reloadFarm, renewalConditions } = useContext(FarmContext);
  const mode = useContext(ModeContext);

  const alreadyRenewed = !!(
    farm?.renewalDate &&
    moment(farm.renewalDate).year() === new Date().getFullYear()
  );

  const onSubmit = () => {
    if (farm) {
      setIsLoading(true);
      renewFarm(farm.uuid)
        .then(() => !!reloadFarm && reloadFarm())
        .catch(() => {})
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <Section
      id="renew"
      title={t(
        mode === "EDIT"
          ? "RenewTitleOrdered"
          : alreadyRenewed
          ? "RenewTitle"
          : "Renew",
        {
          number: farm?.registrationNumber,
        }
      )}
    >
      {alreadyRenewed ? (
        <InfoBox
          title={t("AlreadyRenewedTitle")}
          desc={t("AlreadyRenewedDesc", {
            date: formatDate(farm?.renewalDate),
          })}
        />
      ) : (
        <Loader isLoading={isLoading}>
          <div className="flex column gap-3">
            {renewalConditions && (
              <RenewalConditionsTable renewalConditions={renewalConditions} />
            )}
            <div className="info-box light">
              <h3 className="mb-1">{t("RenewInfoTitle")}</h3>
              <p>{t("RenewInfoDesc")}</p>
              <ol className="pl-4 flex column gap-2">
                <li>{t("RenewInfoDesc1")}</li>
                <li>{t("RenewInfoDesc2")}</li>
                <li>{t("RenewInfoDesc3")}</li>
                <li>{t("RenewInfoDesc4")}</li>
                <li>{t("RenewInfoDesc5")}</li>
              </ol>
            </div>
            <div className="flex justify-right">
              <Button
                disabled={!renewalConditions?.readyForRenewal}
                label={t("RenewData")}
                primary
                size="l"
                onClick={onSubmit}
              />
            </div>
          </div>
        </Loader>
      )}
    </Section>
  );
};
