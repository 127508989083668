import { axiosInstance } from "../App";
import { IMemberNew } from "../types/IMember";

export const getMembers = (farmUuid: string) =>
  axiosInstance
    .get(`/farm/${farmUuid}/members`)
    .then(({ data }) => ({ members: data.members as IMemberNew[] }));

export const addMember = (farmUuid: string, data: IMemberNew) =>
  axiosInstance.post(`/farm/${farmUuid}/member`, data);

export const editMember = (data: IMemberNew) =>
  axiosInstance.patch(`/member/${data.uuid}`, data);

export const deleteMember = (memberUuid: string) =>
  axiosInstance.delete(`/member/${memberUuid}`);
