import {
  DateInput,
  deleteProps,
  formatDate,
  TextInput,
} from "@inceptionbg/ui-components";
import { TFunction } from "i18next";
import { Dispatch, SetStateAction } from "react";
import { IInputComponentProps } from "../../../Elements/Input";
import { IHolderNew } from "../../../types/IHolder";
import { genderTypes } from "../../../types/IMember";
import { inputPattern } from "../../../utils/InputPatternValidation";
import {
  getCountriesOptions,
  getEducationOptions,
  getWorkActivityOptions,
} from "../../../utils/LoadOptions";
import { Select } from "../../Inputs/Select";
import { SelectAsyncPaginate } from "../../Inputs/SelectAsyncPaginate";

export const getPersonalInfoData: (props: {
  formData: IHolderNew;
  setFormData: Dispatch<SetStateAction<IHolderNew>>;
  t: TFunction;
  edit: boolean;
  isHolder?: boolean;
  isMember?: boolean;
  isNewForm?: boolean;
  displayPIN?: boolean;
  setDisplayPIN?: Dispatch<SetStateAction<boolean>>;
}) => IInputComponentProps[] = ({
  formData,
  setFormData,
  t,
  edit,
  isHolder,
  isMember,
  isNewForm,
  displayPIN,
  setDisplayPIN,
}) => [
  {
    label: t("PersonalIdentityNumber"),
    value: displayPIN ? formData.personalIdentityNumber : "",
    valueElement: !displayPIN ? (
      <p
        className="link"
        onClick={() => !!setDisplayPIN && setDisplayPIN(true)}
      >
        {t("DisplayPersonalIdentityNumber")}
      </p>
    ) : undefined,
    input: (
      <TextInput
        required
        value={formData.personalIdentityNumber}
        setValue={(e) =>
          setFormData({ ...formData, personalIdentityNumber: e })
        }
        inputProps={{ pattern: inputPattern.personalIdentityNumber }}
        error={!isNewForm && !formData.personalIdentityNumber}
      />
    ),
    alert: {
      text: t("RequiredFieldError"),
      type: "error",
      active: !isNewForm && !formData.personalIdentityNumber,
    },
    readOnly: isHolder || (isMember && edit),
  },
  {
    label: t("FirstName"),
    value: formData.firstName,
    input: (
      <TextInput
        required
        value={formData.firstName}
        setValue={(e) => setFormData({ ...formData, firstName: e })}
        error={!isNewForm && !formData.firstName}
      />
    ),
    alert: {
      text: t("RequiredFieldError"),
      type: "error",
      active: !isNewForm && !formData.firstName,
    },
    readOnly: isHolder || (isMember && edit),
  },
  {
    label: t("LastName"),
    value: formData.lastName,
    input: (
      <TextInput
        required
        value={formData.lastName}
        setValue={(e) => setFormData({ ...formData, lastName: e })}
        error={!isNewForm && !formData.lastName}
      />
    ),
    readOnly: isHolder || (isMember && edit),
    alert: {
      text: t("RequiredFieldError"),
      type: "error",
      active: !isNewForm && !formData.lastName,
    },
  },
  {
    label: t("BirthDate"),
    value: formatDate(formData.birthDate),
    input: (
      <DateInput
        required
        date={formData.birthDate}
        setDate={(e) => setFormData({ ...formData, birthDate: e })}
        error={!isNewForm && !formData.birthDate}
      />
    ),
    alert: {
      text: t("RequiredFieldError"),
      type: "error",
      active: !isNewForm && !formData.birthDate,
    },
  },
  {
    label: t("BirthPlace"),
    value: formData.birthPlace,
    input: (
      <TextInput
        required
        value={formData.birthPlace}
        setValue={(e) => setFormData({ ...formData, birthPlace: e })}
        error={!isNewForm && !formData.birthPlace}
      />
    ),

    alert: {
      text: t("RequiredFieldError"),
      type: "error",
      active: !isNewForm && !formData.birthPlace,
    },
  },
  {
    label: t("BirthCountry"),
    value: formData.birthCountry?.name,
    input: (
      <SelectAsyncPaginate
        required
        value={
          formData.birthCountry
            ? {
                label: formData.birthCountry.name,
                value: formData.birthCountry.code,
              }
            : null
        }
        loadOptions={getCountriesOptions}
        onChange={(e) =>
          setFormData(
            e
              ? {
                  ...formData,
                  birthCountry: { name: e.label, code: e.value },
                }
              : deleteProps(formData, ["birthCountry"])
          )
        }
        error={!isNewForm && !formData.birthCountry}
      />
    ),
    alert: {
      text: t("RequiredFieldError"),
      type: "error",
      active: !isNewForm && !formData.birthCountry,
    },
  },
  {
    label: t("Gender"),
    value: formData.gender ? t(formData.gender) : "/",
    input: (
      <Select
        required
        value={
          formData.gender
            ? { label: t(formData.gender), value: formData.gender }
            : null
        }
        options={genderTypes.map((e) => ({ label: t(e), value: e }))}
        onChange={(e) => setFormData({ ...formData, gender: e.value })}
        error={!isNewForm && !formData.gender}
      />
    ),
    alert: {
      text: t("RequiredFieldError"),
      type: "error",
      active: !isNewForm && !formData.gender,
    },
  },
  {
    label: t("WorkActivity"),
    value: formData.workActivity?.name || "/",
    input: (
      <SelectAsyncPaginate
        required
        value={
          formData.workActivity
            ? {
                label: formData.workActivity.name,
                value: formData.workActivity.uuid,
              }
            : null
        }
        loadOptions={getWorkActivityOptions}
        onChange={(e) =>
          setFormData(
            e
              ? {
                  ...formData,
                  workActivity: { name: e.label, uuid: e.value },
                }
              : deleteProps(formData, ["workActivity"])
          )
        }
        error={!isNewForm && !formData.workActivity}
      />
    ),
    alert: {
      text: t("RequiredFieldError"),
      type: "error",
      active: !isNewForm && !formData.workActivity,
    },
  },
  {
    label: t("Education"),
    value: formData.education?.name || "/",
    input: (
      <SelectAsyncPaginate
        required
        value={
          formData.education
            ? {
                label: formData.education.name,
                value: formData.education.uuid,
              }
            : null
        }
        loadOptions={getEducationOptions}
        onChange={(e) =>
          setFormData(
            e
              ? {
                  ...formData,
                  education: { name: e.label, uuid: e.value },
                }
              : deleteProps(formData, ["education"])
          )
        }
        error={!isNewForm && !formData.education}
      />
    ),
    alert: {
      text: t("RequiredFieldError"),
      type: "error",
      active: !isNewForm && !formData.education,
    },
    hidden: !isHolder,
  },
  {
    label: t("Status"),
    value: t(formData.temporary ? "Temporary" : "Permanently"),
    hidden:
      !isHolder ||
      typeof formData.temporary !== "boolean" ||
      formData.type === "legalEntity",
  },
];
