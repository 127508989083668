import { ITableColumn, ITableDataItem } from "@inceptionbg/ui-components";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { RecapitulationTable } from "./RecapitulationTable";
import { IAnimalByGroup, IAnimalCountTotals } from "../../types/IAnimal";

interface Props {
  animalsByGroups: IAnimalByGroup[];
  totals?: IAnimalCountTotals;
}

export const AnimalsRecapitulationTable: FC<Props> = ({
  animalsByGroups,
  totals,
}) => {
  const { t } = useTranslation();

  const columns: ITableColumn[] = [
    { id: "group", label: t("AnimalGroup") },
    { id: "animalCount", label: t("EnrolledInUPV"), align: "right" },
    { id: "animalCountOrganic", label: t("OrganicFarming"), align: "right" },
    {
      id: "animalCountConventional",
      label: t("ConventionalFarming"),
      align: "right",
    },
  ];

  const data: ITableDataItem[] = animalsByGroups.map((e) => {
    if (e.id === "CUSTOM") {
      return {
        uuid: e.id,
        cells: {
          group: { value: t(e.id) },
          animalCount: { value: 0 },
          animalCountOrganic: {
            value: t(
              e.animals.find((animal) => animal.productionType === "ORGANIC")
                ? "Yes"
                : "No"
            ),
          },
          animalCountConventional: {
            value: t(
              e.animals.find(
                (animal) => animal.productionType === "CONVENTIONAL"
              )
                ? "Yes"
                : "No"
            ),
          },
        },
      };
    } else {
      let animalCountSum = 0;
      let animalCountOrganicSum = 0;
      let animalCountConventionalSum = 0;

      e.animals.forEach((animal) => {
        animalCountSum += +animal.animalCount;
        animalCountOrganicSum += animal.animalCountOrganic
          ? +animal.animalCountOrganic
          : 0;
        animalCountConventionalSum += animal.animalCountConventional
          ? +animal.animalCountConventional
          : 0;
      });

      return {
        uuid: e.id,
        cells: {
          group: { value: t(e.id) },
          animalCount: { value: animalCountSum },
          animalCountOrganic: { value: animalCountOrganicSum },
          animalCountConventional: { value: animalCountConventionalSum },
        },
      };
    }
  });

  const totalsData: ITableDataItem[] = !!totals
    ? [
        {
          uuid: "totalsData",
          cells: {
            group: { value: t("TotalAnimalsCount") },
            animalCount: { value: totals.animalCountTotal || 0 },
            animalCountOrganic: { value: totals.animalCountOrganicTotal || 0 },
            animalCountConventional: {
              value: totals.animalCountConventionalConTotal || 0,
            },
          },
        },
      ]
    : [];

  return (
    <RecapitulationTable
      title={t("AnimalsRecapitulationTitle")}
      tableColumns={columns}
      tableData={data}
      totalsData={totalsData}
    />
  );
};
