import { axiosInstance } from "../App";
import { IHolderNew } from "../types/IHolder";
import {
  IFarm,
  IFarmBasic,
  IBankAccount,
  IHeadOfficeNew,
  ILegalRepresentativeNew,
  IFarmNew,
  IRenewalCondition,
} from "../types/IFarm";
import { IUserInfo } from "../types/IUser";
import { deleteProps, ISimpleObject } from "@inceptionbg/ui-components";
import { IEvidence } from "../types/IFile";

export const checkFarm = (regNumber: string) =>
  axiosInstance
    .get(`/farm/${regNumber}/exists`, { headers: { public: true } })
    .then(({ data }) => data as IFarm);

export const updateFarm = (farmUuid: string, data: IFarmNew) =>
  axiosInstance.patch(`/farm/${farmUuid}`, data);

export const getUserInfo = () =>
  axiosInstance
    .get("/user/info")
    .then(({ data }) => ({ ...data, isLoaded: true } as IUserInfo));

export const createFarm = (data: IFarmNew) =>
  axiosInstance
    .post("/farm", data, {
      headers: { noToast: true },
    })
    .then(({ data }) => data.identifier as string);

export const getFarmList = () =>
  axiosInstance.get("/authorizations").then(({ data }) => data as IFarmBasic[]);

export const checkFarmAuth = (farmUuid: string) =>
  axiosInstance
    .get(`/authorization/${farmUuid}`)
    .then(({ data }) => data as boolean);

export const getFarmByUuid = (farmUuid: string) =>
  axiosInstance.get(`/farm/${farmUuid}`).then(({ data }) => data as IFarm);

export const getFarmByRegNum = (regNum: string, atDay: any) =>
  axiosInstance
    .get(`/farm/${regNum}/at-day`, { params: { atDay } })
    .then(({ data }) => data as IFarm);

export const setTempHolder = (
  regNumber: string,
  data: {
    bankAccount: { accountNumber: string };
    workActivity?: ISimpleObject;
    evidences?: IEvidence[];
    education: { uuid: string };
    birthPlace: string;
  }
) =>
  axiosInstance.put(`/farm/${regNumber}/holder-temporarly`, {
    ...data,
    type: "physicalPerson",
  });

export const changeLegalRepresentative = (
  regNumber: string,
  evidences?: IEvidence[]
) =>
  axiosInstance.put(`/farm/${regNumber}/change-legal-representative`, {
    evidences,
    type: "legalEntity",
  });

export const changeHolder = (
  regNumber: string,
  data: {
    member: { uuid: string };
    bankAccount: { accountNumber: string };
    workActivity?: ISimpleObject;
    education: { uuid: string };
    evidences?: IEvidence[];
  }
) =>
  axiosInstance.put(`/farm/${regNumber}/holder/member`, {
    ...data,
    type: "physicalPerson",
  });

export const updateHolder = (farmUuid: string, data: IHolderNew) =>
  axiosInstance.put(`/farm/${farmUuid}/holder`, data);

export const updateHeadOffice = (farmUuid: string, data: IHeadOfficeNew) =>
  axiosInstance.put(
    `/farm/${farmUuid}/head-office`,
    deleteProps(data, ["approved"])
  );

export const updateBankAccount = (farmUuid: string, data: IBankAccount) =>
  axiosInstance.put(`/farm/${farmUuid}/bank-account`, {
    accountNumber: data.accountNumber,
    evidences: data.evidences,
    referenceNumber: data.referenceNumber,
  } as IBankAccount);

export const updateBankAccountRefNumber = (data: IBankAccount) =>
  axiosInstance.patch(`/farm/bank-account`, data as IBankAccount);

export const renewFarm = (farmUuid: string) =>
  axiosInstance.post(`/farm/${farmUuid}/renewal`);

export const registerFarm = (farmUuid: string, data: { status: string }) =>
  axiosInstance.patch(`/farm/${farmUuid}`, data);

export const updateLegalRepresentative = (
  farmUuid: string,
  data: ILegalRepresentativeNew
) => axiosInstance.put(`/farm/${farmUuid}/legal-representative`, data);

export const getRenewalConditions = (farmUuid: string) =>
  axiosInstance
    .get(`/farm/${farmUuid}/renewal-condition`)
    .then(({ data }) => data as IRenewalCondition);

export const farmHasEDelivery = (farmUuid: string) =>
  axiosInstance
    .get(`/farm/${farmUuid}/e-delivery`)
    .then(({ data }) => data as boolean);

export const updateRegistrationApprovalStatus = (
  approvalUuid: string,
  status: string
) => axiosInstance.patch(`/approval/${approvalUuid}/${status}`);

export const setPermanentHolder = (data: {
  farm: IFarmNew;
  permanent: boolean;
}) => axiosInstance.patch("/farm/holder/permanent", data);

export const setFarmRequest = (data: {
  registrationNumber: string;
  atDay: string;
  state: string;
}) =>
  axiosInstance.post("/nis/farm/request", data, {
    headers: {
      noToast: true,
    },
  });

export const setIsUserProcessing = (
  approvalUuid: string,
  userProcessing: boolean
) =>
  axiosInstance.patch(`/approval/${approvalUuid}/processing`, {
    userProcessing,
  });
