import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { BasicRequest } from "../Common/SubSections/BasicRequest";
import { IFile } from "../../../types/IFile";
import { IRequestNew } from "../../../types/IRequest";
import { saveGeneralRequest } from "../../../repos/GeneralRepo";
import { FilesUpload } from "../Common/SubSections/FilesUpload";
import { CustomRequestSection } from "../CustomRequestSection";
import { deleteProps } from "@inceptionbg/ui-components";

export const ComplaintSection: FC = () => {
  const [requestData, setRequestData] = useState<IRequestNew>({});
  const [storedFiles, setStoredFiles] = useState<IFile[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const onSubmit = () => {
    setIsLoading(true);
    saveGeneralRequest(
      deleteProps(
        {
          ...requestData,
          caseId: requestData.case?.caseId,
          storedFiles,
          deliveryType: "INBOX",
        },
        ["case"]
      )
    )
      .then(() => {
        setRequestData({});
        setStoredFiles([]);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  return (
    <CustomRequestSection
      sectionTitle={t("ComplaintPageTitle")}
      infoBoxTitle={t("ComplaintRequestTitle")}
      infoBoxDesc={t("ComplaintRequestDesc")}
      onSubmit={onSubmit}
      disableSubmit={
        !requestData.case || !requestData.requestType || !requestData.note
      }
    >
      <BasicRequest
        mode="COMPLAINT"
        data={requestData}
        setData={setRequestData}
        isLoading={isLoading}
      />
      <FilesUpload
        storedFiles={storedFiles}
        setStoredFiles={setStoredFiles}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    </CustomRequestSection>
  );
};
