import { FC } from "react";
import { ITableColumn, ITableDataItem } from "@inceptionbg/ui-components";

interface Props {
  title?: string;
  tableColumns: ITableColumn[];
  tableData: ITableDataItem[];
  totalsData?: ITableDataItem[];
  hideHeader?: boolean;
}

export const RecapitulationTable: FC<Props> = ({
  title,
  tableColumns,
  tableData,
  totalsData = [],
  hideHeader,
}) => {
  return (
    <div className="recapitulation-table-container">
      {title && <h2>{title}</h2>}
      <table className="recapitulation-table">
        {!hideHeader && <thead>
        <tr>
          {tableColumns
            .map((col, i) => (
              <th
                key={"head" + i}
                style={
                  col.width
                    ? { minWidth: col.width }
                    : undefined
                }>
                <div className="flex align-center">
                  <p className={`full-width text-${col.align || "left"}`}>
                    {col.label}
                  </p>
                </div>
              </th>
            ))}
        </tr>
        </thead>}
        <tbody>
        {tableData.map(row => (
          <tr key={row.uuid}>
            {tableColumns.map(col => <td key={col.id}>
              <div className="flex align-center">
                <p className={`${row.cells[col.id]?.className} full-width text-${col.align || "left"}`}>
                  {row.cells[col.id]?.value}
                </p>
              </div>
            </td>)}
          </tr>
        ))}
        <tr><td colSpan={tableColumns.length}></td></tr>
        </tbody>
        <tfoot>
        {totalsData.map(row => (
          <tr key={row.uuid}>
            {tableColumns.map(col => <td key={col.id}>
              <div className="flex align-center">
                <p className={`${row.cells[col.id]?.className} full-width text-${col.align || "left"}`}>
                  {row.cells[col.id]?.value}
                </p>
              </div>
            </td>)}
          </tr>
        ))}
        </tfoot>
      </table>
    </div>
  );
};
