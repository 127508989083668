import { deleteProps } from "@inceptionbg/ui-components";
import { TFunction } from "i18next";
import { IInputComponentProps } from "../../../../../Elements/Input";
import { getFuelTypeOptions } from "../../../../../utils/LoadOptions";
import { SelectAsyncPaginate } from "../../../../Inputs/SelectAsyncPaginate";
import { IInvoiceItem } from "../../../../../types/IInvoice";
import { NumberInput } from "../../../../Inputs/NumberInput";

export const getInvoiceItemData: (props: {
  formData: IInvoiceItem;
  setFormData?: (item: IInvoiceItem) => void;
  t: TFunction;
  edit: boolean;
  excludeFuelTypeUuids?: string[];
}) => IInputComponentProps[] = ({
  formData,
  setFormData,
  t,
  edit,
  excludeFuelTypeUuids,
}) => [
  {
    label: t("FuelType"),
    value: formData.fuelType?.name,
    input: setFormData && (
      <SelectAsyncPaginate
        required
        value={
          formData.fuelType
            ? {
                value: formData.fuelType.uuid,
                label: formData.fuelType.name,
              }
            : null
        }
        loadOptions={(
          search: string,
          loadedOptions: [],
          offset: { page: number }
        ) =>
          getFuelTypeOptions(search, loadedOptions, offset, {
            excludeFuelTypeUuids,
          })
        }
        onChange={(e) =>
          setFormData(
            e
              ? {
                  ...formData,
                  fuelType: { name: e.label, uuid: e.value },
                }
              : deleteProps(formData, ["fuelType"])
          )
        }
        refresh={[excludeFuelTypeUuids]}
      />
    ),
    edit,
  },
  {
    label: t("Liter"),
    value: `${formData.liter || 0}`,
    input: setFormData && (
      <NumberInput
        required
        placeholder="0"
        value={formData.liter}
        setValue={(e) => setFormData({ ...formData, liter: +e })}
      />
    ),
    edit,
  },
];
