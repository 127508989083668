import { Button, deleteProps, formatDate } from "@inceptionbg/ui-components";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FarmContext } from "../../../../AppContent";
import { IInputComponentProps, Input } from "../../../../Elements/Input";
import { Loader } from "../../../../Elements/Loader";
import { updateFarm } from "../../../../repos/FarmRepo";
import { getFarmOrganizationFormOptions } from "../../../../utils/LoadOptions";
import { SelectAsyncPaginate } from "../../../Inputs/SelectAsyncPaginate";
import { Checkbox } from "../../../../Elements/Checkbox";
import { IFarmNew } from "../../../../types/IFarm";

export const FarmInfo: FC = () => {
  const [holdingInfo, setHoldingInfo] = useState<IInputComponentProps[]>([]);
  const [formData, setFormData] = useState<IFarmNew>({});
  const [edit, setEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const { farm, reloadFarm } = useContext(FarmContext);

  useEffect(() => {
    if (
      farm?.holder.type === "physicalPerson" &&
      edit &&
      !!farm?.organizationForm.name
    ) {
      setFormData({
        organizationForm: farm.organizationForm,
      });
    } else if (farm?.holder.type === "legalEntity" && edit) {
      setFormData({
        agriculturalProduction: farm.agriculturalProduction,
        agriculturalProductsProcessing: farm.agriculturalProductsProcessing,
        nonAgriculturalProduction: farm.nonAgriculturalProduction,
      });
    }
  }, [farm, edit]);

  useEffect(() => {
    const holderType = farm?.holder.type;
    if (farm) {
      setHoldingInfo([
        {
          label: t("OrgNumberLong"),
          value: farm.registrationNumber,
          hidden: ["PENDING", "PENDING_APPROVAL"].includes(farm.status),
        },
        {
          label: t("StatusPG"),
          value: `${t(farm.status)} ${
            farm.passiveStatusReason && farm.status !== "ACTIVE"
              ? " - " + farm.passiveStatusReason?.name
              : ""
          }`,
        },
        {
          label: t("RegistrationDate"),
          value: formatDate(farm.registrationDate),
        },
        {
          label: t("RenewalDate"),
          value: formatDate(farm.renewalDate),
        },
        {
          label: t("ActiveStatusDays"),
          value: farm.activeStatusDays?.toString(),
          hidden: !farm.activeStatusDays,
        },
        {
          label: t("OrganizationalForm"),
          value: farm.organizationForm ? farm.organizationForm.name : "",
          input: (
            <SelectAsyncPaginate
              required
              value={{
                label: formData.organizationForm?.name || "/",
                value: formData.organizationForm?.uuid,
              }}
              loadOptions={(
                search: string,
                loadedOptions: [],
                offset: { page: number }
              ) =>
                getFarmOrganizationFormOptions(search, loadedOptions, offset, {
                  physicalPersonHolder: holderType === "physicalPerson",
                })
              }
              // onChange={setOrganizationForm}
              onChange={(e) =>
                setFormData(
                  e
                    ? {
                        ...formData,
                        organizationForm: {
                          name: e.label,
                          uuid: e.value,
                        },
                      }
                    : deleteProps(formData, ["organizationForm"])
                )
              }
            />
          ),
          edit: holderType === "physicalPerson" && edit,
        },
        {
          label: t("MembersCount"),
          value: `${farm.membersCount || "/"}`,
          hidden: holderType === "legalEntity",
        },
        {
          label: t("AgriculturalProduction"),
          value: t(!!farm.agriculturalProduction ? "Yes" : "No"),
          input: (
            <Checkbox
              selected={!!formData.agriculturalProduction}
              setSelected={(e) =>
                setFormData((data) => ({
                  ...data,
                  agriculturalProduction: e,
                }))
              }
            />
          ),
          edit,
          hidden: holderType === "physicalPerson",
        },
        {
          label: t("AgriculturalProductsProcessing"),
          value: t(!!farm.agriculturalProductsProcessing ? "Yes" : "No"),
          input: (
            <Checkbox
              selected={!!formData.agriculturalProductsProcessing}
              setSelected={(e) =>
                setFormData((data) => ({
                  ...data,
                  agriculturalProductsProcessing: e,
                }))
              }
            />
          ),
          edit,
          hidden: holderType === "physicalPerson",
        },
        {
          label: t("NonAgriculturalProduction"),
          value: t(!!farm.nonAgriculturalProduction ? "Yes" : "No"),
          input: (
            <Checkbox
              selected={!!formData.nonAgriculturalProduction}
              setSelected={(e) =>
                setFormData((data) => ({
                  ...data,
                  nonAgriculturalProduction: e,
                }))
              }
            />
          ),
          edit,
          hidden: holderType === "physicalPerson",
        },
      ]);
    }
  }, [farm, formData, edit, t]);

  const onSubmit = () => {
    if (farm?.uuid) {
      setIsLoading(true);
      updateFarm(farm.uuid, formData)
        .then(() => {
          reloadFarm(() => {
            setIsLoading(false);
            setEdit(false);
          });
        })
        .catch(() => setIsLoading(false));
    }
  };

  return (
    <Loader isLoading={isLoading}>
      <>
        {holdingInfo.map(
          (e) =>
            !e.hidden && <Input key={e.label} component={e} edit={e.edit} />
        )}
        <div className="flex pr-4 pt-3 justify-right gap-2">
          {edit ? (
            <>
              <Button
                label={t("Cancel")}
                outlined
                onClick={() => setEdit(false)}
                size="s"
              />
              <Button
                label={t("Confirm")}
                primary
                onClick={onSubmit}
                size="s"
              />
            </>
          ) : (
            <Button
              label={t("Edit")}
              outlined
              onClick={() => setEdit(true)}
              size="s"
            />
          )}
        </div>
      </>
    </Loader>
  );
};
