import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "../../../Elements/Input";
import { IInvoice } from "../../../types/IInvoice";
import { SubSection } from "../../SubSection";
import { InvoiceForm } from "./InvoiceForm";

interface Props {
  invoice: IInvoice;
  reloadInvoices: (callback?: () => void) => void;
}

export const Invoice: FC<Props> = ({ invoice, reloadInvoices }) => {
  const { t } = useTranslation();

  return (
    <SubSection
      title={`${invoice?.number}${
        invoice.used ? " - " + t("InvoiceUsed") : ""
      }`}
      expandable
      alerts={[
        {
          text: `${t("WaitingComplementText")}
                ${invoice?.approval?.statusComment}`,
          type: "error",
          number: invoice?.approval?.approvalNumber,
          hidden: invoice?.approval?.approvalStatus !== "WAITING_COMPLEMENT",
        },
        {
          text: t("InvoiceNotApprovedInfo"),
          type: "info",
          number: invoice?.approval?.approvalNumber,
          hidden:
            invoice?.approval?.approvalStatus === "APPROVED" ||
            invoice?.source === "PURS" ||
            invoice.approval?.approvalStatus === "WAITING_COMPLEMENT",
        },
        {
          text: t("InvoiceUsed"),
          type: "success",
          hidden: !invoice?.used,
        },
      ]}
      className="pt-0"
    >
      <InvoiceForm invoice={invoice} reloadInvoices={reloadInvoices} />

      <SubSection
        title={t("InvoiceItems")}
        expandable
        secondary
        className="pt-0"
      >
        {invoice.items.map((item) => (
          <Input
            key={item.uuid}
            component={{
              label: item.fuelType?.name || "",
              value: `${item.liter || "0"} l`,
            }}
          />
        ))}
      </SubSection>
    </SubSection>
  );
};
