import { FC, useContext, useState } from "react";
import logo from "../../assets/svg/eAgrarLogo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser } from "../../assets/icons/light/faCircleUser";
import { faAngleDown } from "../../assets/icons/light/faAngleDown";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../Context/UserContext";
import { FarmContext } from "../../AppContent";
import { ChangeLanguage } from "../ChangeLanguage";
import { Menu, MenuItem } from "@inceptionbg/ui-components";
import { Link, useLocation } from "react-router-dom";
import { logout } from "../../repos/AuthRepo";
import { helpFileUrl } from "../../utils/FileUtils";
import { INavItem } from "../../types/IBase";
import { MobileNav } from "./MobileNav";

export const Header: FC = () => {
  const [isUserOpen, setIsUserOpen] = useState(false);

  const { user } = useContext(UserContext);
  const { farm, changeFarm } = useContext(FarmContext);
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const navItems: INavItem[] = [
    { label: t("Home"), url: "/", hidden: !user },
    { label: t("Login"), url: "/", hidden: pathname !== "/pub-status" },
    { label: t("eEncouragement"), url: "https://epodsticaji.eagrar.gov.rs" },
    { label: t("eInbox"), url: "https://euprava.gov.rs" },
    { label: t("Help"), url: helpFileUrl, target: "_blank", hidden: !user },
    { label: t("RegisterSearch"), url: "/pub-status", hidden: !!user },
    /* { label: t("Contact"), url: "/" }, */
  ];

  return (
    <div className="header">
      <div className="top-content">
        {/* <p>{t("Settings")}</p> */}
        <ChangeLanguage />
      </div>
      <div className="bottom-content">
        <Link to="/">
          <img src={logo} alt="eAgrar" className="logo" />
        </Link>
        <div className="flex align-center gap-3">
          <div className="flex align-center gap-3 mobile-hidden">
            {navItems.map(
              (e) =>
                !e.hidden && (
                  <Link
                    key={e.label}
                    className="nav-item"
                    to={e.url}
                    target={e.target}
                  >
                    {e.label}
                  </Link>
                )
            )}
          </div>
          {user && (
            <>
              <Menu
                isOpen={isUserOpen}
                onClose={() => setIsUserOpen(false)}
                placementX="right"
                renderButton={(ref) => (
                  <div
                    ref={ref}
                    className="user ml-3"
                    onClick={() => setIsUserOpen(!isUserOpen)}
                  >
                    <FontAwesomeIcon
                      icon={faCircleUser}
                      className="icon-3 color-primary"
                    />
                    <div className="mobile-hidden">
                      <p>{`${user.firstName} ${user.lastName}`}</p>
                      <p>{`${t("OrgNumberShort")}: ${
                        farm?.registrationNumber &&
                        !["PENDING", "PENDING_APPROVAL"].includes(
                          farm.status
                        ) &&
                        !pathname.startsWith("/new")
                          ? farm.registrationNumber
                          : "/"
                      }`}</p>
                    </div>
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      className="mobile-hidden"
                    />
                  </div>
                )}
              >
                <MenuItem
                  label={t("ChangeFarm")}
                  onClick={() => {
                    changeFarm();
                    setIsUserOpen(false);
                  }}
                />
                <MenuItem label={t("LogOut")} onClick={() => logout()} />
              </Menu>
              <MobileNav navItems={navItems} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
