import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Section } from "../Section";
import { Button } from "@inceptionbg/ui-components";
import { InfoBox } from "../../Elements/Alert/InfoBox";

interface Props {
  sectionTitle: string;
  infoBoxTitle: string;
  infoBoxDesc: string;
  onSubmit: () => void;
  disableSubmit?: boolean;
  buttonLabel?: string;
  children: ReactNode;
}

export const CustomRequestSection: FC<Props> = ({
  sectionTitle,
  infoBoxTitle,
  infoBoxDesc,
  onSubmit,
  disableSubmit,
  buttonLabel,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <Section id="basic" title={sectionTitle}>
      <InfoBox title={infoBoxTitle} desc={infoBoxDesc} className="mb-4" light />
      {children}
      <div className="flex center mt-3">
        <Button
          label={buttonLabel || t("SubmitRequest")}
          primary
          onClick={onSubmit}
          disabled={!!disableSubmit}
        />
      </div>
    </Section>
  );
};
