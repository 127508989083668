import { clsx, FontAwesomeIcon } from '@inceptionbg/ui-components';
import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { faTimer } from '../../../../../assets/icons/regular/faTimer';
import { Input } from '../../../../../Elements/Input';
import { getEvidenceByUuid } from '../../../../../repos/FileRepo';
import { IEvidenceSignStatus } from '../../../../../types/IFile';

interface Props {
  memberPIN: string;
  evidenceUuid?: string;
  signStatus: IEvidenceSignStatus | '';
  setSignStatus: Dispatch<SetStateAction<IEvidenceSignStatus | ''>>;
}
export const StatementEID: FC<Props> = ({
  memberPIN,
  evidenceUuid,
  signStatus,
  setSignStatus,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (evidenceUuid) {
      const handleGetEvidence = (uuid: string) => {
        getEvidenceByUuid(uuid)
          .then(e => setSignStatus(e.signStatus || ''))
          .catch(() => {});
      };

      handleGetEvidence(evidenceUuid);
      const intervalId = setInterval(() => handleGetEvidence(evidenceUuid), 5000);
      return () => {
        clearInterval(intervalId!);
      };
    }
  }, [evidenceUuid, setSignStatus]);

  return (
    <>
      <Input
        component={{
          label: t('MemberPersonalIdentityNumber'),
          value: memberPIN,
        }}
      />
      <div className="evidence-status">
        {signStatus && (
          <div className={clsx('status', { signed: signStatus === 'SIGNED' })}>
            <FontAwesomeIcon className="icon-2" icon={faTimer} />
            <p>{t(`EvidenceSignStatus${signStatus}`)}</p>
          </div>
        )}
      </div>
    </>
  );
};
