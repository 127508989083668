import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { FarmContext } from "../../../AppContent";
import { Section } from "../../Section";
import { BankAccount } from "./SubSections/BankAccount";
import { ContactInfo } from "./SubSections/ContactInfo";
import { HeadOffice } from "./SubSections/HeadOffice";
import { PersonalInfoForm } from "../Common/PersonalInfoForm";
import { SubSection } from "../../SubSection";
import { LegalEntityInfo } from "./SubSections/LegalEntityInfo";
import { LegalRepresentative } from "./SubSections/LegalRepresentative";
import { FarmInfo } from "./SubSections/FarmInfo";

export const BasicInfoSection: FC = () => {
  const { t } = useTranslation();
  const { farm } = useContext(FarmContext);
  const holderType = farm?.holder.type;

  return (
    <Section id="basic" title={t("BasicInfoTitleOrdered")}>
      <div className="form-container">
        <FarmInfo />
        {farm && (
          <>
            {holderType === "physicalPerson" && (
              <SubSection
                title={t("ArgHolderInfoTitleOrdered")}
                alerts={[
                  {
                    text: t("RequiredFieldsMissing"),
                    type: "error",
                    hidden:
                      !!farm?.holder.personalIdentityNumber &&
                      !!farm?.holder.firstName &&
                      !!farm?.holder.lastName &&
                      !!farm?.holder.birthDate &&
                      !!farm?.holder.birthPlace &&
                      !!farm?.holder.birthCountry &&
                      !!farm?.holder.gender &&
                      !!farm?.holder.workActivity &&
                      !!farm?.holder.education,
                  },
                  {
                    text: t("HolderNotVerifiedInfo"),
                    type: "info",
                    number: farm.holder.approval?.approvalNumber,
                    hidden:
                      !!farm.holder.approved ||
                      farm.registrationStatus === "WAITING_COMPLEMENT",
                  },
                  {
                    text: farm?.holder.approval?.statusComment!,
                    type: "info",
                    hidden:
                      farm?.holder.approval?.approvalStatus !==
                      "WAITING_COMPLEMENT",
                  },
                ]}
              >
                <PersonalInfoForm isHolder member={farm.holder} />
              </SubSection>
            )}
            {holderType === "legalEntity" && <LegalEntityInfo />}
            <HeadOffice />
            <ContactInfo />
            <BankAccount />
            {holderType === "legalEntity" && <LegalRepresentative />}
          </>
        )}
      </div>
    </Section>
  );
};
