import clsx from "clsx";
import { FC, ReactNode } from "react";

interface Props {
  title: string;
  id?: string;
  className?: string;
  children: ReactNode;
}

export const Section: FC<Props> = ({ title, id, className, children }) => (
  <div className="page-section">
    <h1 id={id}>{title}</h1>
    <div className={clsx("content", className)}>{children}</div>
  </div>
);
