import {
  FontAwesomeIcon,
  ITableColumn,
  ITableDataItem,
  Table,
} from "@inceptionbg/ui-components";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { faXmark } from "../../../../../assets/icons/regular/faXmark";
import { Dialog } from "../../../../../Elements/Dialog";
import { ILandNew } from "../../../../../types/ILand";
import { convertSurfaceToObj } from "../../../../../utils/CalculateUtils";
import { SurfaceValue } from "../../../../Inputs/Surface/SurfaceValue";

interface Props {
  lands: ILandNew[];
  setLands: Dispatch<SetStateAction<ILandNew[]>>;
}

export const LandsTable: FC<Props> = ({ lands, setLands }) => {
  const [itemToDelete, setItemToDelete] = useState<number | null>(null);

  const { t } = useTranslation();

  const columns: ITableColumn[] = [
    { id: "municipality", label: t("LandMunicipality") },
    { id: "cadastreNumber", label: t("CadastreNumber") },
    { id: "surface", label: t("Surface") },
    { id: "usedSurface", label: t("UsedSurface") },
    { id: "actions", label: "", width: "100px" },
  ];

  const onDeleteLand = () => {
    if (itemToDelete !== null)
      setLands((e) => {
        let newList = [...e];
        newList.splice(itemToDelete, 1);
        return newList;
      });
  };

  const data: ITableDataItem[] = lands.map((e, i) => ({
    uuid: e.nickname!,
    cells: {
      municipality: {
        value: e.cadastreMunicipality?.name,
      },
      cadastreNumber: {
        value: e.cadastreNumber,
      },
      surface: {
        value: (
          <SurfaceValue valueObj={convertSurfaceToObj(e.surface)} simple />
        ),
      },
      usedSurface: {
        value: (
          <SurfaceValue valueObj={convertSurfaceToObj(e.usedSurface)} simple />
        ),
      },
      actions: {
        value: (
          <div
            className="clickable flex gap-2 align-center color-error"
            onClick={() => setItemToDelete(i)}
          >
            <FontAwesomeIcon icon={faXmark} />
            <p>{t("Delete")}</p>
          </div>
        ),
      },
    },
  }));
  return (
    <>
      <Table
        headerWrap
        data={data}
        columns={columns}
        hideFooter
        translations={{ noDataText: t("AddLandToContinue") }}
        customPagination={{ defaultLimit: 1000 }}
        className="table-5col"
      />
      <Dialog
        isOpen={itemToDelete !== null}
        onClose={() => setItemToDelete(null)}
        title={t("RemoveLand")}
        desc={t("RemoveLandDesc")}
        confirmButton={{ label: t("Confirm"), onClick: onDeleteLand }}
        cancelButton={{ onClick: () => setItemToDelete(null) }}
      />
    </>
  );
};
