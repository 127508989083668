import { FC } from "react";
import { useTranslation } from "react-i18next";
import { List } from "../../../../../Elements/List";
import { ILandUsageBasis } from "../../../../../types/ILand";

interface Props {
  currentUB?: ILandUsageBasis | "UPZ";
  onSelect: (type: string) => void;
}

export const AddLandTypeList: FC<Props> = ({ currentUB, onSelect }) => {
  const { t } = useTranslation();
  const list: ILandUsageBasis[] = [
    "CADASTRE_OWNERSHIP",
    "OWNERSHIP_OUT_CADASTRE",
    "CONSOLIDATION",
    "LEASE",
    "LEASE_LEGAL_ENTITY",
    "LEASE_CONSOLIDATION",
    "LEASE_LEGAL_ENTITY_CONSOLIDATION"
  ];

  return (
    <List
      listItems={list.map((item) => ({
        id: item,
        title: t(`Land${item}Title`),
        desc: t(`Land${item}Desc`),
        active: item === currentUB,
      }))}
      onClick={onSelect}
    />
  );
};
