import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Dialog } from "../../Elements/Dialog";
import { IConfirmDialogData } from "../../types/IBase";

interface Props extends IConfirmDialogData {
  isOpen: boolean;
  onClose: () => void;
}

export const ConfirmDialog: FC<Props> = ({
  isOpen,
  onClose,
  type,
  validation,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title={type ? t(`ConfirmTitle${type}`) : ""}
      desc={
        type ? t(`ConfirmDesc${type}${validation ? "Validation" : ""}`) : ""
      }
      confirmButton={{ okButton: true }}
      notMobileFullScreen
    />
  );
};
