import { Button } from "@inceptionbg/ui-components";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FarmContext } from "../../../AppContent";
import { ModeContext } from "../../../Pages/RPGPage";
import { getMembers } from "../../../repos/MembersRepo";
import { IMemberNew } from "../../../types/IMember";
import { Section } from "../../Section";
import { AddEditMemberDialog } from "./Add/AddEditMemberDialog";
import { Member } from "./Member";

interface Props {
  reloadLands: () => void;
}

export const MembersSection: FC<Props> = ({ reloadLands }) => {
  const [members, setMembers] = useState<IMemberNew[]>([]);
  const [addMember, setAddMember] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { farm } = useContext(FarmContext);
  const isRenew = useContext(ModeContext) === "RENEW";
  const { t } = useTranslation();

  const handleGetMembers = useCallback(
    (callback?: () => void) => {
      if (farm) {
        setIsLoading(true);
        getMembers(farm.uuid)
          .then((data) => {
            setMembers(data.members);
            callback && callback();
          })
          .catch(() => {})
          .finally(() => setIsLoading(false));
      }
    },
    [farm]
  );

  useEffect(handleGetMembers, [handleGetMembers]);

  return (
    <Section id="members" title={t("MembersTitleOrdered")}>
      <div className="form-container">
        {farm &&
          !isLoading &&
          members.map((member) => (
            <Member
              key={member.personalIdentityNumber}
              member={member}
              reloadMembers={handleGetMembers}
              reloadLands={reloadLands}
            />
          ))}
      </div>
      {!isRenew && (
        <>
          <div className="flex justify-right mt-3">
            <Button
              label={t("MemberAdd")}
              primary
              onClick={() => setAddMember(true)}
            />
          </div>
          <AddEditMemberDialog
            isOpen={addMember}
            onClose={() => setAddMember(false)}
            reloadMembers={handleGetMembers}
          />
        </>
      )}
    </Section>
  );
};
