import { Dispatch, FC, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog } from "../../Elements/Dialog";
import { Loader } from "../../Elements/Loader";
import { uploadFile } from "../../repos/FileRepo";
import { IFarmNew } from "../../types/IFarm";
import {
  evidenceSections,
  IEvidence,
  IEvidenceCategory,
} from "../../types/IFile";
import { FilesDropzone } from "./Dropzone/FilesDropzone";
import { EvidencesList } from "./EvidencesList";

interface Props {
  isOpen?: boolean;
  onClose?: () => void;
  category: IEvidenceCategory;
  evidences?: IEvidence[];
  setEvidences?: Dispatch<SetStateAction<IEvidence[]>>;
  setFarm?: Dispatch<SetStateAction<IFarmNew>>;
  onSubmit?: () => void;
}

export const EvidencesComponent: FC<Props> = ({
  isOpen,
  onClose,
  category,
  evidences,
  setEvidences,
  setFarm,
  onSubmit,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const onUpload = (data: File[]) => {
    setIsLoading(true);
    data.forEach((file, i) => {
      uploadFile(file)
        .then((e) => {
          if (!!setEvidences) {
            setEvidences((data) => [...data, { storedFile: e }]);
          } else if (!!setFarm) {
            setFarm((farm) => ({
              ...farm,
              [evidenceSections[category]!]: {
                ...farm[evidenceSections[category]!],
                evidences: [
                  ...(farm[evidenceSections[category]!]?.evidences || []),
                  { storedFile: e },
                ],
              },
            }));
          }
        })
        .catch(() => setIsLoading(false))
        .finally(() => {
          if (i === data.length - 1) {
            setIsLoading(false);
          }
        });
    });
  };

  const onDeleteFile = (index: number, callback: () => void) => {
    if (!evidences) return;

    let newList = [...evidences];
    newList.splice(index, 1);

    if (!!setEvidences) setEvidences(newList);
    else if (!!setFarm) {
      setFarm((farm) => ({
        ...farm,
        [evidenceSections[category]!]: {
          ...farm[evidenceSections[category]!],
          evidences: newList,
        },
      }));
    }
    callback();
  };

  return onClose ? (
    <Dialog
      isOpen={!!isOpen}
      onClose={() => onClose()}
      title={t(`EvidenceTitle${category}`)}
      desc={t(`EvidenceDesc${category}`)}
      confirmButton={{
        label: t("Confirm"),
        okButton: !onSubmit,
        onClick: onSubmit,
        disabled: evidences && !evidences?.length,
      }}
      cancelButton={{}}
      isLoading={isLoading}
      size="m"
    >
      {!!evidences?.length && (
        <EvidencesList evidences={evidences} onDeleteFile={onDeleteFile} />
      )}
      <FilesDropzone
        onSubmit={onUpload}
        category={category}
        setEvidences={setEvidences}
        setFarm={setFarm}
      />
    </Dialog>
  ) : (
    <Loader isLoading={isLoading}>
      <div className="flex column gap-5">
        {!!evidences?.length && (
          <EvidencesList evidences={evidences} onDeleteFile={onDeleteFile} />
        )}
        <FilesDropzone
          onSubmit={onUpload}
          category={category}
          setEvidences={setEvidences}
          setFarm={setFarm}
        />
      </div>
    </Loader>
  );
};
