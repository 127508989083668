import { ReactNode, createContext, useEffect, useState } from "react";
import { desktopBP, mobileBP } from "../utils/ResponsiveUtils";

export interface IAppContext {
  appUnavailable: {
    errorCode: string;
    resetError: () => void;
  } | null;
  screen: {
    isMobile: boolean;
    isDesktop: boolean;
    height: number;
    contentHeight: number;
  };
}

export const AppContext = createContext<IAppContext>({
  appUnavailable: null,
  screen: {
    isMobile: window.innerWidth <= mobileBP,
    isDesktop: window.innerWidth >= desktopBP,
    height: window.innerHeight,
    contentHeight: window.innerHeight,
  },
});

interface IProps {
  appUnavailable: IAppContext["appUnavailable"];
  children: ReactNode;
}

export const AppProvider = ({ appUnavailable, children }: IProps) => {
  const [screen, setScreen] = useState<IAppContext["screen"]>({
    isMobile: window.innerWidth <= mobileBP,
    isDesktop: window.innerWidth >= desktopBP,
    height: window.innerHeight,
    contentHeight: window.innerHeight,
  });

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    const getDimensions = () => {
      const isMobile = window.innerWidth <= mobileBP;
      setScreen({
        isMobile,
        isDesktop: window.innerWidth >= desktopBP,
        height: window.innerHeight,
        contentHeight: window.innerHeight - (isMobile ? 179 : 288),
      });
    };

    getDimensions();

    const onResize = () => {
      !!timeout && clearTimeout(timeout);
      timeout = setTimeout(getDimensions, 100);
    };

    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <AppContext.Provider value={{ appUnavailable, screen }}>
      {children}
    </AppContext.Provider>
  );
};
