import { formatDateAndTime } from "@inceptionbg/ui-components";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import { Dialog } from "../../../Elements/Dialog";
import {
  createPublicUploadKey,
  getMobileUploadSession,
  getStoredFilesPub,
} from "../../../repos/FileRepo";
import { IFile, IUploadStatus } from "../../../types/IFile";

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
  setStoredFiles: Dispatch<SetStateAction<IFile[]>>;
}

export const MobileUploadQRCodeDialog: FC<IProps> = ({
  isOpen,
  handleClose,
  setStoredFiles,
}) => {
  const [uploadKey, setUploadKey] = useState("");
  const [url, setUrl] = useState<string>("");
  const [expiresAt, setExpiresAt] = useState("");
  const [filesCount, setFilesCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [sessionStatus, setSessionStatus] = useState<IUploadStatus>("INACTIVE");

  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      createPublicUploadKey()
        .then((data) => {
          setUploadKey(data.uuid);
          setExpiresAt(formatDateAndTime(data.expiresAt));
          const urlBuilder = new URL(`${window.location.origin}/mobile-upload`);
          urlBuilder.searchParams.append("uuid", data.uuid);
          urlBuilder.searchParams.append("expiresAt", data.expiresAt);
          setUrl(urlBuilder.href);
        })
        .catch(() => {})
        .finally(() => setIsLoading(false));
      return () => setUploadKey("");
    }
  }, [isOpen, setUploadKey]);

  useEffect(() => {
    if (uploadKey) {
      const intervalId = setInterval(() => {
        getMobileUploadSession(uploadKey)
          .then(({ status, filesCount }) => {
            setFilesCount(filesCount);
            switch (status) {
              case "INACTIVE":
                break;
              case "ACTIVE":
                setSessionStatus(status);
                break;
              case "COMPLETED":
                getStoredFilesPub(uploadKey)
                  .then((files) => {
                    setStoredFiles(files);
                  })
                  .catch(() => {});
                break;
            }
          })
          .catch(() => {});
      }, 3000);
      return () => {
        clearInterval(intervalId!);
      };
    }
  }, [uploadKey, setStoredFiles]);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      isLoading={isLoading}
      confirmButton={
        sessionStatus === "ACTIVE"
          ? {
              label: t("Cancel"),
              onClick: handleClose,
            }
          : undefined
      }
    >
      {sessionStatus === "ACTIVE" ? (
        <div className="flex column gap-2">
          <p>{t("MobileSesionOpened")}</p>
          <p>
            {t("SessionExpiresAt")}: <strong>{expiresAt}</strong>
          </p>
          <p>
            {t("FilesCount")}: <strong>{filesCount}</strong>
          </p>
        </div>
      ) : url ? (
        <div className="text-center">
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className="text-center"
          >
            <QRCode value={url} />
          </a>
          <p className="text-center m-4 new-line">{t("ScanQRInfo")}</p>
        </div>
      ) : (
        <div className="loading-empty" />
      )}
    </Dialog>
  );
};
