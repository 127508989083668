import { IAnyObject } from "@inceptionbg/ui-components";

export const groupArray = (arr: IAnyObject[], key: string) => {
  const groupedObj = arr.reduce((acc, value) => {
    (acc[value[key]] = acc[value[key]] || []).push(value);
    return acc;
  }, {});

  return Object.entries(groupedObj).map((e) => ({ id: e[0], animals: e[1] }));
};
