import { Button, TextInput } from "@inceptionbg/ui-components";
import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { FarmContext } from "../../../../AppContent";
import { IInputComponentProps, Input } from "../../../../Elements/Input";
import { ModeContext } from "../../../../Pages/RPGPage";
import {
  setIsUserProcessing,
  updateBankAccount,
  updateBankAccountRefNumber,
} from "../../../../repos/FarmRepo";
import { IFarmNew, IBankAccount } from "../../../../types/IFarm";
import { IEvidence } from "../../../../types/IFile";
import { inputPattern } from "../../../../utils/InputPatternValidation";
import { EvidencesComponent } from "../../../Files/EvidencesComponent";
import { EvidencesUploadDialog } from "../../../Files/EvidencesUploadDialog";
import { SubSection } from "../../../SubSection";
import { Form } from "../../../Wrapper/FormWrapper";
interface Props {
  farmData?: IFarmNew;
  setFarm?: Dispatch<SetStateAction<IFarmNew>>;
}
export const BankAccount: FC<Props> = ({ farmData, setFarm }) => {
  const [formData, setFormData] = useState<IBankAccount>({});
  const [evidences, setEvidences] = useState<IEvidence[]>([]);
  const [edit, setEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [attachmentsOpen, setAttachmentsOpen] = useState(false);
  const [attachments2Open, setAttachments2Open] = useState(false);
  const [isInApproval, setIsInApproval] = useState(false);

  const { t } = useTranslation();
  const { farm, reloadFarm } = useContext(FarmContext);
  const isRenew = useContext(ModeContext) === "RENEW";

  // const disableEditAccountNumber =
  //   farm?.bankAccount?.accountNumber &&
  //   (farm.bankAccount.accountNumber !== formData.accountNumber ||
  //     formData.accountNumber?.startsWith("840"));

  const isBankAccountNumberChanged =
    farm?.bankAccount?.accountNumber &&
    farm.bankAccount.accountNumber !== formData.accountNumber;

  const disableFormEdit =
    farm?.bankAccount?.approval?.adminProcessing === true ||
    farm?.bankAccount?.approval?.approvalStatus === "DECISION_SEND";

  useEffect(() => {
    !setFarm && setFormData(farm?.bankAccount || {});
    setIsInApproval(
      !farm?.bankAccount?.approved && !!farm?.bankAccount?.approval
    );
  }, [farm, setFarm]);

  useEffect(() => {
    !!setFarm &&
      setFarm((e) => ({
        ...e,
        bankAccount: {
          evidences: e.bankAccount?.evidences,
          ...formData,
        },
      }));
  }, [formData, setFarm]);
  const bankInfo: IInputComponentProps[] = [
    {
      label: t("AccountNumber"),
      labelHelper: t("AccountNumberHelper"),
      value: farm?.bankAccount?.accountNumber,
      input: (
        <TextInput
          value={formData.accountNumber}
          setValue={(e) => setFormData({ ...formData, accountNumber: e })}
          required
          inputProps={{ pattern: inputPattern.bankAccount }}
          autoFocus={!setFarm}
          error={!setFarm && !formData.accountNumber}
        />
      ),
      alert: {
        text: !formData.accountNumber ? t("RequiredFieldError") : undefined,
        type:
          farm?.bankAccount?.accountNumber && !farm?.bankAccount?.approved
            ? "info"
            : "error",
        active:
          !setFarm && (!formData.accountNumber || !farm?.bankAccount?.approved),
      },
    },
    {
      label: t("BankName"),
      value: farm?.bankAccount?.bank?.name,
      hidden: !!setFarm || !farm?.bankAccount?.bank,
    },
    {
      label: t("ReferenceNumber"),
      value: farm?.bankAccount?.referenceNumber,
      input: (
        <TextInput
          value={formData.referenceNumber}
          setValue={(e) => setFormData({ ...formData, referenceNumber: e })}
          required={!isBankAccountNumberChanged}
        />
      ),
      hidden: !formData.accountNumber?.startsWith("840"),
    },
  ];
  const onFormSubmit = () => {
    if (
      !formData.accountNumber?.startsWith("840") ||
      (formData.accountNumber?.startsWith("840") &&
        farm?.bankAccount?.accountNumber !== formData.accountNumber)
    ) {
      setAttachments2Open(true);
    } else {
      setIsLoading(true);
      updateBankAccountRefNumber({
        uuid: formData.uuid,
        referenceNumber: formData.referenceNumber,
      })
        .then(() => {
          reloadFarm(() => {
            setIsLoading(false);
            setEdit(false);
          });
        })
        .catch(() => setIsLoading(false));
    }
  };
  const onBankAccountUpdate = () => {
    if (farm?.uuid) {
      const data = evidences.length ? { ...formData, evidences } : formData;
      setIsLoading(true);
      updateBankAccount(farm.uuid, data)
        .then(() => {
          reloadFarm(() => {
            setIsLoading(false);
            setEdit(false);
          });
        })
        .catch(() => setIsLoading(false));
    }
  };
  return (
    <SubSection
      title={t("BankAccountTitleOrdered")}
      expandable={!setFarm}
      alerts={
        setFarm
          ? [
              {
                text: t("MissingAttachment"),
                type: "error",
                hidden: !!farmData?.bankAccount?.evidences?.length,
              },
            ]
          : [
              {
                text: t("RequiredFieldsMissing"),
                type: "error",
                hidden: !!formData.accountNumber,
              },
              {
                text: `${t("WaitingComplementText")}
                      ${farm?.bankAccount?.approval?.statusComment}`,
                type: "error",
                number: formData.approval?.approvalNumber,
                hidden:
                  formData.approval?.approvalStatus !== "WAITING_COMPLEMENT",
              },
              {
                text: t("AccountNumberNotVerifiedInfo"),
                type: "info",
                number: farm?.bankAccount?.approval?.approvalNumber,
                hidden:
                  !farm?.bankAccount?.accountNumber ||
                  !!farm?.bankAccount?.approved ||
                  farm.registrationStatus === "WAITING_COMPLEMENT" ||
                  farm.bankAccount.approval?.approvalStatus ===
                    "WAITING_COMPLEMENT" ||
                  farm.bankAccount.approval?.approvalStatus === "REJECTED",
              },
              {
                text: farm?.bankAccount?.approval?.statusComment!,
                type: "info",
                hidden:
                  farm?.bankAccount?.approval?.approvalStatus !==
                  "WAITING_COMPLEMENT",
              },
              {
                text: t("AdminProcessingRequest"),
                type: "info",
                hidden: !farm?.bankAccount?.approval?.adminProcessing,
              },
            ]
      }
    >
      {setFarm ? (
        <div className="form-container">
          {bankInfo.map(
            (e) => !e.hidden && <Input key={e.label} component={e} edit />
          )}
          <div className="flex justify-right">
            <Button
              label={t("UploadAttachments")}
              primary
              onClick={() => setAttachmentsOpen(true)}
              size="s"
            />
          </div>
        </div>
      ) : (
        <Form
          data={bankInfo}
          edit={edit}
          setEdit={setEdit}
          submitButton={{
            editLabel: t(edit ? "Confirm" : "BankAccountEdit"),
            onSubmit: onFormSubmit,
            // disabled: !disableEditAccountNumber,
            hidden: isInApproval || disableFormEdit,
          }}
          otherButtons={[
            {
              label: t("UploadAttachments"),
              onClick: () => setAttachmentsOpen(true),
              primary: true,
              hidden: isRenew || formData.approved || disableFormEdit,
            },
            {
              label: t("StartChanges"),
              onClick: () =>
                setIsUserProcessing(farm?.bankAccount?.approval?.uuid!, true)
                  .then(() => setIsInApproval(false))
                  .catch(() => {}),
              primary: true,
              hidden:
                !farm?.bankAccount?.approval?.uuid ||
                !isInApproval ||
                disableFormEdit,
            },
          ]}
          onResetForm={() => setFormData(farm?.bankAccount || {})}
          isLoading={isLoading}
        />
      )}
      {!isRenew && (
        <>
          <EvidencesUploadDialog
            isOpen={attachmentsOpen}
            onClose={() => {
              setAttachmentsOpen(false);
              reloadFarm(() => {
                setIsLoading(false);
                setEdit(false);
              });
            }}
            category="BANK_ACCOUNT"
            sectionEvidences={farmData?.bankAccount?.evidences}
            setFarm={setFarm}
            approvalUuid={farm?.bankAccount?.approval?.uuid}
          />
          <EvidencesComponent
            isOpen={attachments2Open}
            onClose={() => setAttachments2Open(false)}
            category="BANK_ACCOUNT"
            evidences={evidences}
            setEvidences={setEvidences}
            onSubmit={onBankAccountUpdate}
          />
        </>
      )}
    </SubSection>
  );
};
