import { ISimpleObjectWithCode } from "@inceptionbg/ui-components";
import { IFarm } from "./IFarm";
import { IPlantProtectionProduct } from "./IPlantProtectionProduct";

export interface IPesticideProcurement {
  uuid: string;
  farm?: IFarm;
  name?: string;
  quantity?: string | number;
  unit?: IUnitOfMeasure;
  date?: string;
  createdAt?: string;
  supplierName?: string;
  organizationalForm?: ISimpleObjectWithCode;
  plantProtectionProduct?: IPlantProtectionProduct;
}

export const unitOfMeasures = ["G", "KG", "T", "ML", "L"] as const;
export type IUnitOfMeasure = (typeof unitOfMeasures)[number];

export type PesticideProcurementsFetchOptions =
  | "FARM"
  | "PLANT_PROTECTION_PRODUCT"
  | "ORGANIZATION_FORM";
