import { Button } from "@inceptionbg/ui-components";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FarmContext } from "../AppContent";
import { Section } from "../Components/Section";
import { AddRefactionRequestDialog } from "../Components/Sections/Refaction/Add/AddRefactionRequestDialog";
import { EditRefactionRequestDialog } from "../Components/Sections/Refaction/Add/EditRefactionRequestDialog";
import { RefactionReq } from "../Components/Sections/Refaction/RefactionReq";
import { Alert } from "../Elements/Alert/Alert";
import { InfoBox } from "../Elements/Alert/InfoBox";
import { Dialog } from "../Elements/Dialog";
import { farmHasEDelivery } from "../repos/FarmRepo";
import { getRefactionRequests } from "../repos/RefactionRepo";
import { IRefactionRequest } from "../types/IRefaction";

export const RefactionPage: FC = () => {
  const [refaction, setRefaction] = useState<IRefactionRequest[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [addRefactionReq, setAddRefactionReq] = useState(false);
  const [hasDelivery, setHasDelivery] = useState(true);
  const [dialogCaseNumber, setDialogCaseNumber] = useState<string>();
  const [dialogRefractionRequest, setDialogRefractionRequest] =
    useState<IRefactionRequest>();
  const [reqToEdit, setReqToEdit] = useState<IRefactionRequest | null>(null);

  const { t } = useTranslation();
  const farmUuid = useContext(FarmContext).farm?.uuid;

  const handleGetData = useCallback(
    (callback?: () => void) => {
      if (farmUuid) {
        setIsLoading(true);
        Promise.all([
          getRefactionRequests(farmUuid, ["INVOICES", "ITEMS"]),
          farmHasEDelivery(farmUuid),
        ])
          .then(([data, hasDelivery]) => {
            setHasDelivery(!!hasDelivery);
            setRefaction(data.refractionRequests);
            callback && callback();
          })
          .catch(() => {})
          .finally(() => setIsLoading(false));
      }
    },
    [farmUuid]
  );

  useEffect(handleGetData, [handleGetData]);

  return (
    <>
      <InfoBox
        title={t("Note")}
        descEl={
          <p>
            <Trans
              i18nKey="RefactionPageInfoBox"
              components={{
                link1: <Link to="/receipts" className="link bold" />,
              }}
            />
          </p>
        }
        className="mb-4"
      />
      <Section id="refaction-request" title={t("RefactionTitle")}>
        {!hasDelivery && (
          <Alert
            text={t("ErrorDATABOX_NOT_FOUND")}
            type="info"
            className="mb-4"
          />
        )}
        <div className="form-container">
          {farmUuid &&
            !isLoading &&
            refaction.map((e) => (
              <RefactionReq
                key={e.uuid}
                refactionReq={e}
                reloadData={(caseNumber) => {
                  handleGetData();
                  setDialogCaseNumber(caseNumber);
                }}
                onEdit={() => setReqToEdit(e)}
              />
            ))}
        </div>
        <div className="flex justify-right mt-3">
          <Button
            label={t("RefactionNewRequestAdd")}
            primary
            disabled={!hasDelivery}
            onClick={() => setAddRefactionReq(true)}
          />
        </div>
        <AddRefactionRequestDialog
          isOpen={addRefactionReq}
          onClose={() => setAddRefactionReq(false)}
          setRefractionRequest={setDialogRefractionRequest}
        />
        {reqToEdit && (
          <EditRefactionRequestDialog
            isOpen={!!reqToEdit}
            onClose={() => setReqToEdit(null)}
            refactionReq={reqToEdit}
            setRefractionRequest={setDialogRefractionRequest}
            reloadRefactions={handleGetData}
          />
        )}
      </Section>
      {dialogRefractionRequest && (
        <Dialog
          isOpen={!!dialogRefractionRequest}
          onClose={() => {
            setDialogRefractionRequest(undefined);
            handleGetData();
          }}
          title={t("ConfirmTitleRefractionPost")}
          desc={t("RefractionSuccessfullyDrafted")}
          cancelButton={{
            onClick: () => setDialogRefractionRequest(undefined),
          }}
        >
          <RefactionReq
            refactionReq={dialogRefractionRequest}
            initialyOpen
            reloadData={(caseNumber) => {
              handleGetData();
              setDialogCaseNumber(caseNumber);
            }}
          />
        </Dialog>
      )}
      <Dialog
        isOpen={!!dialogCaseNumber}
        onClose={() => {
          setDialogCaseNumber(undefined);
          setDialogRefractionRequest(undefined);
        }}
        desc={t("RefractionStatusSuccessfullySubmitted", {
          value: dialogCaseNumber,
        })}
        confirmButton={{
          onClick: () => {
            setDialogCaseNumber(undefined);
            setDialogRefractionRequest(undefined);
          },
          label: t("Ok"),
        }}
      />
    </>
  );
};
