import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { FC, ReactNode } from "react";
import { faCheck } from "../assets/icons/regular/faCheck";

interface Props {
  label?: string | ReactNode;
  selected: boolean;
  setSelected: (checked: boolean) => void;
  required?: boolean;
  disabled?: boolean;
  className?: string;
}
export const Checkbox: FC<Props> = ({
  label,
  selected,
  setSelected,
  required,
  disabled,
  className,
}) => {
  return (
    <label className={clsx("checkbox clickable", className, { disabled })}>
      <input
        required={required}
        disabled={disabled}
        type="checkbox"
        checked={selected}
        onChange={() => setSelected(!selected)}
      />
      <div
        className={clsx("flex align-center justify-center icon", { selected })}
      >
        {selected && <FontAwesomeIcon icon={faCheck} />}
      </div>
      {label && <p className="ml-2">{label}</p>}
    </label>
  );
};
