import { clsx, FontAwesomeIcon } from "@inceptionbg/ui-components";
import { FC } from "react";
import { faArrowRight } from "../assets/icons/light/faArrowRight";
import { IListItem } from "../types/IList";

interface Props {
  listItems: IListItem[];
  onClick?: (id: string) => void;
}

export const List: FC<Props> = ({ listItems, onClick }) => {
  return (
    <div className="list">
      {listItems.map((e) => (
        <div
          key={e.id}
          className={clsx("list-item", {
            active: e.active,
            disabled: e.disabled,
            not_clickable: !onClick,
          })}
          onClick={e.onClick || (() => onClick && onClick(e.id))}
        >
          <div className="flex column gap-2">
            {e.title && <p className="title">{e.title}</p>}
            {e.desc && <p className="new-line">{e.desc}</p>}
          </div>
          {!!onClick && (
            <FontAwesomeIcon
              icon={faArrowRight}
              className="icon-2 color-primary"
            />
          )}
        </div>
      ))}
    </div>
  );
};
