import { FC } from "react";
import { Dialog } from "../../../Elements/Dialog";
import { useTranslation } from "react-i18next";
import { PartLandTable } from "./Add/Components/PartLandTable";
import { ILand } from "../../../types/ILand";

interface Props {
  land: ILand;
  isOpen: boolean;
  onClose: () => void;
}

export const PartLandEvidenceDialog: FC<Props> = ({
  land,
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      enableBackgroundClose
      title={t("PartLand")}
      size="l"
      isOpen={isOpen}
      onClose={onClose}
    >
      <PartLandTable land={land} />
    </Dialog>
  );
};
