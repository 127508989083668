import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { saveGeneralRequest } from "../../../repos/GeneralRepo";
import { IFile } from "../../../types/IFile";
import { IRequestNew } from "../../../types/IRequest";
import { BasicRequest } from "../Common/SubSections/BasicRequest";
import { FilesUpload } from "../Common/SubSections/FilesUpload";
import { CustomRequestSection } from "../CustomRequestSection";

export const DeleteSection: FC = () => {
  const [requestData, setRequestData] = useState<IRequestNew>({});
  const [storedFiles, setStoredFiles] = useState<IFile[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const onSubmit = () => {
    setIsLoading(true);
    saveGeneralRequest({
      ...requestData,
      storedFiles,
      deliveryType: "INBOX",
      requestType: "DELETE_REQUEST",
    })
      .then(() => {
        setRequestData({});
        setStoredFiles([]);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  return (
    <CustomRequestSection
      sectionTitle={t("DeleteTitle")}
      infoBoxTitle={t("RequestDeleteTitle")}
      infoBoxDesc={t("RequestDeleteDesc")}
      onSubmit={onSubmit}
      disableSubmit={!requestData.purpose}
    >
      <BasicRequest
        title={t("DeleteRequestFromRegister")}
        mode="DELETE"
        data={requestData}
        setData={setRequestData}
        isLoading={isLoading}
      />
      <FilesUpload
        storedFiles={storedFiles}
        setStoredFiles={setStoredFiles}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    </CustomRequestSection>
  );
};
