import { DateInput, formatDate, TextInput } from "@inceptionbg/ui-components";
import { TFunction } from "i18next";
import { Dispatch, SetStateAction } from "react";
import { IInputComponentProps } from "../../../../Elements/Input";
import { IInvoiceNew } from "../../../../types/IInvoice";
import { NumberInput } from "../../../Inputs/NumberInput";

export const getInvoiceData: (props: {
  formData: IInvoiceNew;
  setFormData?: Dispatch<SetStateAction<IInvoiceNew>>;
  t: TFunction;
  edit: boolean;
  disabled?: boolean;
}) => IInputComponentProps[] = ({
  formData,
  setFormData,
  t,
  edit,
  disabled,
}) => [
  {
    label: t("InvoiceNumber"),
    value: formData.number,
    input: setFormData && (
      <TextInput
        required
        placeholder={t("FreeTextPlaceholder")}
        value={formData.number}
        setValue={(e) => setFormData({ ...formData, number: e })}
        disabled={disabled}
      />
    ),
    edit,
  },
  {
    label: t("InvoiceDate"),
    value: formatDate(formData.date),
    input: setFormData && (
      <DateInput
        required
        date={formData.date}
        setDate={(e) => setFormData({ ...formData, date: e })}
        disabled={disabled}
      />
    ),
    edit,
  },
  {
    label: t("SellerTaxId"),
    value: formData.sellerTaxId,
    input: setFormData && (
      <NumberInput
        required
        placeholder={t("123456789")}
        value={formData.sellerTaxId}
        setValue={(e) => setFormData({ ...formData, sellerTaxId: e })}
        disabled={disabled}
      />
    ),
    edit,
  },
];
