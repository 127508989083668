import { FontAwesomeIcon } from "@inceptionbg/ui-components";
import clsx from "clsx";
import { FC, ReactNode, useState } from "react";
import { faAngleDown } from "../assets/icons/light/faAngleDown";
import { Alert, AlertIcon } from "../Elements/Alert/Alert";
import { Loader } from "../Elements/Loader";
import { IAlertType } from "../types/IBase";

interface Props {
  title?: string;
  titleMulti?: string[];
  id?: string;
  expandable?: boolean;
  initialyOpen?: boolean;
  className?: string;
  alerts?: {
    text: string;
    type: IAlertType;
    number?: string | number;
    hidden: boolean;
  }[];
  secondary?: boolean;
  tertiary?: boolean;
  isLoading?: boolean;
  onOpen?: () => void;
  children: ReactNode;
}

export const SubSection: FC<Props> = ({
  title,
  titleMulti,
  id,
  expandable,
  initialyOpen,
  className,
  alerts,
  secondary,
  tertiary,
  isLoading,
  onOpen,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(!!initialyOpen);

  const activeAlerts = alerts?.filter((e) => !e.hidden);
  return (
    <div className={clsx("page-sub-section", { secondary, tertiary })}>
      <div
        className={clsx("sub-section-title", {
          secondary,
          tertiary,
          clickable: expandable,
        })}
        onClick={() => {
          onOpen && !isOpen && onOpen();
          setIsOpen(!isOpen);
        }}
      >
        {title && <h2 id={id}>{title}</h2>}
        {titleMulti && (
          <div
            id={id}
            className="flex gap-3 justify-between align-center full-width"
          >
            {titleMulti.map((e) => (
              <h2 key={e}>{e}</h2>
            ))}
          </div>
        )}
        {expandable && (
          <FontAwesomeIcon
            icon={faAngleDown}
            className="expandable icon-2 ml-2 color-primary"
            rotation={isOpen ? 180 : undefined}
          />
        )}
        {!!activeAlerts?.length && (
          <AlertIcon type={activeAlerts[0].type} className="section-type" />
        )}
      </div>
      {(!expandable || isOpen) && (
        <>
          {activeAlerts && (
            <div className="alert-container">
              {activeAlerts.map((e) => (
                <Alert
                  key={e.text}
                  text={e.text}
                  type={e.type}
                  number={e.number}
                />
              ))}
            </div>
          )}
          <Loader isLoading={!!isLoading}>
            <div className={clsx("sub-section-content", className)}>
              {children}
            </div>
          </Loader>
        </>
      )}
    </div>
  );
};
