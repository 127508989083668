import { Button } from "@inceptionbg/ui-components";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FarmContext } from "../../../AppContent";
import { InfoBox } from "../../../Elements/Alert/InfoBox";
import { ModeContext } from "../../../Pages/RPGPage";
import {
  getLands,
  getLandsSum,
  getUnclaimedLand,
} from "../../../repos/LandRepo";
import { ILand, ILandsSum } from "../../../types/ILand";
import { convertSurfaceToObj } from "../../../utils/CalculateUtils";
import { AddLandDialog } from "./Add/AddLandDialog";
import { Section } from "../../Section";
import { Land } from "./Land";
import { LandsRecapitulationTable } from "../../Recapitulation/LandsRecapitulationTable";
import { AddMemberLandDialog } from "./Add/AddMemberLandDialog";

interface Props {
  landUpdated: boolean;
  activeMembersCount?: number;
}

export const LandSection: FC<Props> = ({ landUpdated, activeMembersCount }) => {
  const [lands, setLands] = useState<ILand[]>([]);
  const [landsSum, setLandsSum] = useState<ILandsSum>();
  const [unclaimedSurface, setUnclaimedSurface] = useState(0);
  const [addLand, setAddLand] = useState(false);
  const [addMemberLand, setAddMemberLand] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSumLoading, setIsSumLoading] = useState(false);

  const { farm, handleGetRenewalConditions } = useContext(FarmContext);
  const isRenew = useContext(ModeContext) === "RENEW";
  const { t } = useTranslation();

  const handleGetLands = useCallback(
    (callback?: () => void) => {
      if (farm) {
        setIsLoading(true);
        getLands(farm.uuid, {
          fetchOptions: [
            "CADASTRE_CULTURE",
            "CADASTRE_MUNICIPALITY",
            "VERFICATION_STATUS",
            "MEMBER",
            "APPROVAL",
            "UPZ_BASE",
            "LEASE_TYPE",
          ],
        })
          .then((e) => {
            setLands(e.lands);
            callback && callback();
          })
          .then(() => {
            getUnclaimedLand(farm.uuid)
              .then((surface) => setUnclaimedSurface(surface | 0))
              .catch(() => {});
          })
          .catch(() => {})
          .finally(() => setIsLoading(false));
      }
    },
    [farm]
  );

  useEffect(handleGetLands, [handleGetLands, landUpdated]);

  const handleGetLandsSum = useCallback(() => {
    if (farm) {
      setIsSumLoading(true);
      getLandsSum(farm.uuid)
        .then((e) => setLandsSum(e.sum))
        .catch(() => {})
        .finally(() => setIsSumLoading(false));
    }
  }, [farm]);

  useEffect(handleGetLandsSum, [handleGetLandsSum]);

  return (
    <Section id="land" title={t("LandTitleOrdered")}>
      <div className="form-container">
        {unclaimedSurface > 0 && (
          <InfoBox
            title={t("UnreportedLandTitle")}
            className={"danger"}
            desc={t(
              "UnreportedLandDesc",
              convertSurfaceToObj(unclaimedSurface)!
            )}
          />
        )}
        {!!landsSum && !isSumLoading && (
          <LandsRecapitulationTable landsSum={landsSum} />
        )}
        {farm &&
          !isLoading &&
          lands.map((land) => (
            <Land
              key={land.uuid}
              land={land}
              reloadLands={(callback?: () => void) => {
                handleGetLands();
                handleGetRenewalConditions();
                callback && callback();
              }}
              reloadLandsSum={handleGetLandsSum}
            />
          ))}
      </div>
      {!isRenew && (
        <>
          <div className="flex justify-right gap-2 mt-3">
            {farm?.holder.type === "physicalPerson" && (
              <Button
                label={t("MemberLandAdd")}
                primary
                disabled={!activeMembersCount}
                onClick={() => setAddMemberLand(true)}
              />
            )}
            <Button
              label={t("HolderLandAdd")}
              primary
              onClick={() => setAddLand(true)}
            />
          </div>
          <AddLandDialog
            isOpen={addLand}
            onClose={() => setAddLand(false)}
            reloadLands={(callback?: () => void) => {
              handleGetLands();
              handleGetRenewalConditions();
              callback && callback();
            }}
          />
          <AddMemberLandDialog
            isOpen={addMemberLand}
            onClose={() => setAddMemberLand(false)}
            reloadLands={handleGetLands}
          />
        </>
      )}
    </Section>
  );
};
