import { FC } from "react";
import { useLocation } from "react-router-dom";
import { IUser } from "../types/IUser";
import { LoginPage } from "./LoginPage";
import MobileDeviceUploadPage from "./MobileDeviceUploadPage";
import { PublicStatusPage } from "./PublicStatusPage";

interface Props {
  setUser: (user: IUser | null) => void;
}
export const PublicPages: FC<Props> = ({ setUser }) => {
  const { pathname } = useLocation();
  return (
    <>
      {pathname.startsWith("/mobile-upload") ? (
        <MobileDeviceUploadPage />
      ) : pathname === "/pub-status" ? (
        <PublicStatusPage />
      ) : (
        <LoginPage setUser={setUser} />
      )}
    </>
  );
};
