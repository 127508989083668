import "./styles/App.scss";
import { FC, Fragment, useEffect, useState } from "react";
import { IUser, IUserInfo } from "./types/IUser";
import axios from "axios";
import { BrowserRouter } from "react-router-dom";
import { ScrollToTop } from "./Elements/ScrollToTop";
import { localStorageGet } from "./utils/LocalStorageHelper";
import { isTokenValid, setUserFromToken } from "./utils/TokenUtils";
import { logout, refreshToken } from "./repos/AuthRepo";
import { AppContent } from "./AppContent";
import { Dialog } from "./Elements/Dialog";
import { Trans, useTranslation } from "react-i18next";
import { IError } from "@inceptionbg/ui-components";
import i18n from "./utils/i18n";
import { I18nextProvider } from "react-i18next";
import { ConfirmDialog } from "./Components/Dialogs/ConfirmDialog";
import { IConfirmDialogData } from "./types/IBase";
import { PublicPages } from "./Pages/PublicPages";
import { UserProvider } from "./Context/UserContext";
import { AppProvider, IAppContext } from "./Context/AppContext";

export const API_URL = process.env.REACT_APP_API_URL || "";
export const AUTH_API_URL = process.env.REACT_APP_AUTH_API_URL || "";
export const ENV = process.env.REACT_APP_ENV || "";
export const VERSION =  process.env.REACT_APP_VERSION || '';

// Axios
export const axiosInstance = axios.create({ baseURL: API_URL });
export const axiosAuth = axios.create({ baseURL: AUTH_API_URL });

axiosInstance.interceptors.request.use(
  (config) => {
    if (!!config.headers.public) {
      return config;
    }
    const token = config.headers.token || localStorageGet("token");
    const tokenValid = isTokenValid(token as string);
    if (tokenValid) {
      config.headers!.Authorization = `Bearer ${token}`;
    } else {
      const controller = new AbortController();
      controller.abort();
      config.signal = controller.signal;
      token && logout();
    }

    return config;
  }
  // (error) => {
  //   console.log("error: ", error);
  //   return Promise.reject(error);
  // }
);

////// APP //////

export const App: FC = () => {
  const [user, setUser] = useState<IUser | null>(null);
  const [userInfo, setUserInfo] = useState<IUserInfo>({});
  const [confirm, setConfirm] = useState<IConfirmDialogData | null>(null);
  const [errors, setErrors] = useState<string[]>([]);
  const [appUnavailable, setAppUnavailable] =
    useState<IAppContext["appUnavailable"]>(null);

  const { t } = useTranslation();

  console.log("Current version " + VERSION);

  useEffect(() => {
    // Set User from token
    const token = localStorageGet("token");
    if (token) {
      setUserFromToken(token, setUser);
    }

    // Set Refresh token
    const handleTokenExpiration = () => {
      const tokenExp = localStorageGet("tokenExp");
      const currentTimestamp = Math.round(new Date().getTime() / 1000);
      if (!document.hidden && !!tokenExp) {
        // Logout if token is expired
        if (currentTimestamp > +tokenExp) {
          logout();
          // Refresh token 40 minutes before expiration
        } else if (currentTimestamp > +tokenExp - 2400) {
          console.log("Get new token");
          refreshToken();
        }
      }
    };
    handleTokenExpiration();
    const intervalId = setInterval(handleTokenExpiration, 30000);
    return () => {
      clearInterval(intervalId!);
    };
  }, []);

  useEffect(() => {
    axiosInstance.interceptors.response.use(
      (e) => {
        if (!e.config.headers?.noToast) {
          if (e.config.headers?.toastType) {
            setConfirm({
              type: e.config.headers.toastType,
              validation: e.config.headers.validation,
            });
          } else
            switch (e.config.method) {
              case "post":
                setConfirm({
                  type: "Save",
                  validation: e.config.headers.validation,
                });
                break;
              case "put":
                setConfirm({
                  type: "Edit",
                  validation: e.config.headers.validation,
                });
                break;
            }
        }
        return e;
      },
      function (error) {
        const errors: IError[] = error.response?.data.errors;
        if (
          errors[0].errorCode &&
          ["RGZ_UNAVAILABLE", "APR_UNAVAILABLE"].includes(errors[0].errorCode)
        ) {
          setAppUnavailable({
            errorCode: errors[0].errorCode,
            resetError: () => setAppUnavailable(null),
          });
        } else {
          !!appUnavailable && setAppUnavailable(null);

          const errorMessages = errors?.length
            ? errors.map((e) =>
                e.errorCode ? (
                  // @ts-ignore
                  e.errorPlaceholders ? (
                    <Trans
                      i18nKey={`Error${e.errorCode}`}
                      // @ts-ignore
                      values={e.errorPlaceholders}
                    />
                  ) : (
                    t(`Error${e.errorCode}`)
                  )
                ) : (
                  e.errorMessage
                )
              )
            : [t("ErrorMessage")];

          setErrors(errorMessages as string[]);
        }

        return Promise.reject(error);
      }
    );
    // eslint-disable-next-line
  }, [t]);

  return (
    <I18nextProvider i18n={i18n}>
      <AppProvider appUnavailable={appUnavailable}>
        <BrowserRouter>
          <ScrollToTop />
          {user ? (
            <UserProvider
              user={user}
              userInfo={userInfo}
              setUserInfo={setUserInfo}
            >
              <AppContent />
              <ConfirmDialog
                isOpen={!!confirm}
                onClose={() => setConfirm(null)}
                type={confirm?.type!}
                validation={confirm?.validation}
              />
            </UserProvider>
          ) : (
            <PublicPages setUser={setUser} />
          )}
          <Dialog isOpen={!!errors.length} onClose={() => setErrors([])} error>
            <div>
              {errors.map((e, i) => (
                <Fragment key={e}>
                  {i > 0 && <hr />}
                  <div className="text-center new-line">{e}</div>
                </Fragment>
              ))}
            </div>
          </Dialog>
        </BrowserRouter>
      </AppProvider>
    </I18nextProvider>
  );
};
