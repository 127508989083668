import { FC, useContext, useState } from "react";
import { CustomRequestSection } from "../Components/Sections/CustomRequestSection";
import { useTranslation } from "react-i18next";
import { IFile } from "../types/IFile";
import { FilesUpload } from "../Components/Sections/Common/SubSections/FilesUpload";
import { changeLegalRepresentative } from "../repos/FarmRepo";
import { IInputComponentProps, Input } from "../Elements/Input";
import { TextInput } from "@inceptionbg/ui-components";
import { UserContext } from "../Context/UserContext";

export const ChangeLegalRepPage: FC = () => {
  const [orgNumber, setOrgNumber] = useState("");
  const [storedFiles, setStoredFiles] = useState<IFile[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  const data: IInputComponentProps[] = [
    {
      label: t("OrgNumberLong"),
      input: <TextInput value={orgNumber} setValue={setOrgNumber} />,
    },
    { label: t("NewLRFirstName"), value: user?.firstName },
    { label: t("NewLRLastName"), value: user?.lastName },
    { label: t("NewLRtPIN"), value: user?.personalIdentityNumber },
  ];

  const onSubmit = () => {
    setIsLoading(true);
    changeLegalRepresentative(
      orgNumber,
      storedFiles.map((storedFile) => ({ storedFile }))
    )
      .then(() => {
        setStoredFiles([]);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  return (
    <CustomRequestSection
      sectionTitle={t("ChangeLRTitle")}
      infoBoxTitle={t("RequestChangeLRTitle")}
      infoBoxDesc={t("RequestChangeLRDesc")}
      onSubmit={onSubmit}
      disableSubmit={!orgNumber || !storedFiles.length}
    >
      <div className="form-container mb-4">
        {data.map(
          (e) =>
            !e.hidden && <Input key={e.label} component={e} edit={!e.edit} />
        )}
      </div>
      <FilesUpload
        storedFiles={storedFiles}
        setStoredFiles={setStoredFiles}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        infoBoxItems={[{ title: t("NewLREvidence") }]}
      />
    </CustomRequestSection>
  );
};
