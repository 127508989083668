import { Button, toastError } from "@inceptionbg/ui-components";
import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import Dropzone, { Accept } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../Context/AppContext";
import { FarmContext } from "../../../AppContent";
import { faFileArrowUp } from "../../../assets/icons/light/faFileArrowUp";
import { faMobileNotch } from "../../../assets/icons/light/faMobileNotch";
import { createEvidenceFromStoredFile } from "../../../repos/FileRepo";
import { IFarmNew } from "../../../types/IFarm";
import {
  evidenceSections,
  IEvidence,
  IEvidenceCategory,
  IEvidenceFilters,
  IFile,
} from "../../../types/IFile";
import { MobileUploadQRCodeDialog } from "./MobileUploadQRCodeDialog";

interface Props {
  accept?: Accept;
  onSubmit: (files: File[]) => void;
  category?: IEvidenceCategory;
  filters?: IEvidenceFilters;
  setFarm?: Dispatch<SetStateAction<IFarmNew>>;
  setEvidences?: Dispatch<SetStateAction<IEvidence[]>>;
  setFiles?: Dispatch<SetStateAction<IFile[]>>;
  reloadEvidences?: () => void;
  hideMobileUploadOption?: boolean;
}

export const FilesDropzone: FC<Props> = ({
  accept,
  onSubmit,
  category,
  filters,
  setFarm,
  setEvidences,
  setFiles,
  reloadEvidences,
  hideMobileUploadOption,
}) => {
  const [mobileUploadDialogOpen, setMobileUploadDialogOpen] = useState(false);
  const [storedFiles, setStoredFiles] = useState<IFile[]>([]);
  const { t } = useTranslation();

  const farmUuid = useContext(FarmContext).farm?.uuid;
  const {
    screen: { isMobile },
  } = useContext(AppContext);

  useEffect(() => {
    if (storedFiles.length) {
      setMobileUploadDialogOpen(false);
      setFiles ? setFiles(storedFiles) : setMobileUploadDialogOpen(false);
      storedFiles.forEach((file) => {
        if (setFarm && category) {
          setFarm((farm) => ({
            ...farm,
            [evidenceSections[category]!]: {
              ...farm[evidenceSections[category]!],
              evidences: [
                ...(farm[evidenceSections[category]!]?.evidences || []),
                { storedFile: file },
              ],
            },
          }));
        } else if (setEvidences) {
          setEvidences((evidences) => [...evidences, { storedFile: file }]);
        } else if (farmUuid && category) {
          createEvidenceFromStoredFile(file.uuid!, farmUuid, category, filters)
            .then((e) => {
              reloadEvidences && reloadEvidences();
            })
            .catch(() => {})
            .finally(() => {});
        }
      });
    }
  }, [
    storedFiles,
    farmUuid,
    category,
    filters,
    setFarm,
    setEvidences,
    setFiles,
    reloadEvidences,
  ]);

  return (
    <>
      <Dropzone
        onDrop={(files) => onSubmit(files)}
        accept={accept}
        onDropRejected={() => toastError(t("ErrorUnsupportedFile"))}
      >
        {({ getRootProps, getInputProps }) =>
          isMobile ? (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Button
                label={t("UploadDocument")}
                iconEnd={faMobileNotch}
                outlined
              />
            </div>
          ) : (
            <section className="dropzone-container">
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <div className="flex center gap-2 mb-2">
                  <Button
                    label={t("PCUpload")}
                    iconEnd={faFileArrowUp}
                    outlined
                  />
                  {!hideMobileUploadOption && (
                    <>
                      <p>{t("or")}</p>
                      <Button
                        label={t("MobileUpload")}
                        iconEnd={faMobileNotch}
                        outlined
                        onClick={(e) => {
                          e.stopPropagation();
                          setMobileUploadDialogOpen(true);
                        }}
                      />
                    </>
                  )}
                </div>
                <p>{t("justDragAndDropFile")}.</p>
              </div>
            </section>
          )
        }
      </Dropzone>
      {mobileUploadDialogOpen && (
        <MobileUploadQRCodeDialog
          isOpen={mobileUploadDialogOpen}
          handleClose={() => setMobileUploadDialogOpen(false)}
          setStoredFiles={setStoredFiles}
        />
      )}
    </>
  );
};
