import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

const prefix = "fal";
const iconName = "mobile-notch";
const width = 384;
const height = 512;
const unicode = "e1ee";
const svgPathData =
  "M288 0H96C60.65 0 32 28.65 32 64v384c0 35.35 28.65 64 64 64h192c35.35 0 64-28.65 64-64V64C352 28.65 323.3 0 288 0zM160 32h64v16H160V32zM320 448c0 17.64-14.36 32-32 32H96c-17.64 0-32-14.36-32-32V64c0-17.64 14.36-32 32-32h32v16c0 17.64 14.36 32 32 32h64c17.64 0 32-14.36 32-32V32h32c17.64 0 32 14.36 32 32V448zM224 400H160c-8.836 0-16 7.162-16 16c0 8.836 7.164 16 16 16h64c8.836 0 16-7.164 16-16C240 407.2 232.8 400 224 400z";

export const faMobileNotch: IconDefinition = {
  prefix,
  iconName,
  icon: [width, height, [], unicode, svgPathData],
};
