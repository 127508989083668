import { ITableColumn, ITableDataItem } from "@inceptionbg/ui-components";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ILandsSum } from "../../types/ILand";
import { RecapitulationTable } from "./RecapitulationTable";
import { convertSurfaceToObj } from "../../utils/CalculateUtils";
import { SurfaceValue } from "../Inputs/Surface/SurfaceValue";

interface Props {
  landsSum: ILandsSum;
}

export const LandsRecapitulationTable: FC<Props> = ({ landsSum }) => {
  const { t } = useTranslation();

  const columns: ITableColumn[] = [
    { id: "name", label: t("LandCulture") },
    { id: "surface", label: t("SurfaceTitleFormatted"), align: "right" },
  ];

  const data: ITableDataItem[] = landsSum.totalByCultures.map((e) => ({
    uuid: e.plantCulture.uuid,
    cells: {
      name: {
        value: e.plantCulture.name || "",
      },
      surface: {
        value: (
          <SurfaceValue
            valueObj={convertSurfaceToObj(e.surface || 0)}
            isRecapitulation
          />
        ),
      },
    },
  }));

  const totals: ITableDataItem[] = [
    {
      uuid: "totalSurfaceUnderCultures",
      cells: {
        name: {
          value: t("TotalSurfaceUnderCultures"),
        },
        surface: {
          value: (
            <SurfaceValue
              valueObj={convertSurfaceToObj(
                landsSum.totalSurfaceUnderCultures || 0
              )}
              isRecapitulation
            />
          ),
        },
      },
    },
    {
      uuid: "totalSurfaces",
      cells: {
        name: {
          value: t("TotalSurfaces"),
        },
        surface: {
          value: (
            <SurfaceValue
              valueObj={convertSurfaceToObj(landsSum.totalSurface || 0)}
              isRecapitulation
            />
          ),
        },
      },
    },
    {
      uuid: "noReportedCulture",
      cells: {
        name: {
          value: t("NoReportedCulture"),
        },
        surface: {
          value: (
            <SurfaceValue
              valueObj={convertSurfaceToObj(
                (landsSum.totalSurface || 0) -
                  (landsSum.totalSurfaceUnderCultures || 0)
              )}
              isRecapitulation
            />
          ),
        },
      },
    },
  ];

  return (
    <RecapitulationTable
      title={t("CadastreCultureRecapitulationTitle")}
      tableColumns={columns}
      tableData={data}
      totalsData={totals}
    />
  );
};
