import { axiosInstance } from "../App";
import { IEvidence } from "../types/IFile";
import {
  ICulture,
  ICultureNew,
  ILand,
  IRealLand,
  ILandNew,
  ILandPart,
  ILandReportNew,
  ILandsSum,
  ISurfaceObj,
  LandFetchOption,
  IRealLandNew,
} from "../types/ILand";

export const getLands = (
  farmUuid: string,
  filters?: {
    fetchOptions?: LandFetchOption[];
    memberUuid?: string;
  }
) =>
  axiosInstance
    .get(`/farm/${farmUuid}/lands`, { params: filters })
    .then(({ data }) => ({ lands: data.lands as ILand[] }));

export const addLand = (farmUuid: string, data: ILandNew) =>
  axiosInstance.post(`/farm/${farmUuid}/land`, data);

export const addLands = (
  farmUuid: string,
  data: { lands: ILandNew[]; memberLandStatements: IEvidence[] }
) => axiosInstance.post(`/farm/${farmUuid}/lands`, data);

export const editLand = (landUuid: string, data: ILandNew) =>
  axiosInstance.patch(`/land/${landUuid}`, data);

export const deleteLand = (landUuid: string) =>
  axiosInstance.delete(`/land/${landUuid}`);

export const getUnclaimedLand = (farmUuid: string) =>
  axiosInstance
    .get(`/farm/${farmUuid}/unclaimed-land`)
    .then(({ data }) => data.surface as number);

export const getLandsSum = (farmUuid: string) =>
  axiosInstance
    .get(`/farm/${farmUuid}/lands/sum`)
    .then(({ data }) => ({ sum: data as ILandsSum }));

export const getLandParts = (landUuid: string) =>
  axiosInstance
    .get(`/land/${landUuid}/parts`)
    .then(({ data }) => data as ILandPart[]);

export const getLandSurfaceInfo = (
  farmUuid: string,
  filters: {
    cadastreNumber?: string;
    cadastreMunicipalityUuid?: string;
    memberUuid?: string;
  }
) =>
  axiosInstance
    .get("/land/owner/info", { params: { farmUuid, ...filters } })
    .then(({ data }) => data as { ownedSurface: number; totalSurface: number });

export const isLandExist = (
  farmUuid: string,
  filters: {
    cadastreNumber?: string;
    cadastreMunicipalityUuid?: string;
    usageBasis?: string;
    surface?: ISurfaceObj;
    usedSurface?: ISurfaceObj;
  }
) =>
  axiosInstance
    .get(`/farm/${farmUuid}/land/exist`, { params: { ...filters } })
    .then(({ data }) => data as boolean);

// CULTURES

export const getLandCultures = (landUuid: string) =>
  axiosInstance
    .get(`/land/${landUuid}/cultures`)
    .then(({ data }) => ({ cultures: data.cultures as ICulture[] }));

export const addOrEditLandCultures = (landUuid: string, data: ICultureNew) =>
  axiosInstance.post(`/land/${landUuid}/culture`, data);

export const deleteLandCulture = (cultureUuid: string) =>
  axiosInstance.delete(`/land/culture/${cultureUuid}`);

export const createLandReport = (land: ILandReportNew) =>
  axiosInstance.post(`/land/report`, land, {
    headers: { toastType: "Request" },
  });

export const getRealLands = (filters?: { fetchOptions?: LandFetchOption[],farmUuid?:string }) =>
  axiosInstance
    .get(`/real-lands`, { params: filters })
    .then(({ data }) => ({ realLands: data.realLands as IRealLand[] }));

export const createRealLand = (land: IRealLandNew) =>
  axiosInstance.post(`/real-lands`, land, {
    headers: { toastType: "Request", noToast: true },
  });

export const deleteRealLand = (landUuid: string) =>
  axiosInstance.delete(`/real-lands/${landUuid}`);
