import { TextInput } from "@inceptionbg/ui-components";
import { forwardRef, InputHTMLAttributes } from "react";

interface Props {
  label?: string;
  value?: string | number;
  setValue: (value: string) => void;
  required?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  helperText?: string;
  placeholder?: string;
  className?: string;
  error?: boolean;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
}
export const NumberInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      value,
      setValue,
      label,
      required,
      disabled,
      autoFocus,
      helperText,
      placeholder,
      className,
      error,
      inputProps,
    },
    ref
  ) => {
    const setValueHandler = (e: string) => {
      (new RegExp(`^\\d*$`).test(e) || !e) && setValue(e);
    };

    return (
      <TextInput
        ref={ref}
        value={value || value === 0 ? `${value}` : ""}
        setValue={setValueHandler}
        label={label}
        required={required}
        disabled={disabled}
        placeholder={placeholder}
        inputProps={{
          inputMode: "numeric",
          ...inputProps,
        }}
        className={className}
        autoFocus={autoFocus}
        helperText={helperText}
        error={error}
      />
    );
  }
);
