import { FC, useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { FarmContext } from "../../../AppContent";
import { getLands } from "../../../repos/LandRepo";
import { ILand } from "../../../types/ILand";
import { IMemberNew } from "../../../types/IMember";
import { SubSection } from "../../SubSection";
import { LandForm } from "../Common/LandForm";
import { PersonalInfoForm } from "../Common/PersonalInfoForm";
import { maxChar } from "../../../utils/StringUtils";
import { convertSurfaceToObj } from "../../../utils/CalculateUtils";

interface Props {
  member?: IMemberNew;
  reloadMembers?: (callback?: () => void) => void;
  reloadLands?: (callback?: () => void) => void;
}

export const Member: FC<Props> = ({ member, reloadMembers, reloadLands }) => {
  const [lands, setLands] = useState<ILand[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const { farm } = useContext(FarmContext);

  const handleGetLands = useCallback(
    (callback?: () => void) => {
      if (farm && member) {
        setIsLoading(true);
        getLands(farm.uuid, {
          fetchOptions: [
            "CADASTRE_CULTURE",
            "CADASTRE_MUNICIPALITY",
            "VERFICATION_STATUS",
          ],
          memberUuid: member.uuid,
        })
          .then((e) => {
            setLands(e.lands);
            !!callback && callback();
          })
          .catch(() => {})
          .finally(() => setIsLoading(false));
      }
    },
    [farm, member]
  );

  return (
    <SubSection
      title={`${member?.firstName} ${member?.lastName}`}
      expandable
      onOpen={handleGetLands}
      alerts={[
        {
          text: t("RequiredFieldsMissing"),
          type: "error",
          hidden:
            !!member?.personalIdentityNumber &&
            !!member?.firstName &&
            !!member?.lastName &&
            !!member?.birthDate &&
            !!member?.birthPlace &&
            !!member?.birthCountry &&
            !!member?.gender &&
            !!member?.workActivity,
        },
        {
          text: t("RequiredFieldsMissingMembersLands"),
          type: "error",
          hidden: !lands.find(
            (land) =>
              !(land?.usageBasis !== "LEASE" || !!land?.leaseEndDate) ||
              !land?.surface ||
              !land?.usedSurface ||
              !(land?.usageBasis !== "LEASE" || !!land?.lessorIdentification) ||
              !land?.cadastreMunicipality ||
              !land?.cadastreNumber
          ),
        },
        {
          text: `${t("WaitingComplementText")}
                      ${member?.approval?.statusComment}`,
          type: "error",
          number: member?.approval?.approvalNumber,
          hidden: member?.approval?.approvalStatus !== "WAITING_COMPLEMENT",
        },
        {
          text: t("MemberNotVerifiedInfo"),
          type: "info",
          number: member?.approval?.approvalNumber,
          hidden:
            !!member?.approved ||
            member?.approval?.approvalStatus === "WAITING_COMPLEMENT",
        },
        {
          text: t("AdminProcessingRequest"),
          type: "info",
          hidden: !member?.approval?.adminProcessing,
        },
      ]}
    >
      <PersonalInfoForm
        member={member}
        reloadMembers={reloadMembers}
        isMember
        approvalUuid={member?.approval?.uuid}
      />
      <SubSection title={t("MembersLandTitle")} secondary isLoading={isLoading}>
        {!!lands.length && (
          <div className="form-container">
            {lands.map((land) => (
              <SubSection
                key={land.uuid}
                titleMulti={[
                  `${land.cadastreNumber} - ${
                    land.nickname
                      ? maxChar(land.nickname, 20)
                      : land.cadastreMunicipality.name
                  }`,
                  t(land.usageBasis),
                  `${convertSurfaceToObj(land.usedSurface)?.ha || 0}ha 
                  ${convertSurfaceToObj(land.usedSurface)?.a || 0}a 
                  ${convertSurfaceToObj(land.usedSurface)?.m || 0}m²`,
                ]}
                tertiary
                expandable
                alerts={[
                  {
                    text: t("RequiredFieldsMissing"),
                    type: "error",
                    hidden:
                      (land?.usageBasis !== "LEASE" || !!land?.leaseEndDate) &&
                      !!land?.surface &&
                      !!land?.usedSurface &&
                      (land?.usageBasis !== "LEASE" ||
                        !!land?.lessorIdentification) &&
                      !!land?.cadastreMunicipality &&
                      !!land?.cadastreNumber,
                  },
                  {
                    text: t("MemberLandNotVerifiedInfo"),
                    type: "info",
                    hidden: !!land?.approved,
                  },
                ]}
              >
                <LandForm
                  land={land}
                  membersLand
                  reloadLands={(callback?: () => void) => {
                    handleGetLands();
                    reloadLands && reloadLands();
                    callback && callback();
                  }}
                />
              </SubSection>
            ))}
          </div>
        )}
      </SubSection>
    </SubSection>
  );
};
