import { FC, Fragment, useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useLocation } from "react-router-dom";
import { dataURLtoFile } from "../utils/FileUtils";
import { useTranslation } from "react-i18next";
import Compressor from "compressorjs";
import { Button, TextInput } from "@inceptionbg/ui-components";
import { Alert } from "../Elements/Alert/Alert";
import { Loader } from "../Elements/Loader";
import { setMobileUploadStatus, uploadFilePub } from "../repos/FileRepo";

const MobileDeviceUploadPage: FC = () => {
  const [photo, setPhoto] = useState("");
  const [uploadKey, setUploadKey] = useState("");
  const [expiresAt, setExpiresAt] = useState(0);
  const [currentTime, setCurrentTime] = useState<number>(Date.now());
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [fileExt, setFileExt] = useState("");

  const { t } = useTranslation();
  const location = useLocation();

  const handleFetchFile = useCallback((file: any) => {
    new Compressor(file, {
      quality: 0.3,
      maxHeight: 2000,
      maxWidth: 2000,
      success(result: any) {
        const reader = new FileReader();

        reader.onerror = () => console.log("File reading has failed");
        reader.onload = () => {
          setPhoto(reader.result as any);
        };
        const nameArray = file.name.split(".");
        setFileName(nameArray.slice(0, -1).join("."));
        setFileExt(nameArray[nameArray.length - 1]);
        reader.readAsDataURL(result);
      },
      error(err) {
        console.log(err.message);
      },
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (files: File[]) => handleFetchFile(files[0]),
    multiple: false,
    accept: { "image/*": [".jpeg", ".png", ".jpg"] },
  });

  const {
    getRootProps: getCameraRootProps,
    getInputProps: getCameraInputProps,
  } = useDropzone({
    onDrop: (files: File[]) => handleFetchFile(files[0]),
    multiple: false,
    accept: { "image/*": [".jpeg", ".png", ".jpg"] },
  });

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const key = params.get("uuid");
    if (key) {
      setMobileUploadStatus(key, "ACTIVE")
        .then(() => {
          setUploadKey(key);
          setExpiresAt(new Date(params.get("expiresAt")!).getTime());
        })
        .catch(() => {});
    }
  }, [location.search]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = Date.now();
      setCurrentTime(now);
      if (now + 30000 > expiresAt) {
        setMobileUploadStatus(uploadKey, "COMPLETED");
      }
    }, 2000);
    return () => clearInterval(intervalId);
  }, [expiresAt, uploadKey]);

  const uploadKeyExpired = currentTime + 30000 > expiresAt;

  const handleUpload = () => {
    const file = dataURLtoFile(photo, fileName, fileExt);
    if (!!file && uploadKey) {
      setIsLoading(true);
      uploadFilePub(uploadKey, file)
        .then(() => {
          setSuccess(true);
          setPhoto("");
        })
        .catch(() => {})
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <>
      {isLoading && (
        <Loader isLoading>
          <div className="full-screen" />
        </Loader>
      )}
      {uploadKeyExpired && <Alert text={t("QRExpired")} type="error" />}
      {!isLoading && !uploadKeyExpired && (
        <>
          {success ? (
            <div className="file-upload-page-container">
              <Alert text={t("FileUploadSuccess")} type="success" />
              <Button
                label={t("AddNewFile")}
                onClick={() => setSuccess(false)}
                className="full-width"
                size="l"
                outlined
              />
              <Button
                label={t("Confirm")}
                onClick={() =>
                  setMobileUploadStatus(uploadKey, "COMPLETED")
                    .then(() => window.close())
                    .catch(() => {})
                }
                className="full-width"
                size="l"
                primary
              />
            </div>
          ) : (
            <div
              className="file-upload-page-container"
              style={{ height: window.innerHeight }}
            >
              {!photo ? (
                <Fragment>
                  <div {...getRootProps()} className="file-dropzone">
                    <input {...getInputProps()} />
                    {t("UploadFilePhone")}
                  </div>

                  <div {...getCameraRootProps()}>
                    <input
                      {...getCameraInputProps()}
                      accept="image/*"
                      capture="environment"
                    />
                    <Button
                      label={t("StartCamera")}
                      className="full-width"
                      size="l"
                      primary
                    />
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <img
                    src={photo}
                    alt="document file"
                    className="preview"
                  ></img>
                  <div className="mx-2 my-3">
                    <TextInput
                      required
                      label={t("FileName")}
                      value={fileName}
                      setValue={setFileName}
                    />
                  </div>
                  <FooterBtns
                    onAction={handleUpload}
                    onCancel={() => setPhoto("")}
                    actionName="Sačuvaj datoteku"
                    actionDisabled={!fileName}
                  />
                </Fragment>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

interface FooterBtnProps {
  onAction: () => void;
  onCancel: () => void;
  actionName: string;
  actionIcon?: any;
  actionDisabled?: boolean;
}

const FooterBtns: FC<FooterBtnProps> = ({
  onAction,
  onCancel,
  actionName,
  actionDisabled,
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex column gap-3">
      <Button
        label={actionName}
        disabled={actionDisabled}
        onClick={onAction}
        className="full-width"
        size="l"
        primary
      />

      <Button
        label={t("Cancel")}
        onClick={onCancel}
        className="full-width"
        size="l"
        outlined
      />
    </div>
  );
};

export default MobileDeviceUploadPage;
