import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

const prefix = "fal";
const iconName = "angle-down";
const width = 384;
const height = 512;
const unicode = "f107";
const svgPathData =
  "M362.7 203.9l-159.1 144c-6.125 5.469-15.31 5.469-21.44 0L21.29 203.9C14.73 197.1 14.2 187.9 20.1 181.3C26.38 174.4 36.5 174.5 42.73 180.1L192 314.5l149.3-134.4c6.594-5.877 16.69-5.361 22.62 1.188C369.8 187.9 369.3 197.1 362.7 203.9z";

export const faAngleDown: IconDefinition = {
  prefix,
  iconName,
  icon: [width, height, [], unicode, svgPathData],
};
