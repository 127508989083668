import jwtDecode from "jwt-decode";
import { IToken, ITokenRaw } from "../types/IToken";
import { IUser } from "../types/IUser";

export const setUserFromToken = (
  token: string,
  setUser: (user: IUser | null) => void
) => {
  if (token) {
    const tokenDecoded = jwtDecode<ITokenRaw>(token);
    setUser({
      firstName: tokenDecoded["http://schema.id.rs/claims/givenname"],
      lastName: tokenDecoded["http://schema.id.rs/claims/familyname"],
      personalIdentityNumber: tokenDecoded["http://schema.id.rs/claims/umcn"],
      email: tokenDecoded["http://schema.id.rs/claims/mail"],
    });
  } else {
    setUser(null);
  }
};

export const isTokenValid = (token: string) => {
  const tokenDecoded = token && jwtDecode<IToken>(token);
  return tokenDecoded && Date.now() < tokenDecoded.exp * 1000;
};
