import { IPagination } from "@inceptionbg/ui-components";
import {
  IPesticideProcurement,
  PesticideProcurementsFetchOptions,
} from "../types/IPesticideProcurement";
import { axiosInstance } from "../App";

export const getProcurementPesticides = (
  farmUuid: string,
  pagination: IPagination,
  fetchOptions?: PesticideProcurementsFetchOptions[]
) =>
  axiosInstance
    .get(`/farm/${farmUuid}/pesticide-procurements`, {
      params: { ...pagination, fetchOptions },
    })
    .then(({ data }) => ({
      pesticideProcurements:
        data.pesticideProcurements as IPesticideProcurement[],
      totalRows: data.totalRows as number,
    }));

export const saveProcurementPesticide = (
  farmUuid: string,
  data: Partial<IPesticideProcurement>
) => axiosInstance.post(`/farm/${farmUuid}/pesticide-procurement`, data);

export const deletePesticideProcurement = (uuid: string) =>
  axiosInstance.delete(`/pesticide-procurement/${uuid}`);
