import clsx from "clsx";
import { FC, ReactNode } from "react";

interface Props {
  title: string;
  desc?: string;
  descEl?: ReactNode;
  className?: string;
  light?: boolean;
  size?: "s" | "l";
}
export const InfoBox: FC<Props> = ({
  title,
  desc,
  descEl,
  className,
  light,
  size,
}) => (
  <div className={clsx("info-box", className, size, { light })}>
    <p className="title">{title}</p>
    {desc && <p className="new-line">{desc}</p>}
    {descEl}
  </div>
);
