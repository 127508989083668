import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IInputComponentProps } from "../../../Elements/Input";
import { ModeContext } from "../../../Pages/RPGPage";
import { deleteAnimal, editAnimal } from "../../../repos/AnimalRepo";
import { IAnimal, IAnimalNew } from "../../../types/IAnimal";
import { Form } from "../../Wrapper/FormWrapper";
import { SubSection } from "../../SubSection";
import { NumberInput } from "../../Inputs/NumberInput";
import { Select } from "../../Inputs/Select";
import { productionType } from "../../../types/ILand";
import { TextInput } from "@inceptionbg/ui-components";
import { Dialog } from "../../../Elements/Dialog";

interface Props {
  animal: IAnimal;
  reloadAnimals: (callback?: () => void) => void;
}

export const AnimalForm: FC<Props> = ({ animal, reloadAnimals }) => {
  const [formData, setFormData] = useState<IAnimalNew>({});
  const [edit, setEdit] = useState(false);
  const [animalToDeleteUuid, setAnimalToDeleteUuid] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const isRenew = useContext(ModeContext) === "RENEW";

  useEffect(() => {
    setFormData(animal);
  }, [animal]);

  const animalInfo: IInputComponentProps[] =
    animal.group === "CUSTOM"
      ? [
          {
            label: t("AnimalName"),
            value: formData.name,
            input: (
              <TextInput
                value={formData.name}
                setValue={(e) => setFormData({ ...formData, name: e })}
                required
                autoFocus
                error={!formData.name}
              />
            ),
            alert: {
              text: !formData.name ? t("RequiredFieldError") : undefined,
              type: "error",
              active: !formData.name,
            },
          },
          {
            label: t("AnimalProductionType"),
            value: formData.productionType ? t(formData.productionType) : "/",
            input: (
              <Select
                required
                value={
                  formData.productionType
                    ? {
                        label: t(formData.productionType),
                        value: formData.productionType,
                      }
                    : null
                }
                options={productionType.map((e) => ({
                  label: t(`Animal${e}`),
                  value: e,
                }))}
                onChange={(e) =>
                  setFormData({ ...formData, productionType: e.value })
                }
                error={!formData.productionType}
              />
            ),
            alert: {
              text: !formData.productionType
                ? t("RequiredFieldError")
                : undefined,
              type: "error",
              active: !formData.productionType,
            },
          },
          {
            label: t("AnimalDescriptionAndCount"),
            value: formData.description,
            input: (
              <TextInput
                value={formData.description}
                setValue={(e) => setFormData({ ...formData, description: e })}
                required
                error={!formData.description}
              />
            ),
            alert: {
              text: !formData.description ? t("RequiredFieldError") : undefined,
              type: "error",
              active: !formData.description,
            },
          },
        ]
      : [
          {
            label: t("Hid"),
            value: animal.hid,
          },
          {
            label: t("AnimalGroup"),
            value: t(animal.group),
          },
          {
            label: t("AnimalCount"),
            value: `${animal.animalCount || "/"}`,
          },
          {
            label: t("AnimalCountOrganic"),
            value: `${animal.animalCountOrganic?.toString() || "/"}`,
            input: (
              <NumberInput
                required
                value={formData.animalCountOrganic}
                setValue={(e) =>
                  setFormData({ ...formData, animalCountOrganic: e })
                }
                error={!formData.animalCountOrganic?.toString()}
              />
            ),
            alert: {
              text: t("RequiredFieldError"),
              type: "error",
              active: !formData.animalCountOrganic?.toString(),
            },
          },
          {
            label: t("AnimalCountConventional"),
            value: `${animal.animalCountConventional?.toString() || "/"}`,
            input: (
              <NumberInput
                required
                value={formData.animalCountConventional}
                setValue={(e) =>
                  setFormData({ ...formData, animalCountConventional: e })
                }
                error={!formData.animalCountConventional?.toString()}
              />
            ),
            alert: {
              text: t("RequiredFieldError"),
              type: "error",
              active: !formData.animalCountConventional?.toString(),
            },
          },
        ];

  const onSubmit = () => {
    setIsLoading(true);
    editAnimal(animal.uuid, formData)
      .then(() => {
        if (!!reloadAnimals) {
          reloadAnimals(() => {
            setIsLoading(false);
            setEdit(false);
          });
        } else {
          setIsLoading(false);
          setEdit(false);
        }
      })
      .catch(() => setIsLoading(false));
  };

  const onDelete = () => {
    if (animalToDeleteUuid) {
      setIsLoading(true);
      deleteAnimal(animalToDeleteUuid)
        .then(() => {
          reloadAnimals(() => {
            setIsLoading(false);
            setEdit(false);
          });
        })
        .catch(() => setIsLoading(false));
    }
  };
  return (
    <SubSection
      title={
        animal.group === "CUSTOM"
          ? animal.name!
          : `${t("HidShort")}: ${animal.hid}`
      }
      expandable
      tertiary
      alerts={[
        {
          text: t("RequiredFieldsMissing"),
          type: "error",
          hidden:
            animal.group === "CUSTOM"
              ? !!animal.name && !!animal.description && !!animal.productionType
              : !!animal.animalCountConventional?.toString() &&
                !!animal.animalCountOrganic?.toString(),
        },
      ]}
    >
      <Form
        data={animalInfo}
        edit={edit}
        setEdit={setEdit}
        submitButton={{
          editLabel: t(edit ? "Confirm" : "Edit"),
          onSubmit,
        }}
        buttonSize="xs"
        otherButtons={[
          {
            label: t("DeleteAnimal"),
            onClick: () => setAnimalToDeleteUuid(formData.uuid || ""),
            hidden: !edit || animal.group !== "CUSTOM",
            outlined: true,
            className: "error",
          },
          {
            label: t("CheckAnimalDetails"),
            primary: true,
            //   TO DO -> onClick & remove disable
            disabled: true,
            onClick: () => {},
            hidden: isRenew || edit || animal.group === "CUSTOM",
          },
        ]}
        onResetForm={() => setFormData(animal)}
        isLoading={isLoading}
      />
      <Dialog
        isOpen={!!animalToDeleteUuid}
        onClose={() => setAnimalToDeleteUuid("")}
        title={t("DeleteAnimal")}
        desc={t("DeleteAnimalDesc")}
        isLoading={isLoading}
        confirmButton={{ label: t("Confirm"), onClick: onDelete }}
        cancelButton={{ onClick: () => setAnimalToDeleteUuid("") }}
        error
      />
    </SubSection>
  );
};
