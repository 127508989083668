import { ISelectData } from "@inceptionbg/ui-components";
import { FC, useRef, useState } from "react";
import ReactSelect, { StylesConfig } from "react-select";
import { InputWrapper } from "../Wrapper/InputWrapper";

interface Props {
  options: ISelectData[];
  value?: ISelectData | null;
  valuesMulti?: ISelectData[];
  onChange: (value: any) => void;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  menuWidth?: "fit-content" | "max-content" | number;
  autoFocus?: boolean;
  isClearable?: boolean;
  isOpen?: boolean;
  openDirection?: "top" | "auto" | "bottom";
  noOptionsMessage?: string;
  error?: boolean;
}
export const createSelectStyles = (
  menuWidth?: "fit-content" | "max-content" | number
) =>
  ({
    control: (provided) => ({
      ...provided,
      border: "none",
      boxShadow: "none",
      minHeight: 32,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: state.isMulti ? "8px 14px" : "0px 14px",
      fontSize: 13,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: "0 6px",
    }),
    clearIndicator: (provided) => ({
      ...provided,
      padding: "0 6px",
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: 2,
      width: menuWidth || "100%",
      minWidth: "100%",
      borderRadius: 8,
      boxShadow: `0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);`,
    }),
    menuPortal: (provided) => ({ ...provided, zIndex: 1001 }),
    input: (provided) => ({
      ...provided,
      padding: 0,
      margin: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: 13,
      backgroundColor: state.isSelected
        ? "var(--primary) !important"
        : state.isFocused
        ? "#eee !important"
        : "inherit",
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      fontSize: 13,
      fontStyle: "italic",
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "var(--primary-o25)",
    }),
  } as StylesConfig);

export const Select: FC<Props> = ({
  options,
  value,
  valuesMulti,
  onChange,
  required,
  disabled,
  placeholder = "",
  className,
  menuWidth,
  autoFocus,
  isClearable,
  isOpen,
  openDirection = "auto",
  noOptionsMessage,
  error,
}) => {
  const [focus, setFocus] = useState(false);
  const ref = useRef<any>(null);

  return (
    <InputWrapper className={className} focus={focus} error={error}>
      <ReactSelect
        value={value || valuesMulti || null}
        options={options}
        onChange={onChange}
        placeholder={placeholder}
        className={className}
        styles={createSelectStyles(menuWidth)}
        isDisabled={disabled}
        isClearable={isClearable}
        isMulti={!!valuesMulti}
        menuIsOpen={isOpen}
        menuPlacement={openDirection}
        menuPortalTarget={document.body}
        ref={ref}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        autoFocus={autoFocus}
        noOptionsMessage={() => noOptionsMessage || "Nema opcija"}
      />
      {required && !disabled && (
        <input
          className="fake-input"
          required
          tabIndex={-1}
          autoComplete="off"
          value={value?.value || valuesMulti?.length || ""}
          onChange={() => {}}
          onFocus={() => ref.current?.focus()}
        />
      )}
    </InputWrapper>
  );
};
