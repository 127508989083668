import { Dispatch, FC, SetStateAction, useContext, useState } from "react";
import {
  IRealLandNew,
  IRealLandUsageBasic,
  realLandUsageBasic,
} from "../../types/ILand";
import { useTranslation } from "react-i18next";
import { Dialog } from "../../Elements/Dialog";
import { List } from "../../Elements/List";
import { IInputComponentProps, Input } from "../../Elements/Input";
import {
  TextInput,
  deleteEmptyProps,
  deleteProps,
} from "@inceptionbg/ui-components";
import { SelectAsyncPaginate } from "../Inputs/SelectAsyncPaginate";
import { getCadastreMunicipalityOptions } from "../../utils/LoadOptions";
import { inputPattern } from "../../utils/InputPatternValidation";
import { SurfaceInput } from "../Inputs/Surface/SurfaceInput";
import { calculateSurfaceFromObj } from "../../utils/CalculateUtils";
import { createRealLand } from "../../repos/LandRepo";
import { FarmContext } from "../../AppContent";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  handleGetRealLands: (callback?: () => void) => void;
  setConfirmDialogIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const RealLandDialog: FC<Props> = ({
  isOpen,
  onClose,
  handleGetRealLands,
  setConfirmDialogIsOpen,
}) => {
  const [usageBasic, setUsageBasic] = useState<IRealLandUsageBasic | "">("");
  const [formData, setFormData] = useState<IRealLandNew>({});
  const [isLoading, setIsLoading] = useState(false);
  const farmUuid = useContext(FarmContext).farm?.uuid;

  const { t } = useTranslation();

  const realLandInfo: IInputComponentProps[] = [
    {
      label: t("CadastreMunicipality"),
      input: (
        <SelectAsyncPaginate
          required
          value={
            formData.cadastreMunicipality
              ? {
                  label: formData.cadastreMunicipality.name,
                  value: formData.cadastreMunicipality.uuid,
                }
              : null
          }
          loadOptions={getCadastreMunicipalityOptions}
          onChange={(e) => {
            setFormData(
              e
                ? deleteProps(
                    {
                      ...formData,
                      cadastreMunicipality: { name: e.label, uuid: e.value },
                    },
                    ["cadastreNumber"]
                  )
                : deleteProps(formData, ["cadastreMunicipality"])
            );
          }}
        />
      ),
    },
    {
      label: t("CadastreNumber"),
      input: (
        <TextInput
          required
          value={formData.cadastreNumber}
          setValue={(e) => setFormData({ ...formData, cadastreNumber: e })}
          inputProps={{ pattern: inputPattern.cadastreNumber }}
        />
      ),
    },
    {
      label: t("UsedSurface"),
      input: (
        <SurfaceInput
          formData={formData}
          setFormData={setFormData}
          value="surfaceObj"
          required
        />
      ),
    },
  ];

  const onFormSubmit = () => {
    const data: IRealLandNew = deleteProps(
      deleteEmptyProps({
        ...formData,
        farm: farmUuid ? { uuid: farmUuid } : undefined,
        usageBasis: usageBasic as IRealLandUsageBasic,
        surface: formData.surfaceObj
          ? calculateSurfaceFromObj(formData.surfaceObj)
          : formData.surface,
      }),
      ["surfaceObj"]
    );

    setIsLoading(true);
    createRealLand(data)
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
        handleGetRealLands();
        setConfirmDialogIsOpen(true)
      });
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => {
        setUsageBasic("");
        setFormData({});
        isLoading && setIsLoading(false);
        onClose();
      }}
      title={t(
        !usageBasic ? "RealLandDialogTitle" : `RealLand${usageBasic}Title`
      )}
      desc={t(!usageBasic ? "RealLandDialogDesc" : `RealLand${usageBasic}Desc`)}
      confirmButton={usageBasic ? { onFormSubmit } : undefined}
      cancelButton={usageBasic ? { onClick: () => setUsageBasic("") } : {}}
      isLoading={isLoading}
      size="m"
    >
      {!usageBasic ? (
        <List
          listItems={realLandUsageBasic.map((usageBasic) => ({
            id: usageBasic,
            title: t(`RealLand${usageBasic}ListTitle`),
            desc: t(`RealLand${usageBasic}ListDescription`),
          }))}
          onClick={(e) => setUsageBasic(e as IRealLandUsageBasic)}
        />
      ) : (
        <div className="flex column gap-4">
          {realLandInfo.map((e) => (
            <Input key={e.label} component={e} edit />
          ))}
        </div>
      )}
    </Dialog>
  );
};
