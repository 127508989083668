import { Radio, TextInput } from "@inceptionbg/ui-components";
import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { FarmContext } from "../../../../AppContent";
import { IInputComponentProps, Input } from "../../../../Elements/Input";
import { updateContactInfo } from "../../../../repos/ContactRepo";
import { IFarmNew } from "../../../../types/IFarm";
import { IContactInfoNew, IContactType } from "../../../../types/IContactInfo";
import { inputPattern } from "../../../../utils/InputPatternValidation";
import { ContactConfirmationDialog } from "../../../Dialogs/ContactConfirmationDialog";
import { SubSection } from "../../../SubSection";
import { Form } from "../../../Wrapper/FormWrapper";
import { formatPhoneNumber } from "../../../../utils/StringUtils";

interface Props {
  setFarm?: Dispatch<SetStateAction<IFarmNew>>;
}

export const ContactInfo: FC<Props> = ({ setFarm }) => {
  const [formData, setFormData] = useState<IContactInfoNew>({
    deliveryType: "INBOX",
  });
  const [edit, setEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmDialogType, setConfirmDialogType] = useState<IContactType | "">(
    ""
  );

  const { t } = useTranslation();
  const { farm, reloadFarm } = useContext(FarmContext);

  useEffect(() => {
    if (farm && !setFarm) {
      setFormData({
        email: farm.contactInfo?.email,
        phoneNumber: farm.contactInfo?.phoneNumber,
        deliveryType: farm.contactInfo.deliveryType,
      });
    }
  }, [farm, setFarm]);

  useEffect(() => {
    !!setFarm && setFarm((e) => ({ ...e, contactInfo: formData }));
  }, [formData, setFarm]);

  const contactInfo: IInputComponentProps[] = [
    {
      label: t("Email"),
      labelHelper: t("EmailHelper"),
      value: farm?.contactInfo?.email,
      input: (
        <TextInput
          value={formData.email}
          setValue={(e) => setFormData({ ...formData, email: e })}
          required
          inputProps={{ type: "email" }}
          error={
            !setFarm &&
            !!farm?.contactInfo?.email &&
            !farm?.contactInfo?.emailVerified
          }
        />
      ),
      alert: {
        text: !farm?.contactInfo?.emailVerified
          ? t("EmailNotVerified")
          : undefined,
        type: "error",
        active:
          !setFarm &&
          !!farm?.contactInfo?.email &&
          !farm?.contactInfo?.emailVerified,
        action:
          !!farm?.contactInfo?.email && !farm?.contactInfo?.emailVerified
            ? {
                label: t("VerifyEmail"),
                onClick: () => setConfirmDialogType("Email"),
              }
            : undefined,
      },
    },
    {
      label: t("PhoneNumber"),
      labelHelper: t("PhoneNumberHelper"),
      value: farm?.contactInfo?.phoneNumber,
      input: (
        <TextInput
          required
          value={formData.phoneNumber}
          setValue={(e) => setFormData({ ...formData, phoneNumber: e })}
          inputProps={{ pattern: inputPattern.phone }}
          error={
            !setFarm &&
            (!formData.phoneNumber ||
              (!!farm?.contactInfo?.phoneNumber &&
                !farm?.contactInfo?.phoneNumberVerified))
          }
        />
      ),
      alert: {
        text: !formData.phoneNumber
          ? t("RequiredFieldError")
          : !!farm?.contactInfo?.phoneNumber &&
            !farm?.contactInfo?.phoneNumberVerified
          ? t("PhoneNumberNotVerified")
          : undefined,
        type: "error",
        active:
          !setFarm &&
          (!formData.phoneNumber ||
            (!!farm?.contactInfo?.phoneNumber &&
              !farm?.contactInfo?.phoneNumberVerified)),
        action:
          !!farm?.contactInfo?.phoneNumber &&
          !farm?.contactInfo?.phoneNumberVerified
            ? {
                label: t("VerifyPhoneNumber"),
                onClick: () => setConfirmDialogType("Phone"),
              }
            : undefined,
      },
    },
    {
      label: t("PreferedDeliveryType"),
      value: t(`ContactDeliveryType${formData?.deliveryType}`),
      input: (
        <div className="flex column">
          <Radio
            label={t("ContactDeliveryTypeINBOX")}
            value="INBOX"
            selected={formData?.deliveryType!}
            setSelected={(e) => setFormData({ ...formData, deliveryType: e })}
          />
          <Radio
            label={t("ContactDeliveryTypeEMAIL")}
            value="EMAIL"
            selected={formData?.deliveryType!}
            setSelected={(e) => setFormData({ ...formData, deliveryType: e })}
          />
          <Radio
            label={t("ContactDeliveryTypeSMS")}
            value="SMS"
            selected={formData?.deliveryType!}
            setSelected={(e) => setFormData({ ...formData, deliveryType: e })}
          />
        </div>
      ),
    },
  ];

  const onSubmit = () => {
    if (farm?.uuid) {
      setIsLoading(true);
      updateContactInfo(farm.uuid, {
        ...formData,
        phoneNumber: formatPhoneNumber(formData.phoneNumber),
      })
        .then(() => {
          reloadFarm(() => {
            setIsLoading(false);
            setEdit(false);
          });
        })
        .catch(() => setIsLoading(false));
    }
  };

  return (
    <SubSection
      title={t("ContactTitleOrdered")}
      expandable={!setFarm}
      alerts={
        setFarm
          ? []
          : [
              {
                text: t("RequiredFieldsMissing"),
                type: "error",
                hidden: !!formData?.phoneNumber,
              },
              {
                text: t("EmailNotVerifiedInfo"),
                type: "error",
                hidden:
                  !farm?.contactInfo?.email ||
                  !!farm?.contactInfo?.emailVerified,
              },
              {
                text: t("PhoneNumberNotVerifiedInfo"),
                type: "error",
                hidden:
                  !farm?.contactInfo?.phoneNumber ||
                  !!farm?.contactInfo?.phoneNumberVerified,
              },
            ]
      }
    >
      {setFarm ? (
        <div className="form-container">
          {contactInfo.map(
            (e) => !e.hidden && <Input key={e.label} component={e} edit />
          )}
        </div>
      ) : (
        <>
          <Form
            data={contactInfo}
            edit={edit}
            setEdit={setEdit}
            submitButton={{
              editLabel: t(edit ? "Confirm" : "ContactEdit"),
              onSubmit,
            }}
            onResetForm={() =>
              setFormData(
                {
                  ...farm?.contactInfo,
                  phoneNumber: farm?.contactInfo?.phoneNumber?.slice(4),
                  deliveryType: "INBOX",
                } || { deliveryType: "INBOX" }
              )
            }
            isLoading={isLoading}
          />
          {!!confirmDialogType && (
            <ContactConfirmationDialog
              isOpen={true}
              onClose={() => setConfirmDialogType("")}
              type={confirmDialogType}
            />
          )}
        </>
      )}
    </SubSection>
  );
};
