import { ISurfaceObj } from "../types/ILand";

export const convertSurfaceToObj = (surface?: number | string) => {
  if (surface || surface === 0) {
    const surfaceNumber = Math.round(+surface);
    return {
      ha: Math.floor(surfaceNumber / 10000).toString(),
      a: Math.floor(surfaceNumber / 100)
        .toString()
        .slice(-2),
      m: surfaceNumber.toString().slice(-2),
    };
  }
  return;
};

export const calculateSurfaceFromObj = (surfaceObj: ISurfaceObj) =>
  +(surfaceObj.m || 0) +
  +(surfaceObj.a || 0) * 100 +
  +(surfaceObj.ha || 0) * 10000;

export const getBirthDateFromPIN = (pin?: string) => {
  if (pin) {
    const d = pin.slice(0, 2);
    const m = pin.slice(2, 4);
    const y = pin.slice(4, 7);
    const c = +y[0] > 8 ? 1 : 2;

    return `${c}${y}-${m}-${d}`;
  } else return undefined;
};
