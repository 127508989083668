import { FC } from "react";
import { useTranslation } from "react-i18next";

export const CancelRequestInfo: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="no-access">
      <h2>{t("CancelRequestInfo")}</h2>
    </div>
  );
};
