import { FontAwesomeIcon, Menu, MenuItem } from "@inceptionbg/ui-components";
import { changeLanguage } from "i18next";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { faAngleDown } from "../assets/icons/light/faAngleDown";
import { localStorageGet, localStorageSet } from "../utils/LocalStorageHelper";

export const ChangeLanguage: FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation();

  const languages = ["sr-RS-Latn", "sr-RS-Cyrl"];
  const currentLanguage = localStorageGet("language");

  return (
    <>
      <Menu
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        placementX="right"
        renderButton={(ref) => (
          <div
            ref={ref}
            className="clickable"
            onClick={() => setIsOpen(!isOpen)}
          >
            {t(currentLanguage || "")}
            <FontAwesomeIcon icon={faAngleDown} className="ml-2" />
          </div>
        )}
      >
        {languages.map((e) => (
          <MenuItem
            key={e}
            onClick={() => {
              changeLanguage(e);
              localStorageSet("language", e);
              setIsOpen(false);
            }}
            label={t(e)}
          />
        ))}
      </Menu>
    </>
  );
};
