import { FC, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FarmContext } from "../../../AppContent";
import { InfoBox } from "../../../Elements/Alert/InfoBox";
import { getAnimals } from "../../../repos/AnimalRepo";
import { IAnimalByGroup, IAnimalCountTotals } from "../../../types/IAnimal";
import { groupArray } from "../../../utils/ArrayUtils";
import { Section } from "../../Section";
import { SubSection } from "../../SubSection";
import { AnimalForm } from "./AnimalForm";
import { AnimalsRecapitulationTable } from "../../Recapitulation/AnimalsRecapitulationTable";
import { Button } from "@inceptionbg/ui-components";
import { AnimalAddDialog } from "./AnimalAddDialog";
import { AnimalHidSection } from "./AnimalHidSection";

export const AnimalsSection: FC = () => {
  const [animalsByGroups, setAnimalsByGroups] = useState<IAnimalByGroup[]>([]);
  const [animalCountTotals, setAnimalCountTotals] =
    useState<IAnimalCountTotals>();
  const [addAnimalOpen, setAddAnimalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { farm, handleGetRenewalConditions } = useContext(FarmContext);
  const { t } = useTranslation();
  const farmPending = ["PENDING", "PENDING_APPROVAL"].includes(
    farm?.status || ""
  );

  const handleGetAnimals = useCallback(
    (callback?: () => void) => {
      if (farm) {
        setIsLoading(true);
        getAnimals(farm.uuid)
          .then((data) => {
            setAnimalsByGroups(groupArray(data.animals, "group"));
            setAnimalCountTotals(data.totals);
            callback && callback();
          })
          .catch(() => {})
          .finally(() => setIsLoading(false));
      }
    },
    [farm]
  );

  useEffect(handleGetAnimals, [handleGetAnimals]);

  return (
    <Section id="animals" title={t("AnimalsTitleOrdered")}>
      <div className="form-container">
        <InfoBox
          title={t("Important")}
          desc={t(farmPending ? "AnimalHidsSectionInfo" : "AnimalInfoDesc")}
        />
        {!!animalsByGroups.length && (
          <AnimalsRecapitulationTable
            animalsByGroups={animalsByGroups}
            totals={animalCountTotals}
          />
        )}
        {farmPending && <AnimalHidSection />}
        {farm &&
          !isLoading &&
          animalsByGroups.map((e) => (
            <SubSection
              key={e.id}
              title={t(e.id)}
              expandable
              alerts={[
                {
                  text: t("RequiredFieldsMissingAnimals"),
                  type: "error",
                  hidden:
                    e.animals[0].group === "CUSTOM" ||
                    !e.animals.find(
                      (e) =>
                        !e.animalCountConventional?.toString() ||
                        !e.animalCountOrganic?.toString()
                    ),
                },
                {
                  text: t("RequiredFieldsMissing"),
                  type: "error",
                  hidden:
                    e.animals[0].group !== "CUSTOM" ||
                    !e.animals.find(
                      (e) => !e.name || !e.description || !e.productionType
                    ),
                },
              ]}
            >
              <div className="form-container px-4">
                {e.animals.map((animal) => (
                  <AnimalForm
                    key={animal.uuid}
                    animal={animal}
                    reloadAnimals={(callback?: () => void) => {
                      handleGetAnimals();
                      handleGetRenewalConditions();
                      callback && callback();
                    }}
                  />
                ))}
              </div>
              {e.id === "CUSTOM" && (
                <div className="flex justify-right mt-3">
                  <Button
                    label={t("AddCustomAnimal")}
                    primary
                    size="s"
                    onClick={() => setAddAnimalOpen(true)}
                  />
                </div>
              )}
            </SubSection>
          ))}
        {!animalsByGroups.find((e) => e.id === "CUSTOM") && (
          <SubSection title={t("CUSTOM")} expandable>
            <div className="flex justify-right mt-3">
              <Button
                label={t("AddCustomAnimal")}
                primary
                size="s"
                onClick={() => setAddAnimalOpen(true)}
              />
            </div>
          </SubSection>
        )}
        <AnimalAddDialog
          isOpen={addAnimalOpen}
          onClose={() => setAddAnimalOpen(false)}
          reloadAnimals={handleGetAnimals}
        />
      </div>
    </Section>
  );
};
