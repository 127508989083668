import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { FarmContext } from "../../AppContent";
import { Dialog } from "../../Elements/Dialog";
import {
  createEvidenceFromFile,
  getEvidences,
  uploadFile,
} from "../../repos/FileRepo";
import { IFarmNew } from "../../types/IFarm";
import {
  evidenceSections,
  IEvidence,
  IEvidenceCategory,
} from "../../types/IFile";
import { FilesDropzone } from "./Dropzone/FilesDropzone";
import { EvidencesList } from "./EvidencesList";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  category: IEvidenceCategory;
  sectionEvidences?: IEvidence[];
  setFarm?: Dispatch<SetStateAction<IFarmNew>>;
  approvalUuid?: string;
}

export const EvidencesUploadDialog: FC<Props> = ({
  isOpen,
  onClose,
  category,
  sectionEvidences,
  setFarm,
  approvalUuid,
}) => {
  const [evidences, setEvidences] = useState<IEvidence[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const farmUuid = useContext(FarmContext).farm?.uuid;
  const { t } = useTranslation();

  const handleGetEvidences = useCallback(() => {
    if (farmUuid && isOpen && !setFarm) {
      setIsLoading(true);
      getEvidences(farmUuid, category, { approvalUuid })
        .then((e) => setEvidences(e.evidences))
        .catch(() => {})
        .finally(() => setIsLoading(false));
    }
  }, [farmUuid, isOpen, category, approvalUuid, setFarm]);

  useEffect(handleGetEvidences, [handleGetEvidences]);

  const onSubmit = (data: File[]) => {
    if (setFarm) {
      setIsLoading(true);
      data.forEach((file, i) => {
        uploadFile(file)
          .then((e) => {
            setFarm((farm) => ({
              ...farm,
              [evidenceSections[category]!]: {
                ...farm[evidenceSections[category]!],
                evidences: [
                  ...(farm[evidenceSections[category]!]?.evidences || []),
                  { storedFile: e },
                ],
              },
            }));
          })
          .catch(() => setIsLoading(false))
          .finally(() => {
            if (i === data.length - 1) {
              setIsLoading(false);
            }
          });
      });
    } else if (farmUuid) {
      setIsLoading(true);
      data.forEach((file, i) => {
        createEvidenceFromFile(
          file,
          farmUuid,
          category,
          approvalUuid ? { approval: { uuid: approvalUuid } } : undefined
        )
          .catch(() => {})
          .finally(() => {
            if (i === data.length - 1) {
              setIsLoading(false);
              handleGetEvidences();
            }
          });
      });
    }
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => onClose()}
      title={t(`EvidenceTitle${category}`)}
      desc={t(`EvidenceDesc${category}`)}
      confirmButton={{
        label: t("Confirm"),
        okButton: true,
        disabled: !evidences.length && !sectionEvidences?.length,
      }}
      cancelButton={{}}
      isLoading={isLoading}
      size="m"
    >
      {!setFarm && !!evidences.length && (
        <EvidencesList
          evidences={evidences}
          reloadEvidences={handleGetEvidences}
        />
      )}
      {!!sectionEvidences?.length && (
        <EvidencesList
          evidences={sectionEvidences}
          reloadEvidences={handleGetEvidences}
          onDeleteFile={(index: number, callback: () => void) => {
            setFarm!((farm) => {
              let newList = [...sectionEvidences];
              newList.splice(index, 1);
              return {
                ...farm,
                [evidenceSections[category]!]: {
                  ...farm[evidenceSections[category]!],
                  evidences: newList,
                },
              };
            });

            callback();
          }}
        />
      )}
      <FilesDropzone
        onSubmit={onSubmit}
        category={category}
        setFarm={setFarm}
        reloadEvidences={handleGetEvidences}
      />
    </Dialog>
  );
};
