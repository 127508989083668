import {
  deleteProps,
  NumberInput,
  SelectAsyncPaginate,
} from "@inceptionbg/ui-components";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IInputComponentProps } from "../../../Elements/Input";
import { Form } from "../../Wrapper/FormWrapper";
import { IRealLand, IRealLandNew } from "../../../types/ILand";
import {
  calculateSurfaceFromObj,
  convertSurfaceToObj,
} from "../../../utils/CalculateUtils";
import { createRealLand, deleteRealLand } from "../../../repos/LandRepo";
import { SurfaceValue } from "../../Inputs/Surface/SurfaceValue";
import { SurfaceInput } from "../../Inputs/Surface/SurfaceInput";
import { getCadastreMunicipalityOptions } from "../../../utils/LoadOptions";
import { Dialog } from "../../../Elements/Dialog";

interface Props {
  realLand: IRealLand;
  newForm?: {
    onCancel: () => void;
  };
  reloadLands: (callback?: () => void) => void;
}

export const RealLandForm: FC<Props> = ({ realLand, newForm, reloadLands }) => {
  const [formData, setFormData] = useState<IRealLandNew>({});
  const [edit, setEdit] = useState(false);

  const [landToDeleteUuid, setLandToDeleteUuid] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const surfaceObj = convertSurfaceToObj(realLand.surface);

  // eslint-disable-next-line
  useEffect(() => setFormData({ ...realLand, surfaceObj }), [realLand]);

  const landInfo: IInputComponentProps[] = [
    {
      label: t("CadastreMunicipality"),
      input: (
        <SelectAsyncPaginate
          required
          value={
            formData.cadastreMunicipality
              ? {
                  label: formData.cadastreMunicipality.name,
                  value: formData.cadastreMunicipality.uuid,
                }
              : null
          }
          loadOptions={getCadastreMunicipalityOptions}
          onChange={(e) => {
            setFormData(
              e
                ? deleteProps(
                    {
                      ...formData,
                      cadastreMunicipality: { name: e.label, uuid: e.value },
                    },
                    ["cadastreNumber"]
                  )
                : deleteProps(formData, ["cadastreMunicipality"])
            );
          }}
        />
      ),
      value: realLand.cadastreMunicipality.name,
    },
    {
      label: t("CadastreNumber"),
      value: realLand.cadastreNumber,
      input: (
        <NumberInput
          value={formData.cadastreNumber}
          setValue={(e) => setFormData({ ...formData, cadastreNumber: e })}
          alignLeft
        />
      ),
    },
    {
      label: t("UsageBasis"),
      value: t(realLand.usageBasis),
      readOnly: true,
    },
    {
      label: t("UsedSurface"),
      valueElement: <SurfaceValue valueObj={surfaceObj} />,
      input: (
        <SurfaceInput
          formData={formData}
          setFormData={setFormData}
          value="surfaceObj"
        />
      ),
    },
  ];

  const onSubmit = () => {
    setIsLoading(true);
    const data: IRealLandNew = deleteProps(
      {
        ...formData,
        surface: formData.surfaceObj
          ? calculateSurfaceFromObj(formData.surfaceObj)
          : formData.surface,
      },
      ["surfaceObj"]
    );

    if (!!data.uuid) {
      createRealLand(data)
        .then(() => {
          if (!!reloadLands) {
            reloadLands(() => {
              setIsLoading(false);
              setEdit(false);
              newForm && newForm.onCancel();
            });
          } else {
            setIsLoading(false);
            setEdit(false);
          }
        })
        .catch(() => setIsLoading(false));
    }
  };

  const onDelete = () => {
    if (landToDeleteUuid) {
      setIsLoading(true);
      deleteRealLand(landToDeleteUuid)
        .then(() => {
          if (!!reloadLands) {
            reloadLands(() => {
              setIsLoading(false);
              setEdit(false);
              newForm && newForm.onCancel();
            });
          } else {
            setIsLoading(false);
            setEdit(false);
          }
        })
        .catch(() => setIsLoading(false));
    }
  };

  return (
    <>
      <Form
        data={landInfo}
        edit={!!newForm || edit}
        setEdit={setEdit}
        submitButton={{
          editLabel: t(!!newForm || edit ? "Confirm" : "LandEdit"),
          onSubmit: () => onSubmit(),
        }}
        otherButtons={[
          {
            label: t("DeleteLand"),
            onClick: () => setLandToDeleteUuid(formData.uuid || ""),
            outlined: true,
            className: "error",
          },
        ]}
        buttonSize={"s"}
        onCancel={!!newForm ? newForm.onCancel : undefined}
        onResetForm={() => setFormData({ ...realLand, surfaceObj })}
        isLoading={isLoading}
      />
      <Dialog
        isOpen={!!landToDeleteUuid}
        onClose={() => setLandToDeleteUuid("")}
        title={t("DeleteLand")}
        desc={t("DeleteRealLandDesc")}
        isLoading={isLoading}
        confirmButton={{ label: t("Confirm"), onClick: onDelete }}
        cancelButton={{ onClick: () => setLandToDeleteUuid("") }}
        error
      />
    </>
  );
};
