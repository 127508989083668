import { FC } from "react";
import { ISurfaceObj } from "../../../types/ILand";

interface Props {
  valueObj?: ISurfaceObj;
  simple?: boolean;
  isRecapitulation?: boolean;
}

const zeroValue = (e?: string) => !!e && +e === 0;

export const SurfaceValue: FC<Props> = ({
  valueObj,
  simple,
  isRecapitulation,
}) =>
  isRecapitulation ? (
    <>
      {zeroValue(valueObj?.ha) ? "" : valueObj?.ha}{" "}
      {zeroValue(valueObj?.a)
        ? zeroValue(valueObj?.ha)
          ? ""
          : "00"
        : (!zeroValue(valueObj?.ha) && valueObj?.a?.length === 1 ? "0" : "") +
          valueObj?.a}{" "}
      {(!zeroValue(valueObj?.ha) &&
      !zeroValue(valueObj?.a) &&
      valueObj?.m?.length === 1
        ? "0"
        : "") + valueObj?.m}
    </>
  ) : simple ? (
    <p>
      {valueObj?.ha}ha {valueObj?.a}a {valueObj?.m}m²
    </p>
  ) : (
    <div className="flex justify-between full-width">
      <p>{valueObj?.ha} ha</p>
      <p>{valueObj?.a} a</p>
      <p>{valueObj?.m} m²</p>
    </div>
  );
