import { FC, useContext, useState } from "react";
import {
  ILandReportNew,
  ILandReportType,
  landReportType,
} from "../../types/ILand";
import { useTranslation } from "react-i18next";
import { FarmContext } from "../../AppContent";
import { createLandReport } from "../../repos/LandRepo";
import { Dialog } from "../../Elements/Dialog";
import { List } from "../../Elements/List";
import { IInputComponentProps, Input } from "../../Elements/Input";
import {
  TextInput,
  deleteEmptyProps,
  deleteProps,
} from "@inceptionbg/ui-components";
import { SelectAsyncPaginate } from "../Inputs/SelectAsyncPaginate";
import { getCadastreMunicipalityOptions } from "../../utils/LoadOptions";
import { inputPattern } from "../../utils/InputPatternValidation";
import { SurfaceInput } from "../Inputs/Surface/SurfaceInput";
import { calculateSurfaceFromObj } from "../../utils/CalculateUtils";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const LandReportDialog: FC<Props> = ({ isOpen, onClose }) => {
  const [type, setType] = useState<ILandReportType | "">("");
  const [formData, setFormData] = useState<ILandReportNew>({});
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const farmUuid = useContext(FarmContext).farm?.uuid;

  const landReportInfo: IInputComponentProps[] = [
    {
      label: t("CadastreMunicipality"),
      input: (
        <SelectAsyncPaginate
          required
          value={
            formData.cadastreMunicipality
              ? {
                  label: formData.cadastreMunicipality.name,
                  value: formData.cadastreMunicipality.uuid,
                }
              : null
          }
          loadOptions={getCadastreMunicipalityOptions}
          onChange={(e) => {
            setFormData(
              e
                ? deleteProps(
                    {
                      ...formData,
                      cadastreMunicipality: { name: e.label, uuid: e.value },
                    },
                    ["cadastreNumber"]
                  )
                : deleteProps(formData, [
                    "cadastreMunicipality",
                    "cadastreNumber",
                    "cadastreNumberObj",
                  ])
            );
          }}
        />
      ),
    },
    {
      label: t("CadastreNumber"),
      input: (
        <TextInput
          required
          value={formData.cadastreNumber}
          setValue={(e) => setFormData({ ...formData, cadastreNumber: e })}
          inputProps={{ pattern: inputPattern.cadastreNumber }}
        />
      ),
    },
    {
      label: t("UsedSurface"),
      input: (
        <SurfaceInput
          formData={formData}
          setFormData={setFormData}
          value="usedSurfaceObj"
          required
        />
      ),
    },
    {
      label: t("Note"),
      input: (
        <TextInput
          value={formData.note}
          setValue={(e) => setFormData({ ...formData, note: e })}
        />
      ),
    },
  ];

  const onFormSubmit = () => {
    const data: ILandReportNew = deleteProps(
      deleteEmptyProps({
        ...formData,
        farm: { uuid: farmUuid },
        type: type as ILandReportType,
        usedSurface: formData.usedSurfaceObj
          ? calculateSurfaceFromObj(formData.usedSurfaceObj)
          : formData.usedSurface,
      }),
      ["surfaceObj", "usedSurfaceObj"]
    );

    setIsLoading(true);
    createLandReport(data)
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => {
        setType("");
        setFormData({});
        isLoading && setIsLoading(false);
        onClose();
      }}
      title={t(!type ? "LandReportTitle" : `LandReport${type}`)}
      confirmButton={type ? { onFormSubmit } : undefined}
      cancelButton={type ? { onClick: () => setType("") } : {}}
      isLoading={isLoading}
      size="m"
    >
      {!type ? (
        <List
          listItems={landReportType.map((type) => ({
            id: type,
            title: t(`LandReport${type}`),
          }))}
          onClick={(e) => setType(e as ILandReportType)}
        />
      ) : (
        <div className="flex column gap-4">
          {landReportInfo.map((e) => (
            <Input key={e.label} component={e} edit />
          ))}
        </div>
      )}
    </Dialog>
  );
};
