import { FC, useContext, useState } from "react";
import { CustomRequestSection } from "../Components/Sections/CustomRequestSection";
import { useTranslation } from "react-i18next";
import { IFile } from "../types/IFile";
import { FilesUpload } from "../Components/Sections/Common/SubSections/FilesUpload";
import { changeHolder } from "../repos/FarmRepo";
import { IInputComponentProps, Input } from "../Elements/Input";
import { FarmContext } from "../AppContent";
import { InfoBox } from "../Elements/Alert/InfoBox";
import {
  ISelectData,
  ISimpleObject,
  TextInput,
} from "@inceptionbg/ui-components";
import { SelectAsyncPaginate } from "../Components/Inputs/SelectAsyncPaginate";
import {
  getEducationOptions,
  getMembersOptions,
  getWorkActivityOptions,
} from "../utils/LoadOptions";
import { inputPattern } from "../utils/InputPatternValidation";

export const ChangePPHolderPage: FC = () => {
  const [member, setMember] = useState<ISelectData | null>(null);
  const [workActivity, setWorkActivity] = useState<ISimpleObject>();
  const [accountNumber, setAccountNumber] = useState("");
  const [education, setEducation] = useState<ISimpleObject>();
  const [storedFiles, setStoredFiles] = useState<IFile[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const { farm, reloadFarm } = useContext(FarmContext);

  const data: IInputComponentProps[] = [
    {
      label: t("OrgNumberLong"),
      value: farm?.registrationNumber,
    },
    {
      label: t("NewFarmHolder"),
      input: (
        <SelectAsyncPaginate
          required
          value={member}
          loadOptions={(
            search: string,
            loadedOptions: [],
            offset: { page: number }
          ) => getMembersOptions(search, loadedOptions, offset, farm!.uuid)}
          onChange={setMember}
        />
      ),
    },
    {
      label: t("AccountNumber"),
      labelHelper: t("AccountNumberHelper"),
      value: accountNumber,
      input: (
        <TextInput
          value={accountNumber}
          setValue={setAccountNumber}
          required
          inputProps={{ pattern: inputPattern.bankAccount }}
        />
      ),
    },
    {
      label: t("WorkActivity"),
      value: workActivity?.name || "/",
      input: (
        <SelectAsyncPaginate
          required
          value={
            workActivity
              ? {
                  label: workActivity.name,
                  value: workActivity.uuid,
                }
              : null
          }
          loadOptions={getWorkActivityOptions}
          onChange={(e) =>
            setWorkActivity(e ? { name: e.label, uuid: e.value } : undefined)
          }
        />
      ),
    },
    {
      label: t("Education"),
      value: education?.name || "/",
      input: (
        <SelectAsyncPaginate
          required
          value={
            education
              ? {
                  label: education?.name,
                  value: education?.uuid,
                }
              : null
          }
          loadOptions={getEducationOptions}
          onChange={(e) =>
            setEducation(
              e
                ? {
                    name: e.label,
                    uuid: e.value,
                  }
                : undefined
            )
          }
        />
      ),
    },
  ];

  const onSubmit = () => {
    if (farm?.registrationNumber && member) {
      setIsLoading(true);
      changeHolder(farm.registrationNumber, {
        member: { uuid: member.value },
        workActivity,
        bankAccount: { accountNumber },
        education: { uuid: education?.uuid! },
        evidences: storedFiles.map((storedFile) => ({ storedFile })),
      })
        .then(() => {
          setStoredFiles([]);
          reloadFarm();
        })
        .catch(() => {})
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <CustomRequestSection
      sectionTitle={t("ChangeHolderTitle")}
      infoBoxTitle={t("RequestChangeHolderTitle")}
      infoBoxDesc={t("RequestChangeHolderDesc")}
      onSubmit={onSubmit}
      disableSubmit={
        !member ||
        !accountNumber ||
        !workActivity ||
        !storedFiles.length ||
        !education
      }
    >
      <div className="form-container mb-4">
        {data.map(
          (e) =>
            !e.hidden && <Input key={e.label} component={e} edit={!e.edit} />
        )}
      </div>
      <FilesUpload
        storedFiles={storedFiles}
        setStoredFiles={setStoredFiles}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        infoBoxItems={[
          {
            title: t("NewHolderStatement"),
            // file: { label: t("DownloadHolderStatement"), url: "/" },
          },
          {
            title: t("MembersStatementForChangeHolder"),
            // file: { label: t("DownloadMembersStatement"), url: "/" },
          },
          {
            title: t("BankAccountRegisteredConfirmation"),
            // file: { label: t("DownloadMembersStatement"), url: "/" },
          },
        ]}
      />
      <InfoBox
        title={t("Note")}
        desc={t("ChangeHolderStatement")}
        className="mx-4"
      />
    </CustomRequestSection>
  );
};
