export type IEvidenceCategory =
  | "LAND"
  | "BANK_ACCOUNT"
  | "LEGAL_REPRESENTATIVE"
  | "MEMBER_LAND_STATEMENT"
  | "MEMBER_STATEMENT"
  | "HOLDER"
  | "HEAD_OFFICE"
  | "INVOICE";
export type IEvidenceSignStatus = "PENDING_SIGNATURE" | "SIGNED";

export const evidenceSections: {
  [key in IEvidenceCategory]?:
    | "headOffice"
    | "bankAccount"
    | "legalRepresentative";
} = {
  HEAD_OFFICE: "headOffice",
  BANK_ACCOUNT: "bankAccount",
  LEGAL_REPRESENTATIVE: "legalRepresentative",
  // LAND: "land",
  // MEMBER_LAND_STATEMENT: "member",
  // MEMBER_STATEMENT: "member",
};
export type IEvidenceFilters = {
  land?: { uuid: string };
  member?: { uuid: string };
  approval?: { uuid: string };
};

export interface IFile {
  uuid?: string;
  originalFileName?: string;
  downloadUrl?: string;
  lengthInBytes?: number;
  uploadedAt?: string;
  fileUuid?: string;
  storage?: {
    url?: string;
  };
}

export interface IEvidence {
  uuid?: string;
  storedFile: IFile;
  category?: IEvidenceCategory;
  signStatus?: IEvidenceSignStatus;
  concluded?: boolean;
}

export type IUploadStatus = "INACTIVE" | "ACTIVE" | "COMPLETED";

export interface IMobileUploadSession {
  createdAt: string;
  expiresAt: string;
  filesCount: number;
  status: IUploadStatus;
  uuid: string;
}
