import { Dispatch, FC, SetStateAction } from "react";
import { IInvoiceItem } from "../../../../../types/IInvoice";
import { InvoiceItem } from "./InvoiceItem";
import { Input } from "../../../../../Elements/Input";

interface Props {
  items: IInvoiceItem[];
  setItems: Dispatch<SetStateAction<IInvoiceItem[]>>;
}

export const AddInvoiceItemsForm: FC<Props> = ({ items, setItems }) => (
  <div className="form-container">
    {items.map((item, i) => (
      <Input
        key={item.uuid || i}
        component={{
          label: item.fuelType?.name + ":" || "",
          value: `${item.liter || 0} l`,
        }}
      />
    ))}
    <InvoiceItem
      edit
      onSubmit={(item) => setItems((e) => [...e, item])}
      excludeFuelTypeUuids={items.map((e) => e.fuelType?.uuid || "")}
    />
  </div>
);
