import { Dispatch, FC, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { List } from "../../../../../Elements/List";
import { IStatementType } from "../../../../../types/IMember";

interface Props {
  setStep: Dispatch<SetStateAction<IStatementType>>;
}

export const AddStatementTypeList: FC<Props> = ({ setStep }) => {
  const { t } = useTranslation();
  const list: IStatementType[] = ["eid", "attachments"];

  return (
    <List
      listItems={list.map((item) => ({
        id: item,
        title: t(`StatementTitle_${item}`),
        desc: t(`StatementDesc_${item}`),
        disabled: item === "eid",
      }))}
      onClick={(e) => setStep(e as IStatementType)}
    />
  );
};
